import { useCallback, useMemo } from "react";

import { useInitStripeCheckoutUpgradeSessionMutation } from "@js/apps/auth/api";

import { useProductCheckoutModalContext } from "../../product-checkout-modal-context";

export const useStripeCheckoutForm = () => {
  const { upgradeType, upgradeCost, setModeOfPayment } =
    useProductCheckoutModalContext();
  const [initStripeCheckoutUpgradeSession] =
    useInitStripeCheckoutUpgradeSessionMutation();

  const fetchClientSecret = useCallback(async () => {
    try {
      const currentUrl = window.location.href;
      const separator = currentUrl.includes("?") ? "&" : "?";

      const response = await initStripeCheckoutUpgradeSession({
        upgrade_type: upgradeType,
        return_url: `${currentUrl}${separator}success_fiat_payment=${upgradeType}`,
      }).unwrap();

      return response.clientSecret;
    } catch {
      return "";
    }
  }, [initStripeCheckoutUpgradeSession, upgradeType]);

  const stripeOptions = useMemo(
    () => ({ fetchClientSecret }),
    [fetchClientSecret],
  );

  const onPayViaBTRST = useCallback(
    () => setModeOfPayment("btrst"),
    [setModeOfPayment],
  );

  return { onPayViaBTRST, stripeOptions, upgradeCost };
};
