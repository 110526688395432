import { submitActionAfterOTPConfirmation } from "@js/apps/common/forms/fields/otp/otp-auth-modal";
import { useUser } from "@js/apps/common/hooks/use-user";
import { openOTPDisabledWarningModal } from "@js/apps/settings/components/otp-auth";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { useAppDispatch, useAppSelector } from "@js/hooks/redux";
import type { WithdrawalMethodType } from "@js/types/withdrawals";

import {
  deleteWithdrawalMethod,
  fetchWithdrawalMethods,
  setAsDefaultWithdrawalMethod as setAsDefaultWithdrawal,
} from "../actions";
import { openAddRecipientsModal } from "../components/add-recipients";
import { handleResponseError } from "../utils";

import { useFetchWithdrawalMethods } from "./use-fetch-withdrawal-methods";

export const useWithdrawalMethods = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const fetchingWithdrawalMethods = useAppSelector(
    (state) => state.withdrawal.fetchingWithdrawalMethods,
  );
  const withdrawalMethods = useAppSelector(
    (state) => state.withdrawal.withdrawalMethods,
  );

  useFetchWithdrawalMethods();

  const onWithdrawalMethodDelete = (withdrawalMethod: WithdrawalMethodType) => {
    ModalInstance.open({
      children: (
        <ModalConfirm
          onCancel={ModalInstance.close}
          onConfirm={() => {
            ModalInstance.close();
            submitActionAfterOTPConfirmation({
              onSubmit: ({ code, is_backup_code }) => {
                return deleteWithdrawalMethod(withdrawalMethod.id, {
                  code,
                  is_backup_code,
                })
                  .then(() => dispatch(fetchWithdrawalMethods()))
                  .catch((error) => {
                    handleResponseError(
                      error,
                      "Failed to delete banking information.",
                    );
                  });
              },
            });
          }}
        >
          Are you sure you want to delete this banking information?
        </ModalConfirm>
      ),
    });
  };

  const setAsDefaultWithdrawalMethod = (
    withdrawalMethod: WithdrawalMethodType,
  ) => {
    if (!user?.is_otp_enabled) {
      return openOTPDisabledWarningModal();
    }

    submitActionAfterOTPConfirmation({
      onSubmit: async ({ code, is_backup_code }) => {
        return setAsDefaultWithdrawal(withdrawalMethod.id, {
          code,
          is_backup_code,
        })
          .then(() => dispatch(fetchWithdrawalMethods()))
          .catch((error) => {
            handleResponseError(
              error,
              "Failed to set banking information as default.",
            );
          });
      },
    });
  };

  const onAddRecipientsClick = (withdrawalMethod: WithdrawalMethodType) => {
    if (!user?.is_otp_enabled) {
      return openOTPDisabledWarningModal();
    }

    openAddRecipientsModal({ handledMethod: withdrawalMethod });
  };

  return {
    fetchingWithdrawalMethods,
    onAddRecipientsClick,
    setAsDefaultWithdrawalMethod,
    onWithdrawalMethodDelete,
    withdrawalMethods,
  };
};
