import { PageHead } from "@js/components/page-head";
import type { FreelancerPublic } from "@js/types/freelancer";

type FreelancerProfileMetaProps = { profile: Pick<FreelancerPublic, "user"> };

export const FreelancerProfileMeta = ({
  profile,
}: FreelancerProfileMetaProps) => {
  return (
    <PageHead>
      <meta property="og:title" content={profile.user.public_name} />
      <meta property="og:image" content={profile.user.avatar ?? undefined} />
      <meta
        property="og:description"
        content={profile.user.title || undefined}
      />
      <meta name="description" content={profile.user.title || undefined} />
      <title>{`Talent: ${profile.user.public_name}`}</title>
    </PageHead>
  );
};
