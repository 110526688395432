import { useCallback } from "react";

import {
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { FreelancerRole } from "@js/types/freelancer";

import { useRemoveFreelancerRoleMutation } from "../../api";

import styles from "./styles.module.scss";

type RemoveRoleProps = {
  role: FreelancerRole;
  onCancel: () => void;
  onRemove: () => void;
};

export const RemoveRole = ({ role, onRemove, onCancel }: RemoveRoleProps) => {
  const [removeRole] = useRemoveFreelancerRoleMutation();

  const handleRemove = useCallback(() => {
    removeRole(role.id).then(() => onRemove());
  }, [removeRole, role.id, onRemove]);

  return (
    <div className={styles.roleRemoveWrapper}>
      <div className={styles.roleRemoveWrapperHeader}>
        <IconButton
          variant="tertiary"
          size="small"
          aria-label="Remove role"
          onClick={onRemove}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Typography
          component="h3"
          variant="title"
          size="small"
          fontWeight={400}
          ml={2}
        >
          Remove role
        </Typography>
      </div>
      <Typography component="p" variant="label" size="medium" fontWeight={400}>
        Removing your <b>{role.role.name}</b> role from your profile can’t be
        undone. Are you sure you want to remove this role?
      </Typography>
      <div className={styles.roleRemoveWrapperFooter}>
        <Button variant="secondary" shape="squared" onClick={onCancel}>
          Keep role
        </Button>
        <Button
          variant="destructive"
          shape="squared"
          type="submit"
          onClick={handleRemove}
          sx={{ marginLeft: "8px" }}
        >
          Remove role
        </Button>
      </div>
    </div>
  );
};
