import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { FlagIcon } from "@hexocean/braintrust-ui-components/Icons";

import style from "./styles.module.scss";

export const ReviewReportedBadge = () => {
  return (
    <div className={style.reviewReportBadge}>
      <div className={style.reviewReportBadgeIconWrapper}>
        <FlagIcon className={style.reviewReportBadgeFlagIcon} />
      </div>
      <div>
        <Typography
          component="span"
          variant="label"
          fontWeight={500}
          size="small"
        >
          You’ve reported this review.
        </Typography>
        <Typography component="p" size="small" variant="paragraph">
          The Braintrust moderation team will review and take appropriate action
          from here.
        </Typography>
      </div>
    </div>
  );
};
