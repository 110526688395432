import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { useAppDispatch } from "@js/hooks";

import { fetchWithdrawalMethods } from "../actions";

export const useFetchWithdrawalMethods = () => {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(fetchWithdrawalMethods());
  });
};
