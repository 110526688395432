import { Navigate, Outlet } from "react-router-dom";
import cs from "classnames";

import {
  Alert,
  Loader,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { showAlert } from "@hexocean/braintrust-ui-components/utils";
import { useUser } from "@js/apps/common/hooks/use-user";
import { JobEmployerDetails } from "@js/apps/employer/components";
import { SimilarJobsSection } from "@js/apps/jobs/components";
import { ExperienceBox } from "@js/apps/jobs/components/experience-box";
import { Rate } from "@js/apps/jobs/components/rate";
import { RoleAndJobTypeBox } from "@js/apps/jobs/components/role-and-job-type-box";
import { ScreeningCard } from "@js/apps/jobs/components/screening-card";
import { JOB_LOCATION, JobLocationContext } from "@js/apps/jobs/context";
import { AttachmentList } from "@js/components/attachment-list";
import { PageTitle } from "@js/components/page-title";
import { useAppDispatch } from "@js/hooks";
import { globalTimeoutsDelays, useTimeout } from "@js/hooks/timeout";
import { useIdParam } from "@js/hooks/use-id-param";
import { AppLayout } from "@js/layouts/app";

import { viewJobDetails } from "../../actions";
import {
  JobDetailsBasicDetails,
  JobHeader,
  JobStatus,
  LocationRequirementWarning,
  MatchersListing,
  ShareThisJobBubble,
} from "../../components";
import { JobDescription } from "../../components/job-description";
import { useJobDetails } from "../../hooks";
import { isJobCompleted } from "../../utils";
import { PublicJobDetails } from "../public-job-details";

import style from "./style.module.scss";

type JobDetailsProps = {
  jobId: number;
};

const JobDetailsWrapper = () => {
  const user = useUser();
  const jobId = useIdParam();

  if (!jobId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  if (!user) {
    return <PublicJobDetails jobId={jobId} />;
  }

  return <JobDetails jobId={jobId} />;
};

const JobDetails = ({ jobId }: JobDetailsProps) => {
  const dispatch = useAppDispatch();
  const { loading, job, bid, employerProfile: employer } = useJobDetails(jobId);

  useTimeout(
    () => {
      if (!jobId) {
        return;
      }
      dispatch(
        viewJobDetails({
          source: JOB_LOCATION.job_details,
          jobUrl: window.location.href,
          jobId,
        }),
      );
    },
    globalTimeoutsDelays.log_view_event,
    [dispatch, jobId],
  );

  const isCompleted = !!job && isJobCompleted(job);
  const displayShareJobBubble = !isCompleted && !!job?.openings_left;
  const hideScreeningCard =
    job?.job_type === ENUMS.JobType.GRANT ||
    job?.status_for_freelancer !== ENUMS.JobFreelancerStatus.PENDING_APPROVAL;

  return (
    <JobLocationContext.Provider value={JobLocationContext.Values.job_details}>
      <AppLayout className={style.job} bgcolor="var(--soft-blue)">
        {loading || !job ? (
          <Loader />
        ) : (
          job && (
            <>
              <PageTitle>{`Job: ${job.title}`}</PageTitle>
              {!!job.referring_user &&
                showAlert(
                  <Alert type="info" withIcon>
                    <Typography
                      component="p"
                      variant="paragraph"
                      size="medium"
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      <Typography component="span" fontWeight={500}>
                        {job.referring_user}
                      </Typography>{" "}
                      referred you for this job, please review and place an
                      application if you think you’d be a good fit!
                    </Typography>
                  </Alert>,
                )}
              <main>
                <JobHeader job={job} bid={bid} withDetailsHeader />
                <section
                  className={cs(style.jobDetails, {
                    [style.jobDetailsWithoutSeniority]:
                      !job.experience_level && !job.level,
                  })}
                >
                  <div className={style.jobStatusBox}>
                    <JobStatus
                      job={job}
                      bid={bid}
                      className={style.jobStatus}
                    />
                  </div>
                  <div className={style.jobRate}>
                    <Rate job={job} bid={bid} />
                  </div>
                  <div className={style.jobBasicDetails}>
                    <JobDetailsBasicDetails
                      boxProps={{
                        className: style.jobBasicDetailsWrapper,
                      }}
                      job={job}
                    />
                  </div>
                  <div className={style.jobWarningBadge}>
                    <LocationRequirementWarning
                      job={{
                        ...job,
                        strong_location_requirement_met:
                          job.strong_location_requirement_met,
                        strong_timezone_requirement_met:
                          job.strong_timezone_requirement_met,
                      }}
                    />
                  </div>
                  <div className={style.jobSeniority}>
                    <ExperienceBox
                      experienceAsArrow={job.experience_as_arrow}
                      experienceLevel={job.experience_level}
                      jobRole={job.role}
                      level={job.level}
                    />
                  </div>
                  <div className={style.jobTopSkills}>
                    <RoleAndJobTypeBox
                      jobType={job.job_type}
                      jobRole={job.role}
                    />
                  </div>
                </section>
                {displayShareJobBubble && (
                  <div className={style.jobMobileRefer}>
                    <ShareThisJobBubble job={job} />
                  </div>
                )}
                <section className={style.jobContent}>
                  <section style={{ overflow: "hidden" }}>
                    <JobDescription job={job} />
                    {!!job.attachments.length && (
                      <RoundedBox mt={3}>
                        <AttachmentList attachments={job.attachments} />
                      </RoundedBox>
                    )}
                    <div className={style.jobBottomStatusBox}>
                      <JobStatus job={job} bid={bid} longTile />
                    </div>
                  </section>
                  <section className={style.jobEmployerColumn}>
                    {!hideScreeningCard && <ScreeningCard />}
                    {displayShareJobBubble && (
                      <div className={style.jobRefer}>
                        <ShareThisJobBubble job={job} />
                      </div>
                    )}
                    <div>
                      {!employer ? (
                        <Loader />
                      ) : (
                        <RoundedBox>
                          <JobEmployerDetails
                            employer={employer}
                            jobId={job.id}
                          />
                        </RoundedBox>
                      )}
                    </div>
                    <div>
                      <MatchersListing job={job} />
                    </div>
                    <div className={style.jobBottomRate}>
                      <Rate job={job} bid={bid} isCompleted={isCompleted} />
                    </div>
                  </section>
                  {displayShareJobBubble && (
                    <div className={style.jobMobileRefer}>
                      <ShareThisJobBubble job={job} />
                    </div>
                  )}
                </section>
              </main>
            </>
          )
        )}
        <div className={style.jobSimilarJobs}>
          <SimilarJobsSection jobId={jobId} />
        </div>
        <Outlet />
      </AppLayout>
    </JobLocationContext.Provider>
  );
};

export default JobDetailsWrapper;
