import { createFiltersFormAndHook } from "@js/apps/filters/";

import styles from "./styles.module.scss";

export const ALL_OFFERS = "ALL_OFFERS";

type OfferStatusOption = {
  label: EnumType<typeof ENUMS.HelpOfferStatusFilterLabels> | "All offers";
  value: EnumType<typeof ENUMS.HelpOfferStatusFilter> | typeof ALL_OFFERS;
};

type HelpOffers11Filters = {
  status: OfferStatusOption["value"];
};

export const OFFER_STATUS_OPTIONS: OfferStatusOption[] = [
  {
    label: "All offers",
    value: "ALL_OFFERS",
  },
  {
    label: ENUMS.HelpOfferStatusFilterLabels.ACCEPTED,
    value: ENUMS.HelpOfferStatusFilter.ACCEPTED,
  },
  {
    label: ENUMS.HelpOfferStatusFilterLabels.PENDING,
    value: ENUMS.HelpOfferStatusFilter.PENDING,
  },
  {
    label: ENUMS.HelpOfferStatusFilterLabels.COMPLETED,
    value: ENUMS.HelpOfferStatusFilter.COMPLETED,
  },
  {
    label: ENUMS.HelpOfferStatusFilterLabels.REPORTED,
    value: ENUMS.HelpOfferStatusFilter.REPORTED,
  },
];

const STATUS_DEFAULT_VALUE = OFFER_STATUS_OPTIONS[0].value;

const defaultFormValues: HelpOffers11Filters = {
  status: STATUS_DEFAULT_VALUE,
};

export const [HelpOffers11FiltersForm, useHelpOffers11Filters] =
  createFiltersFormAndHook<HelpOffers11Filters, HelpOffers11Filters>(
    {
      useAllURLParams: true,
      defaultFormValues: defaultFormValues,
      defaultParamsValues: defaultFormValues,
      submitOnChange: true,
    },
    { className: styles.helpOfferFilter },
  );
