import { API } from "@js/api";

export type FreelancerAvailabilityParams = { id: number } & (
  | {
      availability_for_work: false;
    }
  | {
      availability_for_work: true;
      availability_for_work_options: string[];
      working_hours_tz_abbr: string;
      working_hours_start: number;
      working_hours_end: number;
    }
);

const availableForWorkApi = API.injectEndpoints({
  endpoints: (builder) => {
    return {
      updateFreelancerAvailability: builder.mutation<
        void,
        FreelancerAvailabilityParams
      >({
        query: ({ id, ...rest }) => ({
          url: `/manage_freelancers/${id}/update_availability`,
          method: "POST",
          data: rest,
        }),
      }),

      closeAvailabilityCoachMark: builder.mutation<void, { id: number }>({
        query: ({ id }) => ({
          url: `/manage_freelancers/${id}/availability_coachmark_was_shown/`,
          method: "POST",
        }),
      }),
    };
  },
});

export const {
  useUpdateFreelancerAvailabilityMutation,
  useCloseAvailabilityCoachMarkMutation,
} = availableForWorkApi;
