import { useMemo } from "react";

import { formatDate } from "@js/utils";

import { useProductCheckoutModalContext } from "../../product-checkout-modal-context";

export const usePurchaseSummary = () => {
  const {
    formattedRequiredTotalBtrst,
    isSufficientBtrst,
    upgradeType,
    upgradeCost,
  } = useProductCheckoutModalContext();

  const product = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return {
          name: "Application Boosts",
          packageInfo: "Includes 3 credits of Application Boosts",
          price: upgradeCost,
        };

      case "insights": {
        const currentDate = new Date();
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + 30);
        const insightsExpiration = formatDate(futureDate, "MMM D, YYYY");
        return {
          name: "Application Insights",
          packageInfo: `Includes 30 days of Application Insights: Ends ${insightsExpiration}`,
          price: upgradeCost,
        };
      }

      default:
        return {
          name: "",
          packageInfo: "",
          price: "",
        };
    }
  }, [upgradeCost, upgradeType]);

  return {
    formattedRequiredTotalBtrst,
    isSufficientBtrst,
    product,
  };
};
