import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";

export const UploadLoader = ({
  uploadingFileName,
  cancelFileUpload,
}: {
  uploadingFileName: string;
  cancelFileUpload: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: 2,
        gap: "12px",
        padding: "13px 12px",
        backgroundColor: "var(--soft-violet)",
        alignItems: "self-start",
      }}
    >
      <Box sx={{ color: "var(--dark-violet) !important", paddingTop: "4px" }}>
        <Loader size={16} />
      </Box>
      <Typography component="p" size="small" flex={1} lineHeight={24}>
        Uploading{" "}
        <Typography component="span" size="small" fontWeight={500}>
          {uploadingFileName}
        </Typography>
      </Typography>
      <button
        className="btn-reset pointer"
        style={{ paddingTop: "4px" }}
        onClick={cancelFileUpload}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/trash.svg`}
          alt="cancel"
          height="16"
          width="16"
        />
      </button>
    </Box>
  );
};
