import React, { useState } from "react";
import { SubmissionError, submit } from "redux-form";
import { useStripe } from "@stripe/react-stripe-js";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { US_COUNTRY_CODE } from "@js/apps/common/constants";
import { useUser } from "@js/apps/common/hooks/use-user";
import { Modal } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { StripeACHPayment } from "@js/types/payments";

import { StripeACHBankAccountTypes } from "../../constants";
import type { CreateACHPaymentMethodStripeResult } from "../../types";
import { NullStripeFeedback } from "..";

import type { ACHMethodFormData } from "./form";
import { ACH_FORM_ID, ACHForm } from "./form";

import style from "./style.module.scss";

type AddStripeACHProps = {
  onSubmit: (
    values: CreateACHPaymentMethodStripeResult,
  ) => Promise<StripeACHPayment | undefined>;
};

const ADD_STRIPE_ACH_MODAL_ID = "ADD_STRIPE_ACH_MODAL";
const POST_ADD_STRIPE_ACH_MODAL_ID = "POST_STRIPE_ACH_MODAL_ID";

export const AddStripeACHModalInstance = Modal(ADD_STRIPE_ACH_MODAL_ID, {
  keepOnBackdropClick: true,
});
const PostAddStripeACHModalInstance = Modal(POST_ADD_STRIPE_ACH_MODAL_ID, {
  className: style.modalContent,
  keepOnBackdropClick: true,
  closeButtonProps: { sx: { svg: { m: 0 } } },
});

export const AddStripeACH = ({ onSubmit }: AddStripeACHProps) => {
  const dispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState(false);

  const user = useUser();
  const stripe = useStripe();

  const handleOnSubmit = async (values: ACHMethodFormData) => {
    try {
      const updatedValues = {
        ...values,
        currency: SETTINGS.DEFAULT_CURRENCY.toLowerCase(),
      };

      setSubmitting(true);

      if (!stripe) {
        Snackbar.error(
          "Stripe is not available, please refresh the page and try again.",
        );
        return;
      }

      const stripeResult = await stripe.createToken("bank_account", {
        ...updatedValues,
      });

      if (stripeResult.error) {
        throw new Error(stripeResult.error?.message);
      }

      const apiSubmitResult = await onSubmit({
        ...updatedValues,
        ...stripeResult.token,
      });

      if (apiSubmitResult === undefined) {
        throw new Error("Something went wrong.");
      }

      PostAddStripeACHModalInstance.open();
      AddStripeACHModalInstance.close();

      return apiSubmitResult;
    } catch (error) {
      const _error = error as {
        data?: Record<string, string>;
        message?: string;
      };

      Snackbar.error(
        _error.data?._error || "Failed to add payment method, please try again",
        { preventDuplicate: true },
      );

      throw new SubmissionError(_error.data || { _error: _error.message });
    } finally {
      setSubmitting(false);
    }
  };

  const validate = (values: ACHMethodFormData) => {
    const errors: { [k in keyof ACHMethodFormData]?: string } = {};

    if (!values["country"]) {
      errors["country"] = "This field is required.";
    }

    if (!values["account_holder_type"]) {
      errors["account_holder_type"] = "This field is required.";
    }

    if (!values["account_holder_name"]) {
      errors["account_holder_name"] = "This field is required.";
    }

    if (!values["routing_number"]) {
      errors["routing_number"] = "This field is required.";
    }

    if (!values["account_number"]) {
      errors["account_number"] = "This field is required.";
    }

    return errors;
  };

  return (
    <div>
      <AddStripeACHModalInstance>
        <NullStripeFeedback stripe={stripe}>
          <div className={style.modalContent}>
            <Typography component="h1" variant="title" fontWeight={400}>
              Add ACH Details
            </Typography>
            <div className={style.formElement}>
              <ACHForm
                onSubmit={handleOnSubmit}
                validate={validate}
                initialValues={{
                  country: US_COUNTRY_CODE,
                  account_holder_type: StripeACHBankAccountTypes.COMPANY,
                  account_holder_name: user?.public_name,
                }}
              />
            </div>
            <div className="buttons right">
              <Button
                variant="primary"
                onClick={AddStripeACHModalInstance.close}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={submitting}
                onClick={() => dispatch(submit(ACH_FORM_ID))}
              >
                Save
              </Button>
            </div>
          </div>
        </NullStripeFeedback>
      </AddStripeACHModalInstance>
      <PostAddStripeACHModalInstance>
        <Typography component="p">
          The payment method has to be verified before you can transfer the
          refundable deposit. To verify this payment method, you will need to
          enter in the value of the two small deposits. Two small deposits
          should be in the bank account selected in 1-2 days. If you are sending
          an offer to talent and using ACH, the offer will be sent once the
          total offer deposit has been collected. For immediate verification,
          enter in a credit card instead.
        </Typography>
        <div className="buttons right">
          <Button
            variant="secondary"
            color="secondary"
            onClick={PostAddStripeACHModalInstance.close}
          >
            Close
          </Button>
        </div>
      </PostAddStripeACHModalInstance>
    </div>
  );
};
