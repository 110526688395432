import type { AnyAction } from "redux";

import {
  ADD_NOT_INTERESTED_JOB_ID,
  CLOSE_POST_ETIQUETTE_PANEL,
  DELETE_NOT_INTERESTED_JOB_ID,
  FETCH_FREELANCER_JOBS_SELECT,
  FETCH_FREELANCER_JOBS_SELECT_FAILED,
  FETCH_FREELANCER_JOBS_SELECT_SUCCESS,
  FETCH_FREELANCER_PROFILE,
  FETCH_FREELANCER_PROFILE_FAILED,
  FETCH_FREELANCER_PROFILE_SUCCESS,
  FETCHING_FREELANCER_EVENTS,
  FETCHING_FREELANCER_EVENTS_FAILED,
  FETCHING_FREELANCER_EVENTS_SUCCESS,
  UPDATE_FREELANCER_PROFILE_SUCCESS,
} from "@js/apps/freelancer/action-types";
import type { FreelancerState } from "@js/types/freelancer";

const INITIAL_STATE: FreelancerState = {
  freelancerProfile: undefined,
  fetchingFreelancerProfile: false,

  fetchingJobList: false,
  jobList: [],

  fetchingJobListSelect: false,
  jobSelectList: [],

  notInterestedJobIds: [],

  fetchingEvents: false,
  events: {
    results: [],
    count: 0,
  },
};

export default (state = INITIAL_STATE, action: AnyAction): FreelancerState => {
  switch (action.type) {
    case FETCH_FREELANCER_PROFILE:
      return {
        ...state,
        fetchingFreelancerProfile: true,
      };

    case FETCH_FREELANCER_PROFILE_SUCCESS:
      // For now, we only support one freelancer profile.
      return {
        ...state,
        freelancerProfile: action.payload,
        fetchingFreelancerProfile: false,
      };

    case FETCH_FREELANCER_PROFILE_FAILED:
      return {
        ...state,
        fetchingFreelancerProfile: false,
      };

    case UPDATE_FREELANCER_PROFILE_SUCCESS:
      return {
        ...state,
        freelancerProfile: { ...state.freelancerProfile, ...action.payload },
      };

    case FETCH_FREELANCER_JOBS_SELECT:
      return {
        ...state,
        fetchingJobListSelect: true,
      };
    case FETCH_FREELANCER_JOBS_SELECT_SUCCESS:
      return {
        ...state,
        fetchingJobListSelect: false,
        jobSelectList: action.payload,
      };
    case FETCH_FREELANCER_JOBS_SELECT_FAILED:
      return {
        ...state,
        fetchingJobListSelect: false,
        jobSelectList: [],
      };

    case ADD_NOT_INTERESTED_JOB_ID:
      return {
        ...state,
        notInterestedJobIds: [...state.notInterestedJobIds, action.payload],
      };

    case DELETE_NOT_INTERESTED_JOB_ID:
      return {
        ...state,
        notInterestedJobIds: state.notInterestedJobIds.filter(
          (id) => id !== action.payload,
        ),
      };

    case FETCHING_FREELANCER_EVENTS:
      return {
        ...state,
        fetchingEvents: true,
      };
    case FETCHING_FREELANCER_EVENTS_SUCCESS:
      return {
        ...state,
        fetchingEvents: false,
        events: action.payload,
      };
    case FETCHING_FREELANCER_EVENTS_FAILED:
      return {
        ...state,
        fetchingEvents: false,
        events: null,
      };
    case CLOSE_POST_ETIQUETTE_PANEL:
      if (state.freelancerProfile) {
        const updatedUser = {
          ...state.freelancerProfile.user,
          has_started_creating_post: true,
        };

        return {
          ...state,
          freelancerProfile: {
            ...state.freelancerProfile,
            user: updatedUser,
          },
        };
      }
      return state;

    default:
      return state;
  }
};
