import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import URI from "urijs";

import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import type {
  ExpandBid,
  IsBidExpanded,
} from "@js/apps/jobs/hooks/expanded-bids";
import { useNavigate } from "@js/hooks";
import type { EmployerBidListBid } from "@js/types/jobs";
import { isInteractiveElementClicked } from "@js/utils";

import { useMarkBidAsViewedMutation } from "../../api";

type BidListProps = {
  bid: EmployerBidListBid;
  jobId: number;
};

type BidListItemReturnType = {
  linkToSidePanel: string;
  isSkillsAndQASectionEmpty: boolean;
  handleClickWithinItem: (
    ev: React.MouseEvent<HTMLDivElement>,
  ) => Promise<void>;
  isBidExpanded: IsBidExpanded | null;
  expandBid: ExpandBid | null;
};

export const useBidListItem = ({
  bid,
  jobId,
}: BidListProps): BidListItemReturnType => {
  const [markBidAsViewed] = useMarkBidAsViewedMutation();
  const { search } = useLocation();
  const { isBidExpanded, expandBid } = useBidsContext();
  const navigate = useNavigate();
  const linkToSidePanel = `/jobs/${jobId}/proposals/${bid.id}${search}`;
  const isSkillsAndQASectionEmpty =
    !bid.freelancer.superpower_skills.length && !bid.application_answers.length;

  const handleClickWithinItem = useCallback(
    async (event: React.MouseEvent<HTMLDivElement>) => {
      if (bid.status === ENUMS.BidStatus.NEW) {
        const interactiveElementClicked = isInteractiveElementClicked(event);

        if (!interactiveElementClicked) return;

        await markBidAsViewed({ bidId: bid.id });
      } else {
        if (!isElementClickedHasOnClick(event)) {
          const url = new URI(linkToSidePanel);
          if (bid.interview?.scorecard_completed_at) {
            url.setQuery("tab", "interview");
          }

          navigate(url.toString(), {
            state: { prevPath: window.location.pathname },
          });
        }
      }
    },
    [bid, markBidAsViewed, linkToSidePanel, navigate],
  );

  return {
    linkToSidePanel,
    isSkillsAndQASectionEmpty,
    handleClickWithinItem,
    isBidExpanded,
    expandBid,
  };
};

export const isElementClickedHasOnClick = (
  event: React.MouseEvent<HTMLElement>,
): boolean => {
  const element = event.target as HTMLElement;
  if (!element) return false;

  const tagName = element.tagName.toUpperCase();

  // Check for interactive elements
  const interactiveElements = ["INPUT", "A", "BUTTON", "TEXTAREA"];
  if (interactiveElements.includes(tagName)) return true;

  // Check for SVG or PATH inside a button
  if ((tagName === "SVG" || tagName === "PATH") && element.closest("button"))
    return true;

  // Check for elements with onclick attribute
  const elementsWithPossibleOnClick = ["DIV", "LI", "SPAN", "LABEL", "SVG"];
  if (elementsWithPossibleOnClick.includes(tagName) && element.onclick)
    return true;

  // Check for elements with href or src attributes
  if (element.hasAttribute("href") || element.hasAttribute("src")) return true;

  // Check for elements with specific classes
  const specificClasses = [
    "modal",
    "rating-card",
    "tooltip",
    "chip",
    "MenuItem",
  ];
  const hasSpecificClass = specificClasses.some((className) =>
    element.closest(`[class*="${className}"]`),
  );
  if (hasSpecificClass) return true;

  return false;
};
