import type { BaseQueryFn, SubscriptionOptions } from "@reduxjs/toolkit/query";
import type {
  TypedUseQuery,
  TypedUseQueryStateOptions,
} from "@reduxjs/toolkit/query/react";

import type { AppDispatch } from "@js/store";

export const mapTypedDispatchToProps = (dispatch: AppDispatch) => ({
  dispatch,
});

export const getUseQueryHookWithDefaultOptions =
  <ResultType, QueryArg, BaseQuery extends BaseQueryFn>(
    useQueryHook: TypedUseQuery<ResultType, QueryArg, BaseQuery>,
    defaultOptions: DistributiveOmit<
      SubscriptionOptions &
        TypedUseQueryStateOptions<ResultType, QueryArg, BaseQuery>,
      "selectFromResult" | "skip"
    > & { refetchOnMountOrArgChange?: number | boolean },
  ): TypedUseQuery<ResultType, QueryArg, BaseQuery> =>
  (arg, options) => {
    return useQueryHook(arg, { ...defaultOptions, ...options });
  };
