export const ACCESS_RULE = {
  NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
  NOT_AUTHENTICATED_OR_IN_IFRAME: "NOT_AUTHENTICATED_OR_IN_IFRAME",
  AUTHENTICATED: "AUTHENTICATED",
  ACCOUNT_TYPE_SELECTED: "ACCOUNT_TYPE_SELECTED",
  ACCOUNT_TYPE_NOT_SELECTED: "ACCOUNT_TYPE_NOT_SELECTED",
  EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED",
  HAS_EMPLOYER: "HAS_EMPLOYER",
  HAS_FREELANCER: "HAS_FREELANCER",
  FREELANCER_APPROVED: "FREELANCER_APPROVED",
  IS_SUPER_USER: "IS_SUPER_USER",
  IS_SCREENER: "IS_SCREENER",
  EMPLOYER_HAS_ADDRESS_SET: "EMPLOYER_ADDRESS_SET",
  CAN_MANAGE_JOBS: "CAN_MANAGE_JOBS",
  CAN_VIEW_CUSTOMER_INVOICE_REPORT: "CAN_VIEW_CUSTOMER_INVOICE_REPORT",
  CAN_GRANT_TOKEN_REWARD: "CAN_GRANT_TOKEN_REWARD",
  CAN_VIEW_TOKEN_WITHDRAWALS: "CAN_VIEW_TOKEN_WITHDRAWALS",
  CAN_VIEW_REWARDS_CENTER: "CAN_VIEW_REWARDS_CENTER",
  CAN_VIEW_MONEY_FLOW_DASHBOARD: "CAN_VIEW_MONEY_FLOW_DASHBOARD",
  CAN_VIEW_REWARD_CENTER: "CAN_VIEW_REWARD_CENTER",
  CAN_VIEW_REFERRALS_REPORT: "CAN_VIEW_REFERRALS_REPORT",
  CAN_VIEW_DEPOSIT_REPORT: "CAN_VIEW_DEPOSIT_REPORT",
  CAN_VIEW_TALENTS_WITH_JOBS_REPORT: "CAN_VIEW_TALENTS_WITH_JOBS_REPORT",
  CAN_VIEW_ALL_JOBS_REPORT: "CAN_VIEW_ALL_JOBS_REPORT",
  CAN_ACCESS_TALENT_LOCATION_REPORT: "CAN_ACCESS_TALENT_LOCATION_REPORT",
  CAN_ACCESS_DATA_FOR_TALENT_REPORT: "CAN_ACCESS_DATA_FOR_TALENT_REPORT",
  CAN_VIEW_EMPLOYER_INVOICES: "CAN_VIEW_EMPLOYER_INVOICES",
  CAN_VIEW_TOKEN_BALANCE_REPORT: "CAN_VIEW_TOKEN_BALANCE_REPORT",
  CAN_VIEW_OFFER_REPORT: "CAN_VIEW_OFFER_REPORT",
  IS_OPS_OR_SALES: "IS_OPS_OR_SALES",
  CAN_MANAGE_TOKEN_GRANT_PLANS: "CAN_MANAGE_TOKEN_GRANT_PLANS",
  CAN_MANAGE_TOKEN_TAXES_REPORT: "CAN_MANAGE_TOKEN_TAXES_REPORT",
  CAN_VIEW_TOKEN_GRANT_PLANS: "CAN_VIEW_TOKEN_GRANT_PLANS",
  CAN_VIEW_SUGGESTED_CATEGORIES_REPORT: "CAN_VIEW_SUGGESTED_CATEGORIES_REPORT",
  CAN_VIEW_HELP_OFFER_PAYMENTS_REPORT: "CAN_VIEW_HELP_OFFER_PAYMENTS_REPORT",

  get ACCOUNT_ALL_SET() {
    return [this.AUTHENTICATED, this.ACCOUNT_TYPE_SELECTED];
  },
} as const;

export const SEARCH_PARAMS = {
  POST_MODAL_VISIBLE: "post_modal_visible",
} as const;
