import React from "react";

import {
  Box,
  EmployerLogo,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { BookmarkJobButton } from "@js/apps/bookmarks/components";
import { useCtaClickedContext } from "@js/apps/common/context";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { EmployerLogoWithNameAndColor } from "@js/apps/employer/components/profile/employer-logo-with-name-and-color";
import { ApplicationStatusWithTooltip } from "@js/apps/jobs/components/application-status";
import { BasicInfo } from "@js/apps/jobs/components/basic-info";
import { ContractTypeDetail } from "@js/apps/jobs/components/job-basic-details/details";
import { JobBudgetWithPaymentTypeTooltip } from "@js/apps/jobs/components/job-budget-with-payment-type-tooltip";
import { JobForYouBasicInfo } from "@js/apps/jobs/components/job-for-you-basic-info";
import { getSkillForNotFreelancerView } from "@js/apps/jobs/components/job-item/utils";
import { JobSkills } from "@js/apps/jobs/components/job-skills";
import { JobTags } from "@js/apps/jobs/components/job-type-and-role-tags/job-tags";
import { JobTypeTagWithTooltip } from "@js/apps/jobs/components/job-type-tag-with-tooltip";
import { ShareJobButton } from "@js/apps/jobs/components/share-job-button";
import { ViewJobButton } from "@js/apps/jobs/components/view-job-button";
import {
  DEFAULT_LISTING_BG_COLOR,
  FALLBACK_BG_COLOR,
} from "@js/apps/jobs/constants";
import { RouterLink } from "@js/components/link";
import type { JobListingJob } from "@js/types/jobs";

import { EmployerNameAndJobTitle } from "../employer-name-and-job-title";

import styles from "./styles.module.scss";

type JobCarouselItemProps = {
  job: JobListingJob;
  hideSkills?: boolean;
  borderColor?: string;
};

export const JobCarouselItem = ({
  job,
  hideSkills,
  borderColor = "var(--soft-beige)",
}: JobCarouselItemProps) => {
  const { isFreelancer } = useAccountType();

  const skillToDisplay = isFreelancer
    ? job.main_skills
    : getSkillForNotFreelancerView(job.job_skills);

  const { logo_background_color } = job.employer;

  return (
    <Box
      className={styles.jobCarouselItem}
      border={`1px solid ${borderColor}`}
      tabIndex={0}
    >
      <Box
        bgcolor={`var(${logo_background_color || DEFAULT_LISTING_BG_COLOR})`}
        className={styles.jobCarouselItemHeader}
      >
        <EmployerLogoWithNameAndColor
          employer={job.employer}
          jobId={job.id}
          size="medium"
          gap={14}
          nameStyle={{ fontSize: "16px" }}
          openInNewTab
        />
      </Box>
      <Box className={styles.jobCarouselItemContent}>
        <Box>
          <JobTags jobType={job.job_type} role={job.role} mt={0} />

          <Typography
            component="h5"
            variant="paragraph"
            size="large"
            mt={1.5}
            multilineEllipsis={2}
            className="capitalize-first-letter"
          >
            {job.title}
          </Typography>

          <Box className={styles.jobCarouselItemBudget}>
            <JobBudgetWithPaymentTypeTooltip
              job={job}
              variant="title"
              size="small"
              fontWeight={400}
            />
          </Box>

          <BasicInfo
            job={job}
            flexDirection="column"
            additionalInfo={<ContractTypeDetail job={job} variant="listing" />}
          />
        </Box>

        <Box
          className={styles.jobCarouselItemCta}
          height={hideSkills ? "unset" : "192px"}
        >
          {!hideSkills && (
            <Box mt={2}>
              <JobSkills skills={skillToDisplay} />
            </Box>
          )}
          <Box className={styles.jobCarouselItemActions}>
            {isFreelancer && <BookmarkJobButton job={job} />}
            <ViewJobButton
              variant="primary"
              job={job}
              className="width-100"
              style={{ height: 32 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const JobForYouCarouselItem = ({
  job,
}: Omit<JobCarouselItemProps, "hideSkills" | "borderColor">) => {
  const { ctaClicked } = useCtaClickedContext();
  const { isFreelancer } = useAccountType();
  const user = useUser();

  const showJobStatus =
    job.status_for_freelancer === ENUMS.JobFreelancerStatus.INVITED_BY_CLIENT ||
    job.status_for_freelancer === ENUMS.JobFreelancerStatus.INVITED_BY_MATCHER;

  const { logo_background_color, logo_thumbnail } = job.employer;
  const bgColor = logo_thumbnail
    ? `var(${logo_background_color || DEFAULT_LISTING_BG_COLOR})`
    : FALLBACK_BG_COLOR;
  return (
    <Box className={styles.jobForYouCarouselItem} tabIndex={0}>
      <Box className={styles.jobForYouCarouselItemHeader}>
        <EmployerLogo
          className={styles.jobForYouCarouselItemLogo}
          src={{ logo_thumbnail, name: job.employer.name }}
          fallbackTextColor="dark-violet"
          bgColor={bgColor}
          fallbackTextSize="medium"
        />
        <Box className={styles.jobForYouCarouselItemActions}>
          <Box display="flex">
            <JobTypeTagWithTooltip jobType={job.job_type} />
            <Box display="flex" alignItems="center" height="fit-content">
              {isFreelancer && (
                <ShareJobButton
                  job={job}
                  className={styles.jobForYouCarouselItemShare}
                />
              )}
              {isFreelancer && (
                <BookmarkJobButton
                  disabled={!user?.is_verified}
                  job={job}
                  className={styles.jobForYouCarouselItemSaveJob}
                />
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={1}>
            {showJobStatus && (
              <ApplicationStatusWithTooltip
                status={job.status_for_freelancer}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box className={styles.jobForYouCarouselItemContent}>
        <Box>
          <EmployerNameAndJobTitle job={job} />
        </Box>

        <Box>
          <JobBudgetWithPaymentTypeTooltip
            job={job}
            variant="title"
            size="small"
            fontWeight={400}
          />
        </Box>

        <Box>
          <JobForYouBasicInfo job={job} />
        </Box>
      </Box>

      <RouterLink
        to={`/jobs/${job.id}`}
        onClick={() => {
          ctaClicked(ENUMS.UserClicksEventCTAName.VIEW_JOB);
        }}
      >
        <Box className={styles.jobForYouCarouselItemLink} />
      </RouterLink>
    </Box>
  );
};
