import { useEffect } from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components";
import {
  welcomeModalClosed,
  welcomeModalLinkClicked,
  welcomeModalPostJobClicked,
} from "@js/apps/employer/actions";
import { openCreateJobModal } from "@js/apps/employer/components/create-job-modal";
import { useCheckJobsLimit } from "@js/apps/employer/hooks/check-jobs-limit";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";

import { Interview, Job, TopMatches } from "./components";

import styles from "./styles.module.scss";

type EmployerWelcomeModalProps = {
  onModalView: () => void;
  firstName: string;
};

export const openEmployerWelcomeModal = ({
  onModalView,
  firstName,
}: EmployerWelcomeModalProps) => {
  return ModalInstance.open({
    className: styles.welcomeModal,
    closeButton: false,
    keepOnBackdropClick: true,
    disableEscapeKeyDown: true,
    padding: false,
    children: (
      <EmployerWelcomeModalContent
        firstName={firstName}
        onModalView={onModalView}
      />
    ),
  });
};

const EmployerWelcomeModalContent = ({
  onModalView,
  firstName,
}: EmployerWelcomeModalProps) => {
  useEffect(() => {
    onModalView();
  }, [onModalView]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box className={styles.content}>
        <Header name={firstName} />
        <Content />
      </Box>
      <MobileWrapper>
        <Footer />
      </MobileWrapper>
    </Box>
  );
};

const Header = ({ name }: { name: string }) => {
  return (
    <Box textAlign="center">
      <Typography component="h2" variant="title" size="small" fontWeight={400}>
        Welcome to Braintrust, {name}! 👋
      </Typography>
      <Typography component="h3" variant="label" size="medium" mt={1.5}>
        Ready to find your perfect candidate?
      </Typography>
    </Box>
  );
};

const Content = () => {
  return (
    <Box display="flex" justifyContent="center" gap="28px" flexWrap="wrap">
      <Job />
      <TopMatches />
      <Interview />
    </Box>
  );
};

const Footer = () => {
  const dispatch = useAppDispatch();
  const { checkJobsLimit } = useCheckJobsLimit();
  const isMobile = useMediaQuery("sm");
  const buttonSize = isMobile ? "x-small" : "medium";

  const handlePostJobClick = () => {
    ModalInstance.close();
    checkJobsLimit({ onLimitNotExceeded: openCreateJobModal });
    dispatch(welcomeModalPostJobClicked());
  };

  const handleCloseClick = () => {
    ModalInstance.close();
    dispatch(welcomeModalClosed());
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography
        component="link"
        target="_blank"
        RouterLink={RouterLink}
        onClick={() => dispatch(welcomeModalLinkClicked())}
        variant="paragraph"
        size={isMobile ? "small" : "medium"}
        to="https://www.usebraintrust.com/why-braintrust"
        color="grey-2"
        pointer
      >
        Learn about Braintrust
      </Typography>
      <Box display="flex" gap={1}>
        <Button
          variant="white-border-beige"
          size={buttonSize}
          shape="squared"
          onClick={handleCloseClick}
        >
          Close
        </Button>
        <Button
          variant="positive"
          size={buttonSize}
          shape="squared"
          onClick={handlePostJobClick}
        >
          {isMobile ? "Post a job" : "Post a job for free"}
        </Button>
      </Box>
    </Box>
  );
};

const MobileWrapper = ({ children }: { children: JSX.Element }) => {
  const isMobile = useMediaQuery("sm");

  if (!isMobile) {
    return children;
  }

  return <Box className={styles.mobileWrapper}>{children}</Box>;
};
