import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";

import styles from "./styles.module.scss";

type PostTitleProps = {
  isSinglePostView?: boolean;
  title: string;
  postUrl: string;
};

export const PostTitle = ({
  isSinglePostView,
  title,
  postUrl,
}: PostTitleProps) => {
  const isMobile = useMediaQuery("sm");

  const postTitleVariant = isMobile ? "paragraph" : "title";
  const postTitleSize = isMobile ? "large" : "small";
  const shouldTitleBeLink = !isSinglePostView;
  const titleElement = (
    <Box
      component={isSinglePostView ? "h1" : "h2"}
      sx={{ font: "inherit", m: 0 }}
      className="capitalize-first-letter"
    >
      {title}
    </Box>
  );

  if (!shouldTitleBeLink) {
    return (
      <Typography
        className={styles.postTitle}
        component="div"
        fontWeight={400}
        variant={postTitleVariant}
        size={postTitleSize}
      >
        {titleElement}
      </Typography>
    );
  }

  return (
    <Typography
      className={styles.postTitle}
      component={"link"}
      target="_blank"
      href={postUrl}
      rel="noopener"
      fontWeight={400}
      variant={postTitleVariant}
      size={postTitleSize}
      RouterLink={RouterLink}
    >
      {titleElement}
    </Typography>
  );
};
