import React from "react";
import { type Control, useController } from "react-hook-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { HoursRangeSelector } from "@js/apps/available-for-work/forms/fields/hours-range-field";
import { TimezoneField } from "@js/apps/available-for-work/forms/fields/timezone-field";
import { InfoTooltip } from "@js/components/info-tooltip";

import type { AvailabilityFormValues } from "../../hooks/use-availability-form";

type WorkingTimezoneFieldProps = {
  control: Control<AvailabilityFormValues>;
};

export const WorkingTimezoneField = ({
  control,
}: WorkingTimezoneFieldProps) => {
  const { field: availabilityForWorkField } = useController({
    name: "availability_for_work",
    control,
  });

  const {
    field: workingHoursStartField,
    fieldState: { error: workingHoursStartError },
  } = useController({
    name: "working_hours_start",
    control,
  });

  const {
    field: workingHoursEndField,
    fieldState: { error: workingHoursEndError },
  } = useController({
    name: "working_hours_end",
    control,
  });

  const {
    field: timezoneField,
    fieldState: { error: timezoneError },
  } = useController({
    name: "working_hours_tz_abbr",
    control,
  });

  const isDisabled = !availabilityForWorkField.value;

  return (
    <>
      <Typography component="p" size="medium" color="grey-1" mb={2}>
        Hours I’m available to work:{" "}
        <InfoTooltip
          size="16px"
          title="Setting your working hours improves your job matches. If you’re flexible, select a longer window of time."
        />
      </Typography>
      <HoursRangeSelector
        startField={workingHoursStartField}
        endField={workingHoursEndField}
        startError={workingHoursStartError}
        endError={workingHoursEndError}
        disabled={isDisabled}
      />
      <TimezoneField
        field={timezoneField}
        error={timezoneError}
        disabled={isDisabled}
      />
    </>
  );
};
