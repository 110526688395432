import type { FileRejection } from "react-dropzone";
import type { TypedWrappedFieldProps } from "redux-form";

import {
  Box,
  Button,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CheckIcon, ClockIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FileDropzone } from "@js/apps/file-upload";
import type { Attachment } from "@js/types/uploads";

import { useManageIntroVideo } from "./manage-intro-video";
import {
  openRemoveVideoConfirmationModal,
  RemoveVideoConfirmationModal,
} from "./remove-video-confirmation-modal";

type IntroVideoUploadProps = TypedWrappedFieldProps<{
  id: number;
  attachment: Attachment;
}> & {
  onReplaceVideo: (files: File[], rejectedFiles: FileRejection[]) => void;
  onDeleteVideo: () => void;
};

export const VideoCard = ({
  input,
  meta,
  onReplaceVideo,
  onDeleteVideo,
}: IntroVideoUploadProps) => {
  const { videoRef, duration, thumbnail, handlePreview } = useManageIntroVideo(
    input.value.attachment,
  );

  return (
    <Stack
      sx={{
        borderRadius: 2,
        gap: "9px",
        padding: "13px 12px",
        backgroundColor: "var(--soft-violet)",
      }}
    >
      <Box display="flex" gap="14px">
        <img
          src={thumbnail}
          alt="intro_video"
          height={52}
          width={52}
          style={{ objectFit: "contain" }}
        />

        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="start" gap={0.5}>
            {!meta.dirty && (
              <CheckIcon
                style={{
                  fontSize: "15px",
                  color: "var(--positive-2)",
                  marginTop: "4px",
                }}
              />
            )}
            <Typography component="span" size="small">
              <Typography component="span" variant="label" size="small">
                {input?.value?.attachment?.name}
              </Typography>{" "}
              {!meta.dirty && "uploaded"}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <ClockIcon style={{ fontSize: "15px", color: "var(--grey-2)" }} />
            <Typography component="span" size="small" color="grey-2">
              {duration}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gap="10px">
        <Button
          variant="transparent"
          size="x-small"
          onClick={handlePreview}
          sx={{ padding: "4px 0 !important" }}
        >
          <Typography
            component="span"
            size="small"
            sx={{
              textDecoration: "underline",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            <img
              alt="play"
              src={`${SETTINGS.STATIC_URL}jobs/play.svg`}
              width="16"
              height="16"
            />{" "}
            Preview
          </Typography>
        </Button>
        <FileDropzone
          dropzoneOptions={{
            noDrag: true,
            onDrop: onReplaceVideo,
            multiple: false,
            accept: {
              "video/mp4": [],
              "video/quicktime": [],
            },
            maxSize: SETTINGS.JOB_INTRO_VIDEO_FILE_SIZE_LIMIT,
          }}
        >
          {() => (
            <Button
              variant="transparent"
              size="x-small"
              sx={{ padding: "4px 0 !important" }}
            >
              <Typography
                component="span"
                size="small"
                sx={{
                  textDecoration: "underline",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  alt="replace"
                  src={`${SETTINGS.STATIC_URL}jobs/replace.svg`}
                  width="16"
                  height="16"
                />
                Replace
              </Typography>
            </Button>
          )}
        </FileDropzone>
        <Button
          variant="transparent"
          size="x-small"
          onClick={() =>
            openRemoveVideoConfirmationModal({ onConfirm: onDeleteVideo })
          }
          sx={{ padding: "4px 0 !important" }}
        >
          <Typography
            component="span"
            size="small"
            sx={{
              textDecoration: "underline",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            <img
              alt="delete"
              src={`${SETTINGS.STATIC_URL}jobs/trash.svg`}
              width="16"
              height="16"
            />
            Delete
          </Typography>
        </Button>
      </Box>
      <RemoveVideoConfirmationModal />
      <video ref={videoRef} style={{ display: "none" }} />
    </Stack>
  );
};
