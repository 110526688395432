import { Modal } from "@js/components/modal";

import type { AddRecipientsModalContentProps } from "./add-recipients-modal-content";
import { AddRecipientsModalContent } from "./add-recipients-modal-content";

import styles from "./styles.module.scss";

const ADD_RECIPIENTS_MODAL_ID = "add-recipients";
export const AddRecipientsModal = Modal(ADD_RECIPIENTS_MODAL_ID, {
  className: styles.addRecipientsModal,
  closeButton: false,
  keepOnBackdropClick: true,
});

export const openAddRecipientsModal = (
  arg: Pick<AddRecipientsModalContentProps, "handledMethod">,
) => {
  AddRecipientsModal.open({
    children: (
      <AddRecipientsModalContent
        {...arg}
        closeModal={AddRecipientsModal.close}
      />
    ),
  });
};
