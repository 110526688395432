import type { WrappedFieldProps } from "redux-form";
import { Field, Fields, formValueSelector } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { createFormInstance } from "@js/forms/components";
import { RadioListField } from "@js/forms/fields";
import { requiredWithSpecificMessage } from "@js/forms/validators";
import { useAppSelector } from "@js/hooks";
import type { UpdateJobFeedbackData } from "@js/types/jobs";
import { enumToOptions } from "@js/utils";

import {
  RatingFieldWithProps,
  TextFieldWithProps,
} from "./close-filled-job-form";
import { JOB_CLOSE_FIELDS } from "./close-job-constants";

import style from "./style.module.scss";

type FormData = {
  job_not_filled_reason: EnumType<typeof ENUMS.JobNotFilledReasonType>;
  reason_other?: string | null;
  quality_of_applications_received_rate: string;
};

const CloseNotFilledJobFormInstance = createFormInstance<FormData, unknown>(
  "close-job-not-filled-form",
  {
    onChange: (values, dispatch, props) => {
      if (values.job_not_filled_reason !== ENUMS.JobNotFilledReasonType.OTHER) {
        if (props.change && props.untouch && !!values.reason_other) {
          dispatch(props.change(JOB_CLOSE_FIELDS.reason_other, null));
          dispatch(props.untouch(JOB_CLOSE_FIELDS.reason_other));
        }
      }
    },
  },
);

type CloseNotFilledJobFormProps = {
  onCancelClick: () => void;
  onSubmit: (clientFeedback: UpdateJobFeedbackData) => Promise<void>;
};

const closeJobNotFilledSelector = formValueSelector(
  "close-job-not-filled-form",
);

export const CloseNotFilledJobForm: React.FC<CloseNotFilledJobFormProps> = ({
  onCancelClick,
  onSubmit,
}) => {
  const jobNotFilledReason = useAppSelector((state) =>
    closeJobNotFilledSelector(state, "job_not_filled_reason"),
  );

  return (
    <CloseNotFilledJobFormInstance onSubmit={onSubmit}>
      <Box className={style.closeJobFormBody}>
        <Box>
          <Typography component="h1" variant="title" size="small" mb={4}>
            <Box component="span" mr={1}>
              😔
            </Box>{" "}
            Oh no! Sorry to hear that.
          </Typography>
          <Typography component="p" variant="label" mb={1.2}>
            Why didn’t this job get filled?
          </Typography>
          <Field
            name={JOB_CLOSE_FIELDS.job_not_filled_reason}
            options={enumToOptions(ENUMS.JobNotFilledReasonTypeLabels)}
            component={RadioListField}
            yellowOutline
            errorStyle={{
              marginTop: "-10px",
            }}
            validate={[requiredWithSpecificMessage("Please select a reason")]}
            borderRadio={false}
          />
          {jobNotFilledReason === ENUMS.JobNotFilledReasonType.OTHER && (
            <Fields
              names={[
                JOB_CLOSE_FIELDS.job_not_filled_reason,
                JOB_CLOSE_FIELDS.reason_other,
              ]}
              component={JobFilledOtherWay}
              validate={[requiredWithSpecificMessage("Please enter a reason")]}
            ></Fields>
          )}
        </Box>

        <RatingFieldWithProps />

        <Box className={style.closeJobFormBodyButtons}>
          <Button
            variant="secondary"
            size="small"
            onClick={() => {
              onCancelClick();
            }}
          >
            Go back
          </Button>
          <Button variant="primary" size="small" type="submit">
            Close job
          </Button>
        </Box>
      </Box>
    </CloseNotFilledJobFormInstance>
  );
};

type JobFilledOtherWayProps = {
  job_not_filled_reason: WrappedFieldProps;
  reason_other: WrappedFieldProps;
};

const JobFilledOtherWay = ({
  job_not_filled_reason,
  reason_other,
}: JobFilledOtherWayProps) => {
  if (
    job_not_filled_reason.input.value !== ENUMS.JobNotFilledReasonType.OTHER
  ) {
    return null;
  }

  return (
    <TextFieldWithProps
      fieldsName={reason_other}
      placeholder="Enter reason here"
    />
  );
};
