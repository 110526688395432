import type { JobFilters } from "@js/apps/common/components/filters/config";
import type { AIGeneratedJobFormData } from "@js/apps/employer/hooks/generate-ai/utils";
import type { RANGE_VALUE } from "@js/apps/jobs/apps/create-job/constants";
import type { Skill } from "@js/types/admin";
import type { ErrorWithDetail } from "@js/types/errors";
import type { Attachment } from "@js/types/uploads";
import type { IsoDateOrIsoDateTime, IsoDateTime } from "@js/utils/date/types";

import type { AccountType, User } from "./auth";
import type { Address, Location, SavedFilter } from "./common";
import type { Employer, Industry } from "./employer";
import type {
  Freelancer,
  FreelancerForNodeStaff,
  WorkSampleItem,
} from "./freelancer";
import type { PaginatedResult } from "./generic";
import type { PaymentMethod } from "./payments";
import type { Role } from "./roles";

export type TimezoneOption = {
  id: string;
  value: string;
  name: string;
};

export type JobDraftTimezone = {
  timezone: string;
  timezone_id: string;
};

export type Proposal = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    id: number;
    job: EmployerJobMinimal;
    created: IsoDateTime;
    status: EnumType<typeof ENUMS.BidStatus>;
  }[];
};

export type JobMinimalSearchResult = {
  id: number;
  title: string;
  employer_name: string;
  freelancer_already_invited_to_this_job?: boolean;
};

export type JobMinimalSearchParams = {
  search?: string;
  open?: boolean;
  annotate_invited_by_client_or_matcher?: boolean;
  freelancer?: number;
  ops_invitations?: boolean;
};

export type UploadedJob = Partial<
  Pick<JobDraft, "title" | "description" | "flow_entry">
>;

export type JobsState = {
  total: number;
  fetchingFreelancerOffers: boolean;
  freelancerOffers: Array<FreelancerOffer> | null;
  savedSections: string[];
  invitationHistory: InvitationHistoryItem[];
  proposalHistory: Proposal;
  fetchingInvitationHistory: boolean;
  fetchingProposalHistory: boolean;
  fetchingJobEvents: boolean;
  uploadedJobDescription: UploadedJob | null;
  aiGenerated: AIGeneratedJobFormData | null;
  jobEvents: {
    results: Array<JobEvent>;
    count: number;
  };
};

type JobEvent = {
  event: string;
  is_matcher: boolean;
  creator: string;
  created: IsoDateTime;
  job?: string;
  jobs?: { id: number; title: string }[];
  freelancer?: string;
};

export type SkillForJob = Skill & {
  matched: boolean;
  is_superpower: boolean;
};

export type EmployerOfferBid = EmployerBid & {
  job: Pick<
    Job,
    | "creator"
    | "employer"
    | "id"
    | "introduction"
    | "job_type"
    | "level"
    | "role"
    | "title"
  >;
};

export type InitialOffer = {
  anticipated_weekly_hours: number | null;
  batch_invoice_emails: string | null;
  batch_invoices_frequency: Array<string>;
  bid: EmployerOfferBid;
  job_title: string;
  talent_has_own_equipment: boolean;
  payment_amount: string;
  payment_type: EnumType<typeof ENUMS.JobPaymentType>;
  project_duration: EnumType<typeof ENUMS.JobContractType>;
  proposed_end_date: string | null;
  proposed_start_date: string | null;
  require_paperwork: boolean;
  status: EnumType<typeof ENUMS.OfferStatus> | null;
  deposit_payment_method: PaymentMethod | null;
  job_fee_rate: string;
  match_label: keyof typeof ENUMS.SmartMatchingLabels;
  match_summary: MatchSummary[];
  match_score: number;
};

export type SavedJobFilter = SavedFilter<Record<string, string | boolean>>;

export type SavedTalentFilter = SavedFilter<Record<string, string>>;

export type JobAction = {
  action: EnumType<typeof ENUMS.JobActions>;
  state: string;
  reason: string;
};

export type JobSkill = {
  id: number;
  skill: SkillForJob;
  is_top: boolean;
};

export type IntroVideoAttachment = {
  attachment: Attachment;
  id: number;
};

export type JobAttachment = {
  attachment: Attachment;
  id: number;
  link: string;
  safe_file_name: string;
};

export type JobTimezone = {
  timezone: string;
  timezone_utc_offset: string;
};

export type JobLocation = Location;

type BidsCount = {
  hired_bids_count: number;
  interviewing_bids_count: number;
  interviewing_and_active_bids_count: number;
  total_bids_count: number;
};

export type JobOwnersUserData = Pick<
  User,
  | "id"
  | "avatar"
  | "avatar_thumbnail"
  | "can_be_messaged"
  | "first_name"
  | "freelancer_approved"
  | "gravatar"
  | "has_avatar_set"
  | "last_name"
  | "profile_url"
  | "public_name"
  | "title"
>;

export type JobFlowEntryType = "ai" | "custom" | "upload";

export type JobEmployer = Pick<
  Employer,
  | "id"
  | "full_link"
  | "has_logo_set"
  | "logo"
  | "logo_background_color"
  | "logo_thumbnail"
  | "name"
  | "name_color"
> & {
  link: string | null;
  login_as_user_id?: number;
};

export type JobExtension = {
  employer_calendar_link?: string;
  intro_video?: IntroVideoAttachment;
};

export type Job = {
  id: number;
  title: string;
  employer: JobEmployer;
  attachments: Array<JobAttachment>;
  is_open?: boolean;
  is_private: boolean;
  needs_project_manager: boolean;
  promote_listing: boolean;
  disable_freelancer_notifications: boolean;
  user_can_manage: boolean;
  user_is_owner: boolean;
  organization: Organization;
  job_actions: Array<JobAction>;
  job_status: EnumType<typeof ENUMS.JobStatus>;
  job_skills: Array<JobSkill>;
  job_payment_day_of_month: string | null;
  creator: JobCreator | null;
  new_application_questions: Array<ApplicationQuestion>;
  freelancers_with_offers: Array<any>;
  freelancers_hired_count: number;
  freelancer_hired: Freelancer | null;
  offers_status_update: Array<{
    message: string;
  }>;
  level: EnumType<typeof ENUMS.SkillLevel>;
  application_questions: Array<ApplicationQuestion>;
  can_bid: boolean;
  has_freelancer_bid: boolean;
  hired_bids_count: number;
  invited_by_client_or_op: boolean;
  rejected_at: string;
  contract_type: EnumType<typeof ENUMS.JobContractType>;
  created: string;
  deadline: IsoDateOrIsoDateTime | null;
  start_date: IsoDateOrIsoDateTime | null;
  expected_hours_per_week: number | null;
  // Old jobs may not have description but they can have introduction
  introduction?: string;
  description?: string;
  requirements?: string;
  openings_left: number;
  openings_number: number;
  payment_type: EnumType<typeof ENUMS.JobPaymentType>;
  payment_amount: string;
  main_skills: Array<SkillForJob>;
  role: Role;
  other_role_name?: string;
  timezones: JobTimezone[] | null;
  timezone_overlap: EnumType<typeof ENUMS.JobTimezoneOverlapType> | null;
  locations: JobLocation[];
  token_reward: string;
  budget_minimum_usd: string;
  budget_maximum_usd: string;
  referring_user: string | null;
  op_owners: JobOwnersUserData[] | null;
  sale_owners: JobOwnersUserData[] | null;
  estimated_value: string | null;
  published_at: string | null;
  completed_at: IsoDateOrIsoDateTime | null;
  is_saved_by_freelancer: boolean;
  saved_job_id: number | null;
  bids_count: BidsCount;
  job_type: EnumType<typeof ENUMS.JobType>;
  experience_as_arrow?: number;
  experience_level: EnumType<typeof ENUMS.JobExperienceLevel> | null;
  invited_by_client: boolean;
  invited_by_matcher: boolean;
  inviter_public_name: string;
  interested: boolean;
  is_resume_required: boolean;
  strong_location_requirement_met?: boolean;
  strong_timezone_requirement_met?: boolean;
  locations_strongly_required: boolean;
  timezone_required: boolean;
  match_label: EnumType<typeof ENUMS.SmartMatchingLabel> | null;
  is_ai_generated?: boolean;
  is_ai_interview_enabled?: boolean;
  is_boosted: boolean | null;
  bid_id?: number;
  flow_entry?: JobFlowEntryType;
  ai_min_rate?: string;
  ai_max_rate?: string;
  ai_interview_max_duration?: string;
  interview_criteria?: Array<InterviewGradingCriteria> | string[];
  interview_questions?: Array<InterviewQuestion>;
  status_for_freelancer?: EnumType<typeof ENUMS.JobFreelancerStatus>;
  creator_user_id?: number;
  offer_id?: number | null;
  offer_accepted_at: IsoDateTime | null;
  offer_created_at: IsoDateTime | null;
  bid_created_at: IsoDateTime | null;
  extension?: JobExtension | null;
};

export type MyJobFreelancer = Pick<
  Job,
  | "creator"
  | "employer"
  | "expected_hours_per_week"
  | "id"
  | "job_type"
  | "locations"
  | "offer_accepted_at"
  | "offer_id"
  | "payment_amount"
  | "payment_type"
  | "status_for_freelancer"
  | "timezone_overlap"
  | "timezones"
  | "title"
  | "role"
>;

export type JobForCoreTeam = Job & {
  hired_at: string | null;
  on_hold_since: IsoDateOrIsoDateTime | null;
  closed_at: IsoDateOrIsoDateTime | null;
  job_fill_support_type: EnumType<typeof ENUMS.JobFillSupportType>;
};

export type EmployerOwnJob = Pick<
  Job,
  | "created"
  | "freelancer_hired"
  | "freelancers_hired_count"
  | "freelancers_with_offers"
  | "id"
  | "job_skills"
  | "job_actions"
  | "offers_status_update"
  | "role"
  | "title"
  | "completed_at"
  | "hired_bids_count"
> & {
  creator: JobCreator;
  status: Job["job_status"];
  total_bids_count: BidsCount["total_bids_count"];
  subscribed_job_id: number | null;
  ats_imported: boolean;
};

export type JobStatus = EnumType<typeof ENUMS.JobStatus>;

export type ManagedJob = Pick<
  Job,
  | "id"
  | "created"
  | "title"
  | "description"
  | "deadline"
  | "introduction"
  | "application_questions"
  | "attachments"
  | "budget_maximum_usd"
  | "budget_minimum_usd"
  | "can_bid"
  | "contract_type"
  | "creator"
  | "disable_freelancer_notifications"
  | "estimated_value"
  | "expected_hours_per_week"
  | "experience_as_arrow"
  | "experience_level"
  | "hired_bids_count"
  | "is_open"
  | "is_private"
  | "is_resume_required"
  | "job_actions"
  | "job_payment_day_of_month"
  | "job_status"
  | "job_type"
  | "level"
  | "locations"
  | "locations_strongly_required"
  | "openings_left"
  | "openings_number"
  | "payment_type"
  | "promote_listing"
  | "referring_user"
  | "requirements"
  | "start_date"
  | "timezone_overlap"
  | "timezone_required"
  | "timezones"
  | "token_reward"
  | "user_can_manage"
  | "user_is_owner"
  | "is_ai_generated"
  | "is_ai_interview_enabled"
  | "flow_entry"
  | "ai_min_rate"
  | "ai_max_rate"
  | "other_role_name"
  | "ai_interview_max_duration"
  | "interview_criteria"
  | "interview_questions"
  | "extension"
> & {
  can_change_role?: boolean;
  hiring_manager?: number;
  employer: number;
  job_fill_support_type?: EnumType<typeof ENUMS.JobFillSupportType>;
  original_job: number | null;
  role?: number;
  skills: number[];
  top_skills: number[];
  job_subscribers: JobSubscriber[];
  job_subscriber_invitations: JobSubscriberInvitation[];
  auto_invite_talent: boolean;
  op_owners: number[];
  sale_owners: number[];
};

export type JobListingJob = Pick<
  Job,
  | "id"
  | "main_skills"
  | "job_skills"
  | "employer"
  | "creator"
  | "title"
  | "created"
  | "invited_by_client_or_op"
  | "rejected_at"
  | "has_freelancer_bid"
  | "expected_hours_per_week"
  | "budget_minimum_usd"
  | "budget_maximum_usd"
  | "payment_type"
  | "locations"
  | "timezones"
  | "timezone_overlap"
  | "contract_type"
  | "job_status"
  | "application_questions"
  | "role"
  | "saved_job_id"
  | "level"
  | "openings_left"
  | "deadline"
  | "start_date"
  | "job_actions"
  | "op_owners"
  | "sale_owners"
  | "interested"
  | "invited_by_client"
  | "invited_by_matcher"
  | "job_type"
  | "match_label"
  | "bid_id"
  | "is_boosted"
  | "offer_accepted_at"
  | "offer_created_at"
  | "bid_created_at"
  | "status_for_freelancer"
>;

type DefaultMainJobListingJob = Pick<
  Job,
  | "id"
  | "job_skills"
  | "job_type"
  | "employer"
  | "title"
  | "created"
  | "invited_by_client_or_op"
  | "role"
  | "payment_type"
  | "budget_minimum_usd"
  | "budget_maximum_usd"
  | "locations"
  | "timezones"
  | "timezone_overlap"
  | "expected_hours_per_week"
  | "start_date"
  | "deadline"
  | "contract_type"
  | "openings_left"
  | "job_status"
  | "has_freelancer_bid"
  | "invited_by_matcher"
  | "invited_by_client"
  | "interested"
  | "rejected_at"
  | "saved_job_id"
  | "match_label"
  | "is_boosted"
  | "bid_id"
  | "is_open"
  | "status_for_freelancer"
  | "creator_user_id"
  | "offer_id"
  | "offer_accepted_at"
  | "offer_created_at"
  | "bid_created_at"
>;

export type FreelancerMainJobListingJob = DefaultMainJobListingJob &
  Pick<Job, "main_skills" | "has_freelancer_bid">;

export type NodeStaffMainJobListingJob = DefaultMainJobListingJob &
  Pick<
    Job,
    | "creator"
    | "job_actions"
    | "hired_bids_count"
    | "freelancer_hired"
    | "freelancers_hired_count"
  > & {
    op_owners: JobOwnersUserData[];
    sale_owners: JobOwnersUserData[];
  };

export type MainJobListingJob =
  | FreelancerMainJobListingJob
  | DefaultMainJobListingJob
  | NodeStaffMainJobListingJob;

export type ExpandedJobDetails = Pick<
  Job,
  | "contract_type"
  | "deadline"
  | "start_date"
  | "strong_location_requirement_met"
  | "strong_timezone_requirement_met"
> & {
  token_reward: string;
  introduction?: string;
  description?: string;
};

type Organization = {
  id: number;
  city: string;
  industry: Industry;
  name: string;
  organization_size: string;
  overview: string;
  website: string;
  zipcode: string;
  logo_thumbnail?: string;
  has_logo_set: boolean;
};

type BidBase = {
  id: number;
  created: IsoDateTime;
  attachments: BidAttachment[];
  payment_amount: string;
  cover_letter: string | null;
  bt_stake: number;
  accepted_at: string | null;
  closed_at: string | null;
  available_start: string | null;
  freelancer_availability: EnumType<typeof ENUMS.FreelancerAvailability> | null;
  freelancer_availability_specific_date: string | null;
  payment_type: EnumType<typeof ENUMS.JobPaymentType>;
  application_questions: Array<ApplicationQuestion>;
  application_answers: Array<ApplicationAnswer>;
  calendar_url: string | null;
  visits_count: number;
  not_hiring_feedback: Array<string>;
  status: EnumType<typeof ENUMS.BidStatus>;
  location_requirement_met?: boolean;
  timezone_requirement_met?: boolean;
  is_boosted?: boolean;
};

export type FreelancerBidParams = {
  job: number;
  attachments_ids?: number[];
  payment_amount?: string | null;
  bt_stake?: number;
  freelancer_availability?: EnumType<
    typeof ENUMS.FreelancerAvailability
  > | null;
  freelancer_availability_specific_date?: string | null;
  payment_type?: EnumType<typeof ENUMS.JobPaymentType>;
  new_application_answers: Array<
    Partial<ApplicationQuestion> & {
      answer?: string;
    }
  >;
  calendar_url: string | null;
  resume?: ResumeAttachment | number | null;
  store_resume_for_future_bids?: boolean;
  location_requirement_met?: boolean | null;
  timezone_requirement_met?: boolean | null;
  is_boosted?: boolean;
};

export type FreelancerBidCurrentOffer = Pick<
  FreelancerOffer,
  "id" | "payment_amount" | "status"
>;

export type FreelancerBid = BidBase & {
  freelancer: Pick<
    Freelancer["user"],
    | "id"
    | "first_name"
    | "last_name"
    | "public_name"
    | "profile_url"
    | "has_avatar_set"
    | "avatar"
    | "avatar_thumbnail"
  >;
  resume: ResumeAttachment | null;
  job: Job;
  is_hiring_paused: boolean;
  can_edit: boolean;
  can_cancel: boolean;
  current_offer?: FreelancerBidCurrentOffer;
  status_for_freelancer: EnumType<typeof ENUMS.JobFreelancerStatus>;
  current_offer_created_at: IsoDateTime | null;
  current_offer_accepted_at: IsoDateTime | null;
};

type SuperpowerSkill = {
  id: number;
  is_superpower: boolean;
  order: number;
  skill: SkillForJob;
};

export type TalentInviteSuggestion = Pick<
  FreelancerForNodeStaff,
  | "availability_for_work"
  | "availability_for_work_options"
  | "external_profiles"
  | "id"
  | "user"
  | "role"
  | "location"
  | "location_mismatch"
  | "average_internal_review_rating"
  | "internal_review_ratings_count"
> & {
  full_link: string;
  link: string;
  matching_skills: SuperpowerSkill[];
  invite_status: EnumType<typeof ENUMS.TalentInviteStatus> | null;
  personal_score_percent: number;
} & Pick<EmployerBidListBid, "match_label" | "match_score" | "match_summary">;

export const MATCH_SUMMARY_TYPE = {
  POSITIVE: "positive",
  CONSTRUCTIVE: "constructive",
} as const;

export type MatchSummary = {
  sentence: string;
  rank: number;
  summary_type: ObjectValues<typeof MATCH_SUMMARY_TYPE>;
};

export type JobInterviewScorecard = {
  attribute_key: string;
  feedback: string;
  id: number;
  score: number;
};
export type JobInterviewTranscript = {
  name: string;
  text: string;
};

export const INTERVIEW_RATING_STATUS = {
  FAIL: "fail",
  MIXED: "mixed",
  PASS: "pass",
} as const;

export type BidInterviewRating = {
  recommended_rating: string;
  scorecard_status: ObjectValues<typeof INTERVIEW_RATING_STATUS>;
};

export type BidInterview = {
  id: number | null;
  is_completed: boolean;
  interview_id: number | null;
  interview_url: string | null;
  interview_request_sent_at: IsoDateOrIsoDateTime | null;
  overall_feedback: string | null;
  overall_score: string | null;
  scorecard_completed_at: IsoDateOrIsoDateTime | null;
  status: EnumType<typeof ENUMS.JobInterviewStatus>;
  job_interview_scorecards: JobInterviewScorecard[];
  transcript: string | null;
  interview_recording_url: string | null;
  rating: BidInterviewRating | null;
};

export type EmployerBid = BidBase & {
  freelancer: Pick<
    FreelancerForNodeStaff,
    | "approved"
    | "external_profiles"
    | "years_experience"
    | "id"
    | "user"
    | "role"
    | "location"
    | "location_mismatch"
    | "average_internal_review_rating"
    | "internal_review_ratings_count"
  > & {
    address: Address;
    full_link: string;
    link: string;
    average_work_quality: number | null;
    average_responsiveness: number | null;
    review_count: number | null;
    superpower_skills: SuperpowerSkill[];
    freelancer_skills: SuperpowerSkill[];
    portfolio_items: WorkSampleItem[];
  };
  is_boosted: boolean | null;
  can_accept: boolean;
  can_unreject: boolean;
  can_unreject_explanation: string | null;
  can_change_status: boolean;
  compliance_fee_percent: string | null;
  w2_markup_percent: string | null;
  background_fee_amount: string | null;
  can_change_background_fee: boolean;
  braintrust_pays_talent_off_platform_enabled: boolean;
  can_make_offer: boolean;
  can_make_offer_explanation: string | null;
  pending_offers_exist: boolean;
  job_id: number;
  role: Job["role"];
  is_hidden: boolean;
  is_top_match: boolean;
  is_invited?: boolean;
  proposal_score_percent: number;
  employer_reaction: EnumType<typeof ENUMS.BidReactionType> | null;
  recommended_by_matcher: boolean;
  match_label: keyof typeof ENUMS.SmartMatchingLabels;
  match_summary: MatchSummary[];
  match_score: number;
  status: EnumType<typeof ENUMS.BidStatus>;
  application_questions: ApplicationQuestion[];
  application_answers: ApplicationAnswer[];
  calendar_link_filled_score: number;
  notes_count: number;
  resume: ResumeAttachment | null;
  location_requirement_met: boolean | null;
  timezone_requirement_met: boolean | null;
  current_offer: number | null;
  interview: BidInterview | null;
};

export type EmployerBidListBid = Omit<EmployerBid, "freelancer"> & {
  freelancer: Omit<
    EmployerBid["freelancer"],
    "freelancer_skills" | "portfolio_items"
  >;
};

export type Bid = FreelancerBid | EmployerBid;

export type FreelancerOfferJob = Pick<
  Job,
  | "id"
  | "employer"
  | "introduction"
  | "job_type"
  | "title"
  | "expected_hours_per_week"
  | "locations"
  | "timezones"
  | "timezone_overlap"
> & { creator: JobCreator };

export type OfferShippingAddress = {
  id: number;
  addressee_first_name: string;
  addressee_last_name: string;
  address1: string;
  address2?: string;
  zipcode: string;
  city: string;
  country: string;
  country_name: string;
  state: string | null;
  test: string;
};

export type OfferCommon = {
  id: number;
  anticipated_weekly_hours: number | null;
  job_title: string;
  note_to_talent: string | null;
  talent_has_own_equipment: boolean;
  time_of_expiry: string;
  payment_amount: string;
  payment_type: EnumType<typeof ENUMS.JobPaymentType>;
  project_duration: EnumType<typeof ENUMS.JobContractType>;
  proposed_end_date: string | null;
  proposed_start_date: string | null;
  require_paperwork: boolean;
  expired_at: IsoDateOrIsoDateTime | null;
  cancelled_at: IsoDateOrIsoDateTime | null;
  updated_fields: string[];
  shipping_address: OfferShippingAddress | null;
  created: IsoDateOrIsoDateTime;
};

type FreelancerOfferCreator = {
  id: number;
  public_name: string;
  title: string;
  gravatar: string;
  has_avatar_set: boolean;
  avatar: string;
  avatar_thumbnail: string;
};

export type TalentOfferStatus = EnumType<
  Omit<
    typeof ENUMS.OfferStatus,
    | "WAITING_FOR_DEPOSIT"
    | "WAITING_FOR_DEPOSIT_ACH_CONFIRMATION"
    | "DEPOSIT_PAYMENT_FAILED"
    | "DEPOSIT_PAYMENT_IN_PROGRESS"
  >
>;

export type FreelancerOffer = OfferCommon & {
  job: FreelancerOfferJob;
  checksum: string;
  creator: FreelancerOfferCreator | null;
  status: TalentOfferStatus;
  role: Role;
  updated_at: IsoDateOrIsoDateTime | null;
  status_for_freelancer: EnumType<typeof ENUMS.JobFreelancerStatus>;
  accepted_at: IsoDateOrIsoDateTime | null;
  is_boosted: boolean;
};

export type EmployerOffer = OfferCommon & {
  bid: EmployerOfferBid;
  deposit_payment_method: PaymentMethod;
  job_fee_rate: string;
  match_label: keyof typeof ENUMS.SmartMatchingLabels;
  match_summary: MatchSummary[];
  match_score: number;
  on_platform_paying: boolean;
  reject_reason: string | null;
  batch_invoices_frequency: Array<string>;
  batch_invoice_emails: string | null;
  status: EnumType<typeof ENUMS.OfferStatus>;
};

export type BidAttachment = {
  id: number;
  attachment: Attachment;
  link: string;
  safe_file_name: string;
};

export type ResumeAttachment = {
  id: number;
  attachment: Pick<
    Attachment,
    "id" | "name" | "file" | "extension" | "mime" | "file_download_url"
  >;
  link: string;
  safe_file_name: string;
};

export type ApplicationQuestion = {
  id: number;
  question: string;
  is_required: boolean;
  is_active: boolean;
  original_question: number;
};

export type ApplicationAnswer = {
  id: number;
  application_question: number;
  answer: string;
};

type Matcher = {
  avatar: string;
  avatar_thumbnail: string;
  first_name: string;
  freelancer_approved: boolean;
  gravatar: string;
  has_avatar_set: boolean;
  id: number;
  last_name: string;
  profile_url: string;
  public_name: string;
  title: string;
};

export type InvitationHistoryItem = {
  id: number;
  freelancer: {
    company_name: string | null;
    id: number;
    user: Pick<
      User,
      "first_name" | "has_address_set" | "id" | "last_name" | "public_name"
    >;
  };
  job: Pick<Job, "id" | "title"> & { employer_name: string };
  matcher: Matcher;
};

export type JobDraft = {
  id: number;
  title: string;
  job_type: EnumType<typeof ENUMS.JobType>;
  openings_number: number;
  public_after_verification?: boolean;
  role: number;
  other_role_name?: string;
  ats_imported: boolean;
  external_id?: string | null;
  creator: JobCreator;

  contract_type?: EnumType<typeof ENUMS.JobContractType>;
  start_date?: string | null;
  deadline?: string | null;
  expected_hours_per_week?: number;

  auto_invite_talent: boolean;

  locations?: JobLocation[];
  locations_strongly_required: boolean | null;

  timezones?: JobDraftTimezone[];
  timezone_overlap: EnumType<typeof ENUMS.JobTimezoneOverlapType> | null;

  top_skills: number[];
  new_skills: number[];
  level?: EnumType<typeof ENUMS.SkillLevel> | null;
  experience_level: EnumType<typeof ENUMS.JobExperienceLevel> | null;
  original_job?: number;

  payment_type: EnumType<typeof ENUMS.JobPaymentType>;

  budget_minimum_usd: string;
  budget_maximum_usd: string;
  min_annual_rate?: string;
  max_annual_rate?: string;
  fixed_rate?: string;

  min_rate?: string;
  max_rate?: string;
  is_ai_generated?: boolean;
  ai_min_rate?: string;
  ai_max_rate?: string;

  hourly_rate?: typeof RANGE_VALUE;

  requirements?: string;
  description?: string;
  introduction?: string;

  new_application_questions: {
    id: number;
    is_required: boolean;
    original_question: number | null;
    is_active: boolean;
    order?: number;
    can_be_changed?: boolean;
    question: string;
  }[];

  sale_owners?: number[];
  op_owners?: number[];
  hiring_manager?: number;

  is_private: boolean;
  is_resume_required: boolean;

  job_subscribers: JobSubscriberData[];
  job_subscriber_invitations: JobSubscriberInvitationData[];

  job_fill_support_type?: EnumType<typeof ENUMS.JobFillSupportType>;
  employer: number;

  flow_entry?: JobFlowEntryType;

  is_ai_interview_enabled?: boolean;
  ai_interview_max_duration?: string;

  interview_criteria?: InterviewGradingCriteria[] | string[];
  interview_questions?: InterviewQuestion[];
};

export type JobFormValues = Omit<
  CreateJobData,
  | keyof JobRate
  | "timezones"
  | "locations"
  | "job_draft_id"
  | "dry_run"
  | "public_after_verification"
> &
  Pick<JobDraft, "id" | "ats_imported"> &
  JobFormRateValues & {
    timezones: TimezoneOption[];
    locations: JobLocation[];
    jobOwnerId?: number;
    isDeletingJobDraft?: boolean;
    employer?: number;
    intro_video?: IntroVideoAttachment;
  };

export type JobSubscriberData = { team_member_id: number };

export type JobSubscriber = {
  id: number;
} & JobSubscriberData;

export type JobSubscriberInvitationData = { email: string };

export type JobSubscriberInvitation = {
  id: number;
} & JobSubscriberInvitationData;

export type UpdateJobSubscribersParams = {
  jobId: number;
  jobSubscribers: JobSubscriberData[];
};

export type UpdateJobSubscriberInvitationsParams = {
  jobId: number;
  jobSubscriberInvitations: JobSubscriberInvitationData[];
};

// required fields based on backend response for publishing job

export type ATSJob = {
  title: string;
  description: string;
  remote_id: string;
  external_id: string;
  already_imported: boolean;
};

export type EmployerJobMinimal = Pick<Job, "id" | "title">;

export type GetJobDraftResponse = {
  id: number;
  ats_imported: boolean;
  employer: number;
  creator: JobCreator;
  data: Omit<
    JobDraft,
    | "job_subscribers"
    | "job_subscriber_invitations"
    | "ats_imported"
    | "external_id"
  >;
  external_id?: string;
  public_after_verification: boolean;
  job_subscribers: JobSubscriber[];
  job_subscriber_invitations: JobSubscriberInvitation[];
};

export type TimezonePayload = {
  timezone: string;
  timezone_id?: string;
};

export type PreparedJobDraftData = Pick<
  CreateJobData,
  | "role"
  | "contract_type"
  | "expected_hours_per_week"
  | "top_skills"
  | "new_skills"
  | "experience_level"
  | "payment_type"
  | "budget_maximum_usd"
  | "budget_minimum_usd"
  | "is_ai_generated"
  | "is_ai_interview_enabled"
  | "ai_interview_max_duration"
  | "interview_criteria"
  | "interview_questions"
  | "auto_invite_talent"
  | "is_private"
  | "locations_strongly_required"
  | "is_resume_required"
  | "public_after_verification"
  | "job_type"
  | "timezone_overlap"
  | "level"
  | "deadline"
  | "start_date"
  | "openings_number"
  | "external_id"
  | "original_job"
  | "op_owners"
  | "sale_owners"
  | "introduction"
  | "description"
  | "requirements"
  | "title"
  | "role"
  | "job_subscribers"
  | "job_subscriber_invitations"
  | "new_application_questions"
  | "hiring_manager"
  | "op_owners"
  | "sale_owners"
  | "job_fill_support_type"
  | "other_role_name"
> & {
  // make sure below fields are unset when preparing the job payload data
  id: number; // job draft id
  jobOwnerId?: undefined;
  isDeletingJobDraft?: undefined;
  locations: JobLocation[];
  timezones: TimezonePayload[];
  employer?: number;
  extension?: UpdateJobDataExtension;
};

export type JobCreator = Pick<
  User,
  | "avatar"
  | "avatar_thumbnail"
  | "created"
  | "first_name"
  | "first_name_possessive"
  | "gravatar"
  | "has_avatar_set"
  | "id"
  | "introduction_headline"
  | "last_name"
  | "location"
  | "profile_url"
  | "public_name"
  | "timezone_abbreviation"
  | "timezone_offset"
  | "title"
  | "can_be_messaged"
> & {
  account_type: AccountType | null; // null for removed user
};

export type CreateJobDraftPayload = {
  data: Omit<
    PreparedJobDraftData,
    "job_subscribers" | "job_subscriber_invitations" | "id"
  >;
  job_subscribers: JobSubscriberData[];
  job_subscriber_invitations: JobSubscriberInvitationData[];
};

export type UpdateJobDraftPayload = {
  data: Omit<
    PreparedJobDraftData,
    "job_subscribers" | "job_subscriber_invitations" | "id"
  > & { id: number };
} & Pick<
  CreateJobDraftPayload,
  "job_subscriber_invitations" | "job_subscribers"
>;

export type GetJobDraftsResponse = PaginatedResult<GetJobDraftResponse>;

export type TalentInviteSuggestions = PaginatedResult<TalentInviteSuggestion>;

export type InviteTalentProps = {
  jobId: number;
  freelancersIds: number[];
};

export type RemoveInviteProps = {
  jobId: number;
  freelancerId: number;
};

export type SimilarJobsProps = {
  jobId: number;
  pageSize?: number;
};

export type SimilarJobs = PaginatedResult<JobListingJob>;

export type NewestJobsProps = {
  page_size?: number;
  role?: number;
  jobs_ids?: number[];
};

export type NewApplicationQuestion = {
  id?: number;
  is_required?: boolean;
  original_question?: number | null;
  is_active?: boolean;
  order?: number;
  question: string;
  can_be_changed?: boolean;
};

export type SaveOptionalFeesProps = {
  bidId: number;
  fees: {
    background_fee_amount: string | null;
    compliance_fee_percent: string | null;
    braintrust_pays_talent_off_platform_enabled: boolean | null;
    w2_markup_percent: string | null;
  };
};

export type GetOwnJobsParams = {
  status: JobStatus | "DRAFT_STATUS";
  limit?: number;
};

export type GetJobParams = {
  id: number;
  // referrer enables UNauthenticated user to see private job he entered ...
  // ... through a referral link
  referrer?: string;
};

export type GetExpandedJobDetailsResponse =
  | ExpandedJobDetails
  | ErrorWithDetail;

export type GetExpandedJobDetailsQueryArgs = number;

export type GetSavedJobsParams = {
  page: number;
};

export type GetSmartMatchingStatusResponse = {
  smart_matching_status: EnumType<typeof ENUMS.SmartMatchingStatus>;
  analyzing_profiles: number;
};

export type GetSmartMatchingStatusArgs = {
  id: number;
};

export type MoveATSJobsToDraftActionArgs = {
  batch: {
    external_id: string;
    data: { title?: string };
  }[];
};

export type AiInterviewCriteria = {
  grade?: number;
  title: string;
  description: string;
};

export type InterviewGradingCriteria = {
  id: number;
  job: number;
  name: string;
  normalized_name: string;
};

export type InterviewQuestion = {
  id: number;
  question: string;
  order: number;
};

export type GetJobApplicationInsightsParams = {
  id: number;
};

export type ApplicationInsightMatchSummary = {
  shap: number;
  sentence: string;
  rank: number;
  summary_type: "positive" | "constructive";
};

export type JobApplicationInsightsResponse = {
  /**
   * @note expected string format = ^\d+/\d+$
   */
  skills: string;
  total_applicants: number;
  smart_matching_label: EnumType<typeof ENUMS.SmartMatchingLabel> | null;
  match_summary?: ApplicationInsightMatchSummary[];
};

export type JobRate = {
  payment_type: EnumType<typeof ENUMS.JobPaymentType>;
  budget_minimum_usd: string;
  budget_maximum_usd: string;
  ai_min_rate?: string;
  ai_max_rate?: string;
};

export type JobFormRateValues = {
  payment_type?: EnumType<typeof ENUMS.JobPaymentType>;
  hourly_rate?: string | "range";
  min_rate?: string;
  max_rate?: string;
  fixed_rate?: string;
  min_annual_rate?: string;
  max_annual_rate?: string;
  ai_min_rate?: string;
  ai_max_rate?: string;
  custom_min_rate?: string;
  custom_max_rate?: string;
};

export type GenerateAIJobPayload = {
  short_description: string;
  employer_id: number;
};

export type AIGeneratedJob = {
  job_title: string | null;
  job_type: EnumType<typeof ENUMS.JobType>;
  job_category: string | null;
  contract_length: EnumType<typeof ENUMS.JobContractType> | null;
  expected_hours_per_week: number | null;
  experience_level: EnumType<typeof ENUMS.JobExperienceLevel>;
  job_timezone_overlap: string | null;
  number_of_openings: number | null;
  job_pay_rate: string | null;

  //e.g. ['Python', 'Django', 'React']
  job_top_skills: string[] | null;
  //e.g. ['Python', 'Django', 'React']
  job_skills: string[] | null;
  detailed_description: string | null;
  is_resume_required: boolean | null;
  //e.g. ['Question 1', 'Question 2']
  application_questions: string[] | null;
  employer_country: string | null;
  budget_maximum_usd: string | null;
  budget_minimum_usd: string | null;
  job_payment_type: EnumType<typeof ENUMS.JobPaymentType>;
};

export type CompleteJobParams = {
  jobId: number;
  data: {
    client_feedback_was_job_filled: boolean | null;
  };
};

export type CompleteJobResponse = { success: boolean; hired: boolean };

export type CreateJobData = Pick<
  ManagedJob,
  | "title"
  | "contract_type"
  | "budget_maximum_usd"
  | "budget_minimum_usd"
  | "payment_type"
  | "job_type"
  | "top_skills"
  | "flow_entry"
  | "other_role_name"
> &
  Partial<
    Pick<
      ManagedJob,
      | "disable_freelancer_notifications"
      | "introduction"
      | "description"
      | "requirements"
      | "promote_listing"
      | "job_payment_day_of_month"
      | "timezone_overlap"
      | "locations_strongly_required"
      | "is_private"
      | "original_job"
      | "experience_level"
      | "is_resume_required"
      | "level"
      | "auto_invite_talent"
      | "is_ai_generated"
      | "is_ai_interview_enabled"
      | "ai_interview_max_duration"
      | "interview_criteria"
      | "interview_questions"
      | "openings_number"
      // from node staff
      | "hiring_manager"
      | "op_owners"
      | "sale_owners"
      | "job_fill_support_type"
    >
  > & {
    role: number;
    start_date?: string;
    deadline?: string;
    expected_hours_per_week: number;
    timezones?: TimezonePayload[];
    locations?: JobLocation[];
    new_application_questions?: NewApplicationQuestion[];

    job_subscribers?: JobSubscriberData[];
    job_subscriber_invitations?: JobSubscriberInvitationData[];
    new_skills: number[];
    job_draft_id: number;
    external_id?: string;

    dry_run?: boolean;
    public_after_verification?: boolean;
  };

export type CreateJobResponse = { id: number };

export type UpdateJobData = Pick<
  CreateJobData,
  | "title"
  | "contract_type"
  | "deadline"
  | "start_date"
  | "expected_hours_per_week"
  | "openings_number"
  | "role"
  | "introduction"
  | "description"
  | "requirements"
  | "budget_maximum_usd"
  | "budget_minimum_usd"
  | "level"
  | "payment_type"
  | "new_application_questions"
  | "auto_invite_talent"
  | "locations"
  | "locations_strongly_required"
  | "timezones"
  | "timezone_overlap"
  | "new_skills"
  | "top_skills"
  | "is_private"
  | "experience_level"
  | "is_resume_required"
  | "job_subscribers"
  | "job_subscriber_invitations"
  | "is_ai_generated"
  | "job_fill_support_type"
  | "other_role_name"
> & {
  extension?: UpdateJobDataExtension;
};

export type UpdateJobDataExtension = {
  intro_video?: number | null;
};

export type UpdateJobParams = {
  jobId: number;
  data: UpdateJobData;
};

export type PatchJobParams = { jobId: number; data: Partial<UpdateJobData> };

export type GetTotalJobBidsResponse = { bids_count: number };
export type GetTotalJobBidsParams = { jobId: number };

export type UpdateJobFeedbackData = {
  job_not_filled_reason?: EnumType<typeof ENUMS.JobNotFilledReasonType>;
  job_filled_reason?: EnumType<typeof ENUMS.JobFilledReasonType>;
  job_filled_using_platform?: EnumType<
    typeof ENUMS.JobFilledUsingPlatformType
  > | null;
  reason_other?: string | null;
  quality_of_applications_received_rate: string;
};

export type UpdateJobFeedbackParams = {
  jobId: number;
  data: UpdateJobFeedbackData;
};

export type GetJobsParams = Partial<JobFilters> & { page?: number };
