import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { Button, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { Modal } from "@js/components/modal";

import type { CreateCCPaymentMethodStripeResult } from "../../types";
import { NullStripeFeedback } from "..";

import style from "../add-stripe-ach/style.module.scss";

type AddStripeCreditCardModalProps = {
  onSuccess: (paymentMethod: CreateCCPaymentMethodStripeResult) => void;
};

const ADD_STRIPE_CREDIT_CARD_MODAL_ID = "ADD_STRIPE_CREDIT_CARD_MODAL";

export const AddStripeCreditCardModalInstance = Modal(
  ADD_STRIPE_CREDIT_CARD_MODAL_ID,
  { keepOnBackdropClick: true },
);

export const AddStripeCreditCardModal = ({
  onSuccess,
}: AddStripeCreditCardModalProps) => {
  const [cardElementLoaded, setCardElementLoaded] = useState(false);
  const [cardIsSaved, setCardIsSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null,
  );
  const stripe = useStripe();
  const elements = useElements();

  const onClose = () => {
    setCardElementLoaded(false);
    setErrorMessage(null);
  };

  const onSubmit = () => {
    setCardIsSaved(true);
    const cardElement = elements?.getElement(CardElement);

    if (!cardElement) {
      setErrorMessage("Failed to load card element");
      setCardIsSaved(false);
      return;
    }

    stripe
      ?.createPaymentMethod({ card: cardElement, type: "card" })
      .then(({ error, paymentMethod }) => {
        if (error) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage(null);

          AddStripeCreditCardModalInstance.close();

          if (paymentMethod) {
            onSuccess(paymentMethod);
          }
        }

        setCardIsSaved(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setCardIsSaved(false);
      });
  };

  return (
    <AddStripeCreditCardModalInstance onClose={onClose}>
      <NullStripeFeedback stripe={stripe}>
        <div className={style.modalContent}>
          <Typography component="h1" variant="title" fontWeight={400}>
            Add Credit Card
          </Typography>
          <div className={style.formElement}>
            {!cardElementLoaded && <Loader />}
            <div style={{ display: cardElementLoaded ? "block" : "none" }}>
              <CardElement
                options={{
                  style: { base: { fontSize: "18px" } },
                }}
                onReady={() => setCardElementLoaded(true)}
              />
            </div>
          </div>
          {errorMessage && (
            <div className="general-form-error">{errorMessage}</div>
          )}
          <div className="buttons right">
            <Button
              variant="primary"
              onClick={AddStripeCreditCardModalInstance.close}
            >
              Cancel
            </Button>
            <Button variant="primary" disabled={cardIsSaved} onClick={onSubmit}>
              Save
            </Button>
          </div>
        </div>
      </NullStripeFeedback>
    </AddStripeCreditCardModalInstance>
  );
};
