import { useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { Button, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks/use-user";
import { useGetFreelancerPublicProfileQuery } from "@js/apps/freelancer/api";
import type { SaveReviewProps } from "@js/apps/reviews/api";
import {
  useCreateJobWorkReviewMutation,
  useGetOpenReviewProcessesQuery,
} from "@js/apps/reviews/api";
import { WriteReviewForm } from "@js/apps/reviews/components/write-review/form";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";

import style from "./style.module.scss";

export const openReviewsPostJobCompletedModal = (jobId: number) => {
  ModalInstance.open({
    className: style.modal,
    closeButton: false,
    children: <ReviewsPostJobCompleted jobId={jobId} />,
  });
};

type ReviewsPostJobCompletedProps = {
  jobId: number;
};

const ReviewsPostJobCompleted = ({ jobId }: ReviewsPostJobCompletedProps) => {
  const [currentReviewProcessIndex, setCurrentReviewProcessIndex] = useState(0);
  const user = useUser();
  const {
    data: openReviewProcessesList,
    isLoading: isLoadingOpenReviewProcessesList,
  } = useGetOpenReviewProcessesQuery({
    content_type: ENUMS.ReviewType.WORK_REVIEW,
    jobId,
  });

  const currentFreelancerId =
    openReviewProcessesList?.[currentReviewProcessIndex]?.subject;

  const { data: profile } = useGetFreelancerPublicProfileQuery(
    currentFreelancerId ?? skipToken,
  );

  const [saveWorkReview] = useCreateJobWorkReviewMutation();

  const skipReview = () => {
    if (
      openReviewProcessesList &&
      openReviewProcessesList.length > currentReviewProcessIndex + 1
    ) {
      setCurrentReviewProcessIndex(currentReviewProcessIndex + 1);
    } else {
      ModalInstance.close();
    }
  };

  const onSubmit = async (values: SaveReviewProps) => {
    return saveWorkReview(values)
      .unwrap()
      .then(() => {
        Snackbar.success("Review saved successfully.");

        if (
          openReviewProcessesList?.length &&
          currentReviewProcessIndex === openReviewProcessesList?.length - 1
        ) {
          ModalInstance.close();
        }
      });
  };

  if (isLoadingOpenReviewProcessesList || !user) {
    return <Loader centered />;
  }

  if (!openReviewProcessesList?.length) {
    return <p>No profiles to review.</p>;
  }

  return (
    <div className={style.content}>
      <Typography
        component="h1"
        variant="title"
        fontWeight={400}
        size="small"
        className="mb+"
      >
        Give Reviews
      </Typography>
      {profile &&
        profile.id ===
          openReviewProcessesList[currentReviewProcessIndex].subject && (
          <div className={style.contentNav}>
            {openReviewProcessesList.length > 1 && (
              <>
                {currentReviewProcessIndex + 1} out of{" "}
                {openReviewProcessesList.length} -&nbsp;
              </>
            )}
            Reviewing <strong>{profile.user.public_name}</strong> for overall
            work
            <Button
              variant="transparent"
              color="secondary"
              className="ml"
              onClick={skipReview}
            >
              Skip &raquo;
            </Button>
          </div>
        )}
      <WriteReviewForm
        hideSubjectInput
        onClose={ModalInstance.close}
        openReviewProcessesList={[
          openReviewProcessesList[currentReviewProcessIndex],
        ]}
        initialValues={{
          review_process: openReviewProcessesList[currentReviewProcessIndex].id,
          subject: openReviewProcessesList[currentReviewProcessIndex].subject,
          reviewer: user.freelancer ? user.freelancer : user.employer,
        }}
        reviewSubject={ENUMS.AccountType.FREELANCER}
        onSubmit={onSubmit}
      />
    </div>
  );
};
