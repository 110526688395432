import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  CloseIcon,
  EditPenIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { Tag } from "@js/apps/jobs/components/job-type-and-role-tags";
import type { FreelancerRole } from "@js/types/freelancer";
import { pluralize } from "@js/utils";

import type { RolesFormData } from "../../forms/role-form";

import styles from "./styles.module.scss";

type RoleListItemProps = {
  role: FreelancerRole;
  onEdit: (data: RolesFormData) => void;
  onDelete: (data: FreelancerRole) => void;
  isLastRole: boolean;
};

export const RoleListItem = ({
  role,
  onEdit,
  onDelete,
  isLastRole,
}: RoleListItemProps) => {
  const handleEdit = () => {
    onEdit({
      id: role.id,
      primary: role.primary,
      selected_role: role.role.id,
      years_experience: role.years_experience,
    });
  };

  const isMobile = useMediaQuery("sm");
  const roleNameFontSize = isMobile ? "medium" : "large";
  const experienceFontSize = isMobile ? "small" : "medium";

  const handleDelete = () => onDelete(role);

  return (
    <div className={styles.wrapper}>
      <Box display="flex" flexDirection="column" gap="4px" mb={2} mt={3}>
        <Box display="flex" gap="8px" flexWrap="wrap">
          <Typography
            component="span"
            variant="paragraph"
            size={roleNameFontSize}
          >
            {role.role.name}
          </Typography>
          {role.primary && (
            <Tag bgcolor="var(--soft-grey)" borderRadius="500px">
              Primary role
            </Tag>
          )}
        </Box>
        {role.years_experience && (
          <Typography
            component="span"
            variant="label"
            size={experienceFontSize}
          >
            {role.years_experience} year{pluralize(role.years_experience)} of
            experience
          </Typography>
        )}
      </Box>
      <div className={styles.actions}>
        <IconButton
          variant="tertiary"
          size="x-small"
          aria-label="Edit role"
          onClick={handleEdit}
        >
          <EditPenIcon />
        </IconButton>
        <IconButton
          variant="tertiary"
          size="x-small"
          aria-label="Delete role"
          onClick={isLastRole ? handleEdit : handleDelete}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};
