import { useMemo } from "react";
import type { ControllerRenderProps, FieldError } from "react-hook-form";

import {
  Box,
  ComboBox,
  TextField,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { AvailabilityFormValues } from "@js/apps/available-for-work/hooks/use-availability-form";

import type { TimezoneOption } from "./helpers";
import { timezoneFilters, timezoneOptions } from "./helpers";

type TimezoneFieldProps = {
  field: ControllerRenderProps<AvailabilityFormValues, "working_hours_tz_abbr">;
  disabled: boolean;
  error?: FieldError;
};

export const TimezoneField = ({
  disabled,
  field,
  error,
}: TimezoneFieldProps) => {
  const selectedTimezone = useMemo(
    () => timezoneOptions.find(({ value }) => value === field.value) || null,
    [field.value],
  );

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        id="timezone-label-id"
        component="label"
        size="medium"
        fontWeight={500}
        mb={1}
        color={disabled ? "grey-3" : undefined}
      >
        Timezone
      </Typography>
      <ComboBox<TimezoneOption, false>
        initialTaxonomiesLoading={false}
        filterOptions={timezoneFilters}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select a timezone"
            inputProps={{
              ...params.inputProps,
              ["aria-labelledby"]: "timezone-label-id",
              ["aria-describedby"]: "timezone-description-id",
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
        {...field}
        ref={undefined}
        options={timezoneOptions}
        value={selectedTimezone}
        onChange={(_event, _selectedTimezone: TimezoneOption | null) => {
          field.onChange(_selectedTimezone?.value ?? null);
        }}
      />
      <Typography
        id="timezone-description-id"
        component="p"
        size="small"
        mt={0.5}
        color={error ? "negative" : disabled ? "grey-3" : "primary"}
      >
        By default, we assume you work in your location’s timezone.
      </Typography>
    </Box>
  );
};
