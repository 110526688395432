import React, { useCallback } from "react";

import {
  Button,
  IconButton,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import {
  addRecipientOnboardingLink,
  removeRecipientOnboardingLink,
} from "@js/apps/withdrawal/actions";
import {
  useCreateDashboardLinkMutation,
  useCreateOnboardingLinkMutation,
} from "@js/apps/withdrawal/api/api";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { Recipient, WithdrawalMethodType } from "@js/types/withdrawals";
import { typeGuard } from "@js/utils";

import styles from "./styles.module.scss";

const ViewDashboardButton = ({
  id,
  disabled,
}: {
  id: number;
  disabled: boolean;
}): JSX.Element => {
  const [createDashboardLink, { isSuccess, isLoading }] =
    useCreateDashboardLinkMutation();
  const redirectToStripe = useCallback(
    async (linkId: number) => {
      const data = await createDashboardLink(linkId);
      if ("error" in data) {
        Snackbar.error("Sorry, something when wrong. Please try again.");
      } else {
        window.open(data.data.url, "_blank");
      }
    },
    [createDashboardLink],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Button
      variant="tertiary"
      disabled={disabled || isSuccess}
      onClick={() => redirectToStripe(id)}
      size="x-small"
    >
      View
    </Button>
  );
};

const ViewOnboardingButton = ({
  id,
  disabled,
}: {
  id: number;
  disabled?: boolean;
}) => {
  const [createOnboardingLink, { isLoading }] =
    useCreateOnboardingLinkMutation();
  const recipientsOnboardingLinks = useAppSelector(
    (state) => state.withdrawal.recipientsOnboardingLinks,
  );
  const dispatch = useAppDispatch();

  const handleClick = useCallback(async () => {
    let onboardingUrl = recipientsOnboardingLinks[id];
    if (!onboardingUrl) {
      const data = await createOnboardingLink(id)
        .unwrap()
        .catch((error: unknown) => {
          if (
            typeGuard<
              unknown,
              {
                data: {
                  detail: number;
                };
              }
            >(error, "data")
          ) {
            Snackbar.error("Sorry, Stripe onboarding link not found.");
          } else {
            Snackbar.error("Sorry, something when wrong. Please try again.");
          }
        });

      if (data?.url) {
        dispatch(addRecipientOnboardingLink({ id, link: data.url }));
        onboardingUrl = data?.url;
      }
    }

    window.location.href = onboardingUrl;
  }, [id, createOnboardingLink, recipientsOnboardingLinks, dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Button
      disabled={disabled}
      variant="tertiary"
      size="x-small"
      onClick={handleClick}
    >
      Go to Stripe onboarding
    </Button>
  );
};

type RecipientsListingProps = {
  currentWithdrawalMethod?: WithdrawalMethodType;
  deleteRecipient: (recipient: number) => void;
  closeModal: () => void;
};

export const RecipientsListing = ({
  currentWithdrawalMethod,
  deleteRecipient,
  closeModal,
}: RecipientsListingProps) => {
  const dispatch = useAppDispatch();
  const handleDeleteRecipient = (recipientId: number) => {
    deleteRecipient(recipientId);
    dispatch(removeRecipientOnboardingLink(recipientId));

    closeModal();
  };

  const recipients =
    currentWithdrawalMethod && "recipients" in currentWithdrawalMethod.method
      ? currentWithdrawalMethod.method.recipients
      : [];

  return !!recipients.length ? (
    <div className={styles.recipientsTableWrapper}>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Company Node</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Account</TableCell>
            <TableCell align="left">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recipients.map((recipient) => {
            return (
              <TableRow key={recipient.id}>
                <TableCell>{recipient.company_node_name}</TableCell>
                <TableCell>
                  <RecipientStatus recipient={recipient} />
                </TableCell>
                <TableCell>
                  <ViewDashboardButton
                    id={recipient.id}
                    disabled={!recipient.account_onboarded}
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteRecipient(recipient.id)}
                    variant="tertiary"
                    size="x-small"
                  >
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  ) : (
    <Typography component="p">No recipients added yet.</Typography>
  );
};

const RecipientStatus = ({
  recipient,
}: {
  recipient: Recipient;
}): JSX.Element | null => {
  if (!recipient.account_onboarded) {
    return <ViewOnboardingButton id={recipient.id} />;
  }
  if (recipient.payouts_enabled) {
    return <>Active</>;
  }
  if (!recipient.payouts_enabled) {
    return <>Payouts paused - check account</>;
  }
  return null;
};
