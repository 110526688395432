import React from "react";
import { useLocation } from "react-router-dom";
import cs from "classnames";

import { Box, Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  LocationPinSmallIcon,
  SkillIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { openLocationMismatchModal } from "@js/apps/admin/components/location-mismatch-modal";
import { AvailableForWorkStatus } from "@js/apps/available-for-work/components";
import { useOptionTooltip } from "@js/apps/available-for-work/hooks/use-option-tooltip";
import { RatingCard } from "@js/apps/common/components";
import { useAccountType, useIsNodeStaff } from "@js/apps/common/hooks";
import { LocationWarning } from "@js/apps/freelancer/components/location-warning";
import { RolesTag } from "@js/apps/jobs/components/job-type-and-role-tags";
import { FreelancerSkillChip } from "@js/apps/skills/components";
import { useSearchEventQueryId } from "@js/apps/tracking/search-event-slice";
import { UNIVERSAL_SEARCH_PATHS } from "@js/apps/universal-search/constants";
import { RouterLink } from "@js/components/link";
import { UserAvatar } from "@js/components/user-avatar";
import { useAppDispatch } from "@js/hooks";
import type { FreelancerSuperpower, Talent } from "@js/types/freelancer";
import { formatCurrency } from "@js/utils";

import {
  clickInviteAfterSearch,
  clickTalentAvatarAfterSearch,
  clickTalentNameAfterSearch,
} from "../../actions";
import type { TALENT_LOCATION } from "../../constants";

import { InviteToBidButton } from "./invite-to-bid-button";
import { TalentCardItemActionMenu } from "./talent-card-item-action-menu";

import styles from "./style.module.scss";

type CardItemProps = {
  talent: Talent;
  jobId?: number;
  resultPosition?: number;
  searchResults?: boolean;
  location: EnumType<typeof TALENT_LOCATION>;
};

export const CardItemTalent = ({
  talent,
  jobId,
  resultPosition,
  searchResults,
  location,
}: CardItemProps) => {
  const isMobile = useMediaQuery(400);
  const { isEmployer } = useAccountType();
  const isNodeStaff = useIsNodeStaff();

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const searchQueryId = useSearchEventQueryId(
    ENUMS.SearchEventOption.TALENT_SEARCH_BOX,
  );

  const displayRate = (isEmployer || isNodeStaff) && talent.rate;
  const displaySkills = !!talent.superpowers.length;
  const isAvailableForWork = talent.availability_for_work;

  const rate = React.useMemo(
    () => `${formatCurrency(talent.rate ?? 0)}/hr`,
    [talent.rate],
  );

  const urlToTalentPage = `/talent/${talent.id}/`;

  const tooltipTitle = useOptionTooltip(talent.availability_for_work_options);
  const isLocationMismatched = !!talent.location_mismatch || !talent.location;

  const isOnTheTalentSearchPage = pathname.startsWith(
    UNIVERSAL_SEARCH_PATHS.TALENT,
  );

  return (
    <Box
      className={cs(styles.talentListingCard, {
        [styles.talentListingCardBorder]: isOnTheTalentSearchPage,
      })}
    >
      <Box
        className={cs(styles.talentListingCardWrapper, {
          [styles.talentListingCardWrapperWithRate]:
            displayRate && displaySkills,
        })}
        mb={1}
      >
        <Box className={styles.talentListingCardActions}>
          <RouterLink
            to={urlToTalentPage}
            className={styles.talentListingCardLink}
            tabIndex={-1}
          >
            <Box display="flex" justifyContent="center" position="relative">
              <UserAvatar
                className={styles.talentListingCardAvatar}
                hideBadge
                disableShadow
                hideBorder
                user={talent.user}
                size="xl"
                onClick={() => {
                  if (searchResults && resultPosition !== undefined) {
                    dispatch(
                      clickTalentAvatarAfterSearch({
                        talentId: talent.user.id,
                        resultPosition,
                        location,
                        searchQueryId,
                      }),
                    );
                  }
                }}
              />
              <Tooltip
                title={tooltipTitle}
                color="var(--black)"
                disableInteractive
              >
                <Box className={styles.availabilityStatusContainer}>
                  {isAvailableForWork ? <AvailableForWorkStatus /> : null}
                </Box>
              </Tooltip>
            </Box>
          </RouterLink>
          <CTAButton
            talent={talent}
            jobId={jobId}
            onClick={() => {
              if (searchResults && resultPosition !== undefined)
                dispatch(
                  clickInviteAfterSearch({
                    talentId: talent.user.id,
                    resultPosition,
                    location,
                    searchQueryId,
                  }),
                );
            }}
          />
        </Box>

        <Box className={styles.talentListingCardContent}>
          <RolesTag
            role={talent.role}
            className={styles.talentListingCardRolesTag}
          />

          <Tooltip
            title={`${talent.user.first_name} ${talent.user.last_name}`}
            placement="top"
            disabled={!isMobile}
          >
            <Box
              className={styles.talentListingCardName}
              onClick={() => {
                if (searchResults && resultPosition !== undefined)
                  dispatch(
                    clickTalentNameAfterSearch({
                      talentId: talent.user.id,
                      resultPosition,
                      location,
                      searchQueryId,
                    }),
                  );
              }}
            >
              <Typography
                component="p"
                size="large"
                variant="label"
                ellipsis
                to={urlToTalentPage}
                RouterLink={RouterLink}
                tabIndex={0}
              >
                {talent.user.first_name} {talent.user.last_name}
              </Typography>
              {!isEmployer && talent.approved && (
                <Box
                  bgcolor="var(--yellow)"
                  width={25}
                  height={25}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                  data-testid="approved-icon"
                >
                  <SkillIcon />
                </Box>
              )}
            </Box>
          </Tooltip>

          <Box className={styles.talentListingCardInfo}>
            {displayRate && (
              <Typography
                component="p"
                ellipsis={String(talent.rate).length > 3}
                pointer={String(talent.rate).length > 3}
                title={rate}
              >
                {rate}
              </Typography>
            )}

            <Location
              timezone={talent.timezone}
              location={talent.location}
              country={talent.country}
              isLocationMismatched={isLocationMismatched}
              userId={talent.id}
              singleLine={!isMobile && talent.superpowers.length > 2}
            />
          </Box>

          {displaySkills && <TalentSkills skills={talent.superpowers} />}
        </Box>
      </Box>
      {isNodeStaff && (
        <Box gridColumn="span 2" justifySelf="start" marginTop={2}>
          <RatingCard freelancer={talent} className={styles.talentRating} />
        </Box>
      )}

      <TalentCardItemActionMenu talent={talent} jobId={jobId} />
    </Box>
  );
};

type LocationProps = {
  timezone: string;
  location: string | null;
  country: string | null;
  isLocationMismatched: boolean;
  userId: number;
  singleLine?: boolean;
};

const Location = ({
  timezone,
  location,
  country,
  isLocationMismatched,
  userId,
  singleLine,
}: LocationProps) => {
  const isNodeStaff = useIsNodeStaff();
  const displayLocationMismatch = isLocationMismatched && isNodeStaff;
  const handleLocationWarningClick = () => {
    openLocationMismatchModal({ userId });
  };

  if (location && country) {
    const locationElement = (
      <Typography
        component="p"
        multilineEllipsis={singleLine ? undefined : 2}
        ellipsis={singleLine}
      >
        {location}
      </Typography>
    );

    return (
      <Tooltip title={`${timezone}, ${country}`} placement="top">
        <Box className={styles.talentListingCardTimezone}>
          {displayLocationMismatch ? (
            <LocationWarning
              onClick={handleLocationWarningClick}
              gap={0.5}
              overflow={"hidden"}
            >
              {locationElement}
            </LocationWarning>
          ) : (
            <>
              <LocationPinSmallIcon />
              {locationElement}
            </>
          )}
        </Box>
      </Tooltip>
    );
  }

  if (displayLocationMismatch) {
    return <LocationWarning onClick={handleLocationWarningClick} gap={0.5} />;
  }

  return null;
};

const CTAButton = ({
  talent,
  jobId,
  onClick,
}: Omit<CardItemProps, "location"> & { onClick: () => void }) => {
  const { isEmployer } = useAccountType();
  const isNodeStaff = useIsNodeStaff();

  if (!isEmployer && !isNodeStaff) return null;

  return (
    <Box className={styles.talentListingCardCta}>
      <InviteToBidButton freelancer={talent} jobId={jobId} onClick={onClick} />
    </Box>
  );
};

type TalentSkillsProps = {
  skills: FreelancerSuperpower[];
};
const TalentSkills = ({ skills }: TalentSkillsProps) => {
  const isDoubleRow = !useMediaQuery("lg");

  return (
    <Box className={styles.talentListingCardSkills}>
      <Box className={styles.talentListingCardSkillsRow}>
        {skills.slice(0, isDoubleRow ? 2 : 4).map((skill) => (
          <FreelancerSkillChip skill={skill} key={skill.id} />
        ))}
      </Box>
      {isDoubleRow && (
        <Box className={styles.talentListingCardSkillsRow}>
          {skills.slice(2, 4).map((skill) => (
            <FreelancerSkillChip skill={skill} key={skill.id} />
          ))}
        </Box>
      )}
    </Box>
  );
};
