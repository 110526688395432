import React, { useEffect } from "react";
import { Field, isSubmitting } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useWithRecaptcha } from "@js/apps/common/hooks";
import {
  clickClientInviteJoin,
  clickClientSignupJoin,
  viewClientInviteJoinPage,
  viewClientSignUpJoinPage,
} from "@js/apps/onboarding/actions";
import { onboardingFormValueSelector } from "@js/apps/onboarding/components";
import type { OnboardingOrganizationFields } from "@js/apps/onboarding/components/organization/schema";
import { SignUpConfirmButton } from "@js/apps/onboarding/components/sign-up-confirm-button";
import { ONBOARDING_FORM_ID } from "@js/apps/onboarding/constants";
import { FirstOrLastNameField } from "@js/apps/onboarding/fields/first-name-field";
import { OrganizationSizeField } from "@js/apps/onboarding/fields/organization-size-field";
import { SignUpEmailField } from "@js/apps/onboarding/fields/sign-up-email-field";
import { SignupPasswordField } from "@js/apps/onboarding/fields/signup-password-field";
import { TOSField } from "@js/apps/onboarding/fields/tos-field";
import {
  ONBOARDING_SUBMIT_TYPE,
  useOnboardingUpdateSubmitType,
} from "@js/apps/onboarding/hooks";
import { useNewClientSignUpInvitation } from "@js/apps/onboarding/hooks/new-client-signup-invitation";
import { useNewClientSignUpSelfServe } from "@js/apps/onboarding/hooks/new-client-signup-self-serve";
import { OnboardingProfNetLayout } from "@js/apps/onboarding/views/layout";
import { PhoneField } from "@js/forms/fields/phone";
import { useAppDispatch, useAppSelector } from "@js/hooks/redux";

import { SMSOptInField } from "../../fields/sms-opt-in";

import { LoadingOverlay } from "./loading-overlay";

import styles from "./style.module.scss";

export const OnboardingCreateAccountPage = () => {
  const dispatch = useAppDispatch();

  const updateSubmitType = useOnboardingUpdateSubmitType();

  const { isClientInvitation, newClientInvitationData } =
    useNewClientSignUpInvitation();

  const isClientSelfServe = useNewClientSignUpSelfServe();
  const isClientSignup = isClientInvitation || isClientSelfServe;

  useWithRecaptcha();

  const _isSubmitting = useAppSelector((state) =>
    isSubmitting(ONBOARDING_FORM_ID)(state),
  );

  const organizationSize: OnboardingOrganizationFields["organization_size"] =
    useAppSelector((state) => {
      return onboardingFormValueSelector(state, "organization_size");
    });

  const smsOptIn: boolean | undefined = useAppSelector((state) =>
    onboardingFormValueSelector(state, "sms_opt_in"),
  );

  const isPhoneNumberRequired =
    (organizationSize !== ENUMS.OrganizationSize.LESS_THAN_10 &&
      organizationSize !== ENUMS.OrganizationSize.SELF_EMPLOYED) ||
    smsOptIn;

  useEffect(() => {
    if (isClientSelfServe) {
      dispatch(viewClientSignUpJoinPage());
    }

    if (isClientInvitation) {
      dispatch(viewClientInviteJoinPage());
    }
  }, [dispatch, isClientSelfServe, isClientInvitation]);

  return (
    <>
      <LoadingOverlay
        fullScreen
        loaderProps={{ size: 64 }}
        show={_isSubmitting}
      />
      <OnboardingProfNetLayout
        activeStep={2}
        className={isClientSignup ? styles.createAccountPage : undefined}
      >
        <GreetingHeadline />
        <Typography
          mt={1}
          component="h2"
          variant="title"
          fontWeight={400}
          size="small"
        >
          {isClientSignup
            ? "Create an employer account to start hiring Talent"
            : "Just one more step to set up your account."}
        </Typography>
        <Box mt={4} display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            gap={2}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <FirstOrLastNameField variant="first_name" />

            <FirstOrLastNameField variant="last_name" />
          </Box>
          <Box
            display="flex"
            gap={2}
            flexDirection={{ xs: "column", sm: "row" }}
            flexWrap="wrap"
          >
            {isClientSignup && (
              <Field
                name="organization_size"
                component={OrganizationSizeField}
              />
            )}

            <SignUpEmailField
              variant={isClientSignup ? "business" : "talent"}
              required
              disabled={Boolean(
                isClientInvitation && newClientInvitationData?.email,
              )}
            />
            {isClientSignup && (
              <Field
                variant="outlined"
                id="phone_number"
                name="phone_number"
                label={`Mobile number${isPhoneNumberRequired ? "*" : ""}`}
                component={PhoneField}
              />
            )}
          </Box>

          <SignupPasswordField />
          <TOSField />
          {isClientSignup && <SMSOptInField />}
          <SignUpConfirmButton
            onClick={() => {
              if (isClientInvitation) dispatch(clickClientInviteJoin());
              if (isClientSelfServe) dispatch(clickClientSignupJoin());
              updateSubmitType(ONBOARDING_SUBMIT_TYPE.create_account);
            }}
          />
        </Box>
      </OnboardingProfNetLayout>
    </>
  );
};

const GreetingHeadline = (): JSX.Element => {
  const { isClientInvitation, newClientInvitationData } =
    useNewClientSignUpInvitation();

  const isClientSelfServe = useNewClientSignUpSelfServe();

  if (isClientInvitation) {
    return (
      <Typography component="h1" variant="paragraph" size="medium">
        {`Hi, ${newClientInvitationData?.first_name}. Nice to meet you.`}
      </Typography>
    );
  }

  if (isClientSelfServe) {
    return (
      <Typography component="h1" variant="paragraph" size="medium">
        Welcome to Braintrust!
      </Typography>
    );
  }

  return (
    <Typography component="h1" variant="paragraph" size="medium">
      "You’re almost there!"
    </Typography>
  );
};
