import type { ChangeEventHandler, ReactElement } from "react";
import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import {
  BasicInput,
  Box,
  Button,
  FormHelperText,
} from "@hexocean/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import { reduxForm } from "@js/forms/fields";
import { shouldDisplayError } from "@js/forms/utils";

import style from "./style.module.scss";
export type SendMessageFormValues = { message: string };

type ExtendedSendMessageFormProps = {
  ButtonProps?: ButtonProps;
  minRows?: number;
  additionalButtons?: ReactElement[];
  bulkRejection?: boolean;
  onSubmit: (values: SendMessageFormValues) => void;
};

export const BidSendMessage = reduxForm<
  SendMessageFormValues,
  ExtendedSendMessageFormProps
>()(({
  submitting,
  submit,
  ButtonProps,
  minRows,
  additionalButtons,
  bulkRejection,
}) => {
  return (
    <Box
      component={Form}
      onSubmit={submitting ? null : submit}
      display="flex"
      flexDirection="column"
    >
      {!bulkRejection && (
        <Field
          name="message"
          minRows={minRows}
          maxLength={600}
          component={InputBaseField}
          disabled={submitting}
        />
      )}
      <Box mt={bulkRejection ? 0 : 2} display="flex" columnGap={1}>
        <Button
          shape="squared"
          variant="medium-violet"
          className={style.messageModalBtn}
          disabled={submitting}
          {...ButtonProps}
        />
        {additionalButtons?.map((button) => {
          return React.cloneElement(button, { key: button.key });
        })}
      </Box>
    </Box>
  );
});

type InputBaseFieldProps = TypedWrappedFieldProps<string> & {
  minRows?: number;
  disabled?: boolean;
};

const InputBaseField = ({
  input,
  meta,
  minRows = 6,
  disabled,
}: InputBaseFieldProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const displayError = shouldDisplayError({ input, meta });

  React.useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.focus();
    // set cursor at the end
    inputRef.current.setSelectionRange(input.value.length, input.value.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    input.onChange(event.target.value);
  };

  return (
    <>
      <Box
        borderRadius="20px"
        p={2}
        border={`1px solid var(--${displayError ? "negative-1" : "grey-3"})`}
        bgcolor="var(--white)"
      >
        <BasicInput
          fullWidth
          multiline
          inputRef={inputRef}
          minRows={minRows}
          maxRows={20}
          {...input}
          onChange={handleChange}
          disabled={disabled}
        />
      </Box>
      {displayError && <FormHelperText error>{meta.error}</FormHelperText>}
    </>
  );
};
