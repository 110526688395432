import { useCallback } from "react";

import { useAccountType } from "@js/apps/common/hooks";
import { WELCOME_MODAL_PARAM } from "@js/apps/employer/hooks/use-employer-welcome-modal";
import { globalConfig } from "@js/constants";
import { useNavigate } from "@js/hooks";
import type { UnverifiedUser } from "@js/types/auth";
import { iframePostMessage, isInIframe } from "@js/utils";

const getRedirectPathForUnfinishedUser = (
  query_params: UnverifiedUser["query_params"],
  isEmployer: boolean,
) => {
  if (query_params?.post_id) {
    return `/feed/${query_params.post_id}/`;
  } else if (query_params?.space_id) {
    return `/spaces/${query_params.space_id}/`;
  } else if (isEmployer) {
    return `/?${WELCOME_MODAL_PARAM}=true`;
  }
  return "/";
};

export const useRedirectAfterSignUp = (unfinishedUser?: UnverifiedUser) => {
  const { isEmployer } = useAccountType();
  const navigate = useNavigate();

  const redirect = useCallback(() => {
    if (!unfinishedUser) return;

    const redirectPath = getRedirectPathForUnfinishedUser(
      unfinishedUser?.query_params,
      isEmployer,
    );

    if (isInIframe()) {
      globalConfig.surpressDisplayGenericRequestError = true;
      iframePostMessage({ redirect: window.location.origin });
    } else {
      navigate(redirectPath);
    }
  }, [navigate, unfinishedUser, isEmployer]);

  return redirect;
};
