import { useCallback, useEffect, useRef, useState } from "react";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

import { dateDifferenceFromNow } from "@js/utils/date";

// Really simple hook which manages seconds only. Please consider some hook from npm if you need more features
export const useCountdown = (
  endTime: Dayjs | string = dayjs().add(3, "seconds"),
) => {
  const [start, setStartCountdown] = useState(false);
  const [countdown, setCountDown] = useState(
    dateDifferenceFromNow(endTime, "seconds"),
  );
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  const startCountdown = useCallback(() => setStartCountdown(true), []);

  const count = useCallback(() => {
    const differenceInSeconds = dateDifferenceFromNow(endTime, "seconds");
    setCountDown(differenceInSeconds);
  }, [endTime]);

  useEffect(() => {
    if (start) {
      interval.current = setInterval(count, 1000);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [count, start]);

  useEffect(() => {
    if (countdown <= 0 && interval.current) {
      clearInterval(interval.current);
    }
  }, [countdown]);

  return {
    startCountdown,
    countdown,
    countdownFinished: countdown === 0,
  };
};
