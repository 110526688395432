import React from "react";
import _ from "underscore";

import { Box } from "@hexocean/braintrust-ui-components";
import { useGetNotificationsSettingsQuery } from "@js/apps/settings/api";
import {
  JobsNotificationsSection,
  NotificationsSection,
} from "@js/apps/settings/components/notifications-section";

const JOBS_GROUP = ENUMS.NotificationGroup.JOBS;

export const ClientNotificationSettings = () => {
  const { data, isLoading } = useGetNotificationsSettingsQuery();

  if (!data || isLoading) {
    return null;
  }

  const filteredNotifications = data.general_notifications_settings.filter(
    (item) => item.account_type !== ENUMS.AccountType.FREELANCER,
  );

  const notificationsByGroup = _.groupBy(
    filteredNotifications,
    (item) => item.group,
  );

  const jobsNotificationGroup = notificationsByGroup[JOBS_GROUP];
  const notificationsWithoutJobsGroup = _.omit(
    notificationsByGroup,
    JOBS_GROUP,
  );

  const sortOrder = {
    [ENUMS.NotificationGroup.INVOICES]: 1,
    [ENUMS.NotificationGroup.TALENT]: 2,
    [ENUMS.NotificationGroup.REFERRALS]: 3,
    [ENUMS.NotificationGroup.MESSAGING]: 4,
    default: 5,
  };

  const sortedNotifications = Object.keys(notificationsWithoutJobsGroup).sort(
    (groupA, groupB) => {
      const aKey = groupA as keyof typeof sortOrder;
      const bKey = groupB as keyof typeof sortOrder;
      return (
        (sortOrder[aKey] || sortOrder["default"]) -
        (sortOrder[bKey] || sortOrder["default"])
      );
    },
  );

  return (
    <Box mb={5}>
      {jobsNotificationGroup && (
        <JobsNotificationsSection
          key="job"
          title={"Jobs"}
          notifications={jobsNotificationGroup}
        />
      )}
      {sortedNotifications.map((group) => {
        return (
          <NotificationsSection
            key={group}
            title={group}
            notifications={notificationsWithoutJobsGroup[group]}
          />
        );
      })}
    </Box>
  );
};
