import { Box } from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";
import { HlsPlayer } from "@js/components/video-player";

import styles from "./styles.module.scss";

const VideoPreviewModalContent = ({ videoUrl }: { videoUrl: string }) => (
  <Box className={styles.videoPreviewContainer}>
    <Box className={styles.videoPreview}>
      <HlsPlayer
        options={{
          autoplay: true,
          controls: true,
          responsive: true,
          fluid: true,
          sources: [
            {
              src: videoUrl,
              type: "video/mp4",
            },
            {
              src: videoUrl,
              type: "video/quicktime",
            },
          ],
        }}
      />
    </Box>
  </Box>
);

export const openVideoPreviewModal = ({ videoUrl }: { videoUrl: string }) => {
  ModalInstance.open({
    className: `${styles.videoPreviewModal} text-content-mw"`,
    children: <VideoPreviewModalContent videoUrl={videoUrl} />,
    keepOnBackdropClick: true,
    closeButton: true,
    closeButtonProps: {
      sx: {
        backgroundColor: "var(--white) !important",
        height: "48px",
        width: "48px",
      },
      size: "x-small",
    },
  });
};
