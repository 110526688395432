import { useMemo } from "react";
import cs from "classnames";

import { Box } from "@hexocean/braintrust-ui-components";
import { usePostContext } from "@js/apps/give-and-get-help/context";
import { useSubscribePostOrComment } from "@js/apps/give-and-get-help/hooks/websocket-subscribe";
import type { PostComment } from "@js/types/give-and-get-help";

import { Comment } from "../single-comment";

import styles from "../styles.module.scss";

export type CommentsListProps = {
  postId: number;
  comments: PostComment[];
  isFirstLevel?: boolean;
  toggleReplyBox?: (postId: number) => void;
};

export const CommentsList = ({
  postId,
  comments,
  isFirstLevel,
  toggleReplyBox,
}: CommentsListProps) => {
  const commentsIdsToSubscribe = useMemo(
    () => comments.map((comment) => comment.id),
    [comments],
  );

  useSubscribePostOrComment(commentsIdsToSubscribe);

  const id = isFirstLevel ? `post-comments-${postId}` : undefined;
  return (
    <Box className={styles.feedCommentsSectionList} id={id} data-testid={id}>
      {comments.map((comment, index) => (
        <Comment
          CommentsList={CommentsList}
          key={comment.id}
          postId={postId}
          comment={comment}
          isFirstLevel={isFirstLevel}
          toggleReplyBox={toggleReplyBox}
          isLastInThread={comments.length === index + 1}
        />
      ))}
    </Box>
  );
};

export const PublicCommentsList = ({
  postId,
  comments,
  isFirstLevel,
  toggleReplyBox,
}: CommentsListProps) => {
  const { isPublic } = usePostContext();

  return (
    <Box
      id={`post-comments-${postId}`}
      data-testid={`post-comments-${postId}`}
      className={cs(styles.feedCommentsSectionList, {
        [styles.feedCommentsSectionListPublic]: isFirstLevel,
      })}
    >
      {comments.map((comment, index) => (
        <Comment
          CommentsList={isPublic ? PublicCommentsList : CommentsList}
          key={comment.id}
          postId={postId}
          comment={comment}
          isFirstLevel={isFirstLevel}
          toggleReplyBox={toggleReplyBox}
          isLastInThread={comments.length === index + 1}
          restrictTogglingReplies
        />
      ))}
    </Box>
  );
};
