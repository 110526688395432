import { useEffect, useRef, useState } from "react";

import type { Attachment } from "@js/types/uploads";

import { openVideoPreviewModal } from "./video-preview-modal";

export const useManageIntroVideo = (videoAttachment: Attachment) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState<string>();
  const [thumbnail, setThumbnail] = useState<string>();

  useEffect(() => {
    const video = videoRef.current;

    if (!video || !videoAttachment?.file) return;

    video.crossOrigin = "anonymous";
    video.src = videoAttachment.file;
    video.preload = "auto";
    video.muted = true;

    video.onloadedmetadata = () => {
      video.currentTime = 3;
      const minutes = Math.floor(video.duration / 60);
      const seconds = Math.ceil(video.duration % 60);
      setDuration(`${minutes}:${seconds.toString().padStart(2, "0")}`);
    };

    video.onseeked = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      context?.drawImage(video, 0, 0, canvas.width, canvas.height);

      setThumbnail(canvas.toDataURL("image/png"));
    };

    return () => {
      video.src = "";
    };
  }, [videoAttachment?.file]);

  const handlePreview = () => {
    openVideoPreviewModal({ videoUrl: videoAttachment?.file });
  };

  return {
    videoRef,
    duration,
    thumbnail,
    handlePreview,
  };
};
