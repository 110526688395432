import React, { useMemo } from "react";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  IconButton,
  Rating,
  RoundedBox,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { JobSectionTitle } from "@js/apps/jobs/components/job-section-title";
import type { Role } from "@js/types/roles";
import { getExperienceString } from "@js/utils";

import style from "./style.module.scss";

type ExperienceBoxProps = BoxProps & {
  experienceAsArrow?: number;
  experienceLevel?: EnumType<typeof ENUMS.JobExperienceLevel> | null;
  jobRole: Role;
  level?: EnumType<typeof ENUMS.SkillLevel>;
};

export const ExperienceBox = ({
  experienceAsArrow,
  experienceLevel,
  jobRole,
  level,
  ...props
}: ExperienceBoxProps) => {
  const isDesktop = useMediaQuery("md");
  const isMobile = useMediaQuery("sm");

  const seniorityLabelText = getExperienceString(experienceLevel);
  const seniorityFallbackLabelText = useMemo(
    () => !seniorityLabelText && level && ENUMS.SkillLevelLabels[level],
    [seniorityLabelText, level],
  );
  const seniorityArrowFallback =
    Number(level) >= 2 ? Number(level) - 1 : Number(level);

  const size = (): "small" | "medium" | "large" => {
    if (isMobile) {
      return "small";
    }

    if (isDesktop) {
      return "medium";
    }

    return "large";
  };

  if (!seniorityLabelText && !seniorityArrowFallback) return null;

  return (
    <RoundedBox height="100%" {...props}>
      <JobSectionTitle mb={0.75}>Experience</JobSectionTitle>
      <Box className={isDesktop ? style.seniorityWrapper : ""}>
        <Typography
          mb={3}
          className={isDesktop ? style.seniorityTitle : ""}
          component="h2"
          variant="title"
          fontWeight={400}
          size="small"
        >
          {seniorityLabelText || seniorityFallbackLabelText}
          <Tooltip
            maxWidth={360}
            placement="top"
            title={
              <div>
                <Typography component="span" variant="paragraph" size="medium">
                  {seniorityLabelText || seniorityFallbackLabelText} of
                  experience in
                </Typography>

                <Typography
                  component="span"
                  variant="paragraph"
                  size="medium"
                  fontWeight={500}
                >
                  {" "}
                  {jobRole.name}
                </Typography>
              </div>
            }
          >
            <span>
              <IconButton
                variant="transparent"
                style={{
                  pointerEvents: "none",
                  padding: "5px",
                  color: "var(--black-80)",
                }}
                aria-label="Info"
              >
                <InfoIcon sx={{ fontSize: "14px", marginBottom: "2px" }} />
              </IconButton>
            </span>
          </Tooltip>
        </Typography>
        <Rating
          color="violet"
          variant="triangles"
          value={experienceAsArrow || seniorityArrowFallback}
          size={size()}
          max={4}
          readOnly
        />
      </Box>
    </RoundedBox>
  );
};
