import { useCallback, useEffect, useState } from "react";

import { API } from "@js/api";
import { useUser } from "@js/apps/common/hooks";
import { useGetJobQuery } from "@js/apps/jobs/api";
import { getUseGetOrCreateRoomArg } from "@js/apps/jobs/utils";
import { useManualSendMessageMutation } from "@js/apps/messenger/api";
import { useGetOrCreateRoom } from "@js/apps/messenger/hooks";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useGoBackHistory } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";
import { getRejectOfferMessage } from "@js/services";
import { isAKeyOfAnObject } from "@js/utils";

import {
  useFetchBidQuery,
  useUpdateEmployerBidFeedbackMutation,
  useUpdateEmployerBidSectionMutation,
} from "../../api";
import { openRejectOfferMessageModal } from "../../components/bid-messages-modals/reject-offer-message";
import type { BidFeedbackFormData } from "../../forms";

type useGetOrCreateRoomError = {
  data: {
    context: string;
  };
};

export const useBidRejectionFeedbackPage = () => {
  const dispatch = useAppDispatch();
  const goBack = useGoBackHistory();

  const user = useUser();
  const jobId = useIdParam("id");
  const bidId = useIdParam("bidId");
  const [message, setMessage] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { data: bid, isFetching: isFetchingBid } = useFetchBidQuery(
    { bidId: bidId as number },
    { skip: !bidId },
  );
  const { data: job, isLoading: isLoadingJob } = useGetJobQuery(
    { id: Number(bid?.job_id) },
    { skip: !bid },
  );

  const isEmployersOwnProfile = job?.employer.id === user?.employer;

  const [manualSendMessage] = useManualSendMessageMutation();
  const [updateEmployerBidFeedback] = useUpdateEmployerBidFeedbackMutation();
  const [updateEmployerBidSection] = useUpdateEmployerBidSectionMutation();

  const refetchBidList = useCallback(() => {
    dispatch(API.util.invalidateTags([{ type: "EmployerBids", id: "LIST" }]));
  }, [dispatch]);

  const { error, room } = useGetOrCreateRoom(
    getUseGetOrCreateRoomArg({
      receiverUserId: bid?.freelancer.user.id,
      senderUserId: user?.id,
    }),
  );

  useEffect(() => {
    if (typeof error === "object" && error !== null && "data" in error) {
      // if there is an error, navigating back from /rejection_feedback is necessary to avoid getting stuck on an infinite loader
      goBack(`/jobs/${jobId}/proposals/`);
      Snackbar.error((error as useGetOrCreateRoomError).data);
    }
  }, [error, goBack, jobId]);

  useEffect(() => {
    if (bidId) {
      const messageToTalent = getRejectOfferMessage(bidId);
      if (messageToTalent) {
        setMessage(messageToTalent);
      }
    }
  }, [bidId]);

  const onSubmit = async (feedback: BidFeedbackFormData) => {
    setIsSubmitting(true);
    const bidPath = `/jobs/${bid?.job_id}/proposals/${bid?.id}?tab=messages`;

    if (message && room) {
      const roomContext = {
        participants: room.participants.map((participant) => participant?.id),
      };

      await manualSendMessage({
        payload: { message_content: message, author: user?.id },
        room_context: {
          content_type: ENUMS.RoomTypeContentType.P2P_ROOM_TYPE,
          context: roomContext,
        },
      }).catch(() => Snackbar.error("Failed to send message"));
    }

    if (bidId) {
      await updateEmployerBidFeedback({
        bidId,
        feedback: {
          ...prepareBidFeedback(feedback),
          decline_reason_for_talent: message,
        },
      });

      await updateEmployerBidSection({
        bidId,
        status: ENUMS.BidStatus.REJECTED,
      });
    }

    goBack(`/jobs/${jobId}/proposals/`);
    Snackbar.toast({
      header: `You rejected ${bid?.freelancer.user.first_name} for this role.`,
      content: (closeSnackbar) => {
        return (
          <>
            Thank you for letting {bid?.freelancer.user.first_name} know that it
            didn’t work out this time.{" "}
            {isEmployersOwnProfile && (
              <>
                If you need to follow up, this conversation is tracked in the{" "}
                <RouterLink
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    closeSnackbar();
                  }}
                  to={bidPath}
                >
                  messages tab
                </RouterLink>
              </>
            )}
          </>
        );
      },
    });

    setIsSubmitting(false);
  };

  const handleGoBackClick = useCallback(() => {
    goBack(`/jobs/${jobId}/proposals/`);
    if (bid && job) {
      openRejectOfferMessageModal({
        bid,
        nextUrl: `/jobs/${job?.id}/proposals/${bid?.id}/rejection_feedback`,
        job,
        refetchBidList,
      });
    }
  }, [goBack, jobId, bid, job, refetchBidList]);

  return {
    handleGoBackClick,
    onSubmit,
    isLoading:
      isFetchingBid || !bid || !room || isLoadingJob || !job || isSubmitting,
  };
};

const prepareBidFeedback = (formValues: BidFeedbackFormData) => {
  const reason = Object.keys(formValues).filter(
    (key): key is keyof Omit<BidFeedbackFormData, "reason_other"> => {
      return key !== "reason_other" && isAKeyOfAnObject(formValues, key);
    },
  );

  return {
    reason: reason,
    reason_other: formValues.reason_other,
  };
};
