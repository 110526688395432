import { Outlet } from "react-router-dom";
import { formValueSelector } from "redux-form";

import { FormLayoutWrapper } from "@js/apps/common/components";
import { ONBOARDING_FORM_ID } from "@js/apps/onboarding/constants";
import {
  useOnboardingForm,
  useSignUpInvitationData,
} from "@js/apps/onboarding/hooks";
import type { OverwriteContentComponentProps } from "@js/layouts/app";
import { AppLayout } from "@js/layouts/app";

import style from "./style.module.scss";

export const OnboardingFormWrapper = () => {
  return (
    <AppLayout
      className={`no-padding ${style.onboardingFormWrapper}`}
      hideHeader
      disableSubheaderPortal
      OverwriteContentComponent={OnboardingWrapperContent}
      children={null}
    />
  );
};

const OnboardingWrapperContent = (props: OverwriteContentComponentProps) => {
  const { formProps, updateSubmitType } = useOnboardingForm();
  useSignUpInvitationData();

  return (
    <FormLayoutWrapper
      formId={ONBOARDING_FORM_ID}
      formProps={formProps}
      loading={false}
      {...props}
    >
      <Outlet context={updateSubmitType} />
    </FormLayoutWrapper>
  );
};

export const onboardingFormValueSelector =
  formValueSelector(ONBOARDING_FORM_ID);
