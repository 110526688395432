import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Avatar, Stack } from "@hexocean/braintrust-ui-components";
import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { CheckboxField } from "@js/forms/fields";
import type { EmployerBidListBid } from "@js/types/jobs";
import { capitalizeEachFirstLetter } from "@js/utils";

type SendCalendarLinkProps = {
  freelancer: EmployerBidListBid["freelancer"];
  onEditLink: () => void;
};

export const SendCalendarLink = ({
  freelancer,
  onEditLink,
}: SendCalendarLinkProps) => {
  return (
    <>
      <Typography component="h1" variant="title" fontWeight={400} size="small">
        Send scheduling link
      </Typography>
      <Box mt={1}>
        Please confirm the scheduling link that you will send to{" "}
        {capitalizeEachFirstLetter(freelancer.user.first_name)} to book an
        interview with you.
      </Box>

      <Field
        name="calendar_link"
        component={CalendarDetails}
        freelancer={freelancer}
      />

      <Box display="flex" mt={4} mb={1} gap={1}>
        <Button variant="secondary" shape="squared" onClick={onEditLink}>
          Edit link
        </Button>
        <Button variant="primary" shape="squared" type="submit">
          Send scheduling link
        </Button>
      </Box>

      <Field
        id="same_link_for_all"
        name="same_link_for_all"
        component={CheckboxField}
        size="small"
        label={
          <Typography component="p" variant="paragraph" size="small">
            Send the same scheduling link for all interviews on this job
          </Typography>
        }
      />
    </>
  );
};

const CalendarDetails = ({
  input,
  freelancer,
}: TypedWrappedFieldProps<string> & {
  freelancer: EmployerBidListBid["freelancer"];
}) => {
  return (
    <Stack gap={1} mt={3} mb={4}>
      <Typography component="span" variant="label">
        You’ll be sending
      </Typography>
      <Typography component="p" size="large">
        {input?.value}
      </Typography>
      <Box display="flex" flex={1} gap={0.5} alignItems="center" mt={2}>
        <Typography component="span" variant="label" flexShrink={0} noWrap>
          To
        </Typography>
        <Box
          sx={{
            borderRadius: "50%",
            padding: "2.5px",
            flexShrink: 0,
            height: 44,
            width: 44,
          }}
        >
          <Avatar src={freelancer.user} size={"full"} />
        </Box>
        <Typography
          component="p"
          size="large"
          ellipsis
          title={capitalizeEachFirstLetter(freelancer.user.public_name)}
        >
          {capitalizeEachFirstLetter(freelancer.user.public_name)}
        </Typography>
      </Box>
    </Stack>
  );
};
