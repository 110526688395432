import React, { useCallback } from "react";

import {
  Box,
  CardMedia,
  Grid,
  Typography,
  useSwiperSlide,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CalendarIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Date } from "@js/components/date";
import { Sanitize } from "@js/components/sanitize";
import type { WorkSampleItem } from "@js/types/freelancer";
import { DateFormats } from "@js/utils/date/types";

import styles from "./styles.module.scss";

type ProjectItemProps = {
  item: WorkSampleItem;
  index: number;
  openProjectModal: (index: number) => void;
};

const ProjectItem: React.FC<ProjectItemProps> = ({
  openProjectModal,
  item,
  index,
}) => {
  const handleClick = useCallback(() => {
    openProjectModal(index);
  }, [index, openProjectModal]);

  const isMobile = useMediaQuery(400);

  const imageUrl = item.main_image
    ? item.main_image.image.attachment.file
    : `${SETTINGS.STATIC_URL}jobs/default-work-sample-photo.svg`;

  const { isActive } = useSwiperSlide();

  return (
    <Grid
      container
      direction="column"
      style={{ wordBreak: "break-word" }}
      component="article"
    >
      <CardMedia
        onClick={handleClick}
        style={{
          cursor: "pointer",
          height: 0,
          paddingTop: "34%",
          borderRadius: 8,
          boxShadow: "var(--elevation-5)",
        }}
        image={imageUrl}
      />
      <Box
        pt={{ xs: 4, sm: 8 }}
        pb={{ xs: 4, sm: 8 }}
        pl={{ xs: 2, sm: 4 }}
        pr={{ xs: 2, sm: 4 }}
      >
        <Grid container direction="column">
          <Box component="header" mb={{ xs: 2, sm: 4 }}>
            <Typography
              variant={isMobile ? "label" : "title"}
              size={isMobile ? "large" : "medium"}
              fontWeight={500}
              component="h3"
              mb={1}
            >
              <button
                className="btn-reset pointer"
                tabIndex={isActive ? 0 : -1}
                onClick={handleClick}
              >
                {item.title}
              </button>
            </Typography>
            <Typography
              style={{ display: "flex" }}
              color="secondary"
              component="p"
            >
              {item.completion_date && (
                <>
                  <Box component="span" mr={1}>
                    <CalendarIcon titleAccess="Completion date" />
                  </Box>
                  <Date
                    date={item.completion_date}
                    format={DateFormats["January, 1970"]}
                  />
                </>
              )}
            </Typography>
          </Box>
          <Typography component="div" className={styles.projectItemDescription}>
            <Sanitize input={item.description} />
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
};

export default React.memo(ProjectItem);
