import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";

import { BookmarksPage } from "@js/apps/bookmarks/layout/bookmarks-layout";
import { CareerHelpBookmarksPage } from "@js/apps/bookmarks/views/career-help-bookmarks";
import { JobsBookmarksPage } from "@js/apps/bookmarks/views/jobs-bookmarks";
import { EmployerDashboardMyJobsPage } from "@js/apps/employer/views/dashboard/my-jobs";
import { EmployerDashboardMyTalentPage } from "@js/apps/employer/views/dashboard/my-talent";
import { EmployerDashboardWelcomePage } from "@js/apps/employer/views/dashboard/welcome";
import { ATSIntegrationPage } from "@js/apps/employer/views/edit-profile/ats-integration";
import { EmployerEditOrganizationInfoPage } from "@js/apps/employer/views/edit-profile/organization-info";
import { EmployerEditPersonalInfoPage } from "@js/apps/employer/views/edit-profile/personal-info";
import { EmployerTeamMembersPage } from "@js/apps/employer/views/edit-profile/team-members";
import { EmployerInvoicesPage } from "@js/apps/employer/views/invoices";
import {
  EmployerInvoiceBatchesViewLazy,
  EmployerInvoicesTableViewLazy,
} from "@js/apps/employer/views/lazy";
import { MyWorkIndexRedirect } from "@js/apps/freelancer/components/my-work-index-redirect";
import { FreelancerProfilePageRoutes } from "@js/apps/freelancer/routes/freelancer-profile";
import { SearchTalentPage } from "@js/apps/freelancer/views";
import { EarnPage } from "@js/apps/freelancer/views/dashboard/earn";
import { EarnIndexRedirectPage } from "@js/apps/freelancer/views/dashboard/earn/earn-index-redirect-page";
import { MyHelpOffersListingPage } from "@js/apps/freelancer/views/dashboard/help-offers/my-help-offers-listing";
import { MyWalletPage } from "@js/apps/freelancer/views/dashboard/my-wallet";
import { MyOffersListingPage } from "@js/apps/freelancer/views/dashboard/offers";
import {
  FreelancerDashboardGettingHelpOffersPageLazy,
  FreelancerDashboardGivingHelpOffersPageLazy,
} from "@js/apps/freelancer/views/lazy";
import { GGHPageOutlet } from "@js/apps/give-and-get-help/components/ggh-page-outlet";
import {
  FeedPage,
  MyPostsPage,
  SearchPosts,
  SinglePostPageWrapper,
} from "@js/apps/give-and-get-help/views";
import { EditInvoicePage } from "@js/apps/invoices/views/edit-invoice";
import { BidDrawer } from "@js/apps/jobs/apps/bids";
import {
  CreateJobRoutes,
  EditJobRoutes,
} from "@js/apps/jobs/apps/create-job/routes/";
import {
  RoleJobsPage,
  SearchJobsPage,
} from "@js/apps/jobs/apps/listing/views/";
import { OffersRoutes } from "@js/apps/jobs/apps/offers/routes";
import { EmbeddedJobsListPage } from "@js/apps/jobs/apps/public-listing/views/embedded-jobs-list";
import { InviteTalentDrawer } from "@js/apps/jobs/components";
import { InterviewQuestionsPage } from "@js/apps/jobs/views/interview-questions";
import { InterviewPage } from "@js/apps/jobs/views/interviews";
import JobsListing from "@js/apps/jobs/views/jobs-listing";
import RejectionFeedbackPage from "@js/apps/jobs/views/rejection-feedback";
import { MessagesProvider } from "@js/apps/messenger/context";
import { MessagesPage } from "@js/apps/messenger/views/messages";
import { NFTRoutes } from "@js/apps/nft/routes";
import { OnboardingRoutes } from "@js/apps/onboarding/routes";
import {
  InvitationRedirect,
  SignUpFinishPage,
} from "@js/apps/onboarding/views";
import { OnboardingOrganizationPage } from "@js/apps/onboarding/views/organization-page/organization-page";
import { SignUpEmailConfirmPage } from "@js/apps/onboarding/views/sign-up-email-confirm";
import PurchasePage from "@js/apps/settings/views/purchases";
import { AboutSpace } from "@js/apps/spaces/components/views/about-space";
import { SpacesPeople } from "@js/apps/spaces/components/views/spaces-people";
import { ExploreSpacesPage } from "@js/apps/spaces/views/explore-spaces";
import { SpacePageWrapper } from "@js/apps/spaces/views/space-page";
import { SinglePostSpacePage } from "@js/apps/spaces/views/space-single-post";
import { ErrorBoundary } from "@js/components/error-boundary";
import { RhfTestRoutes } from "@js/rhf/examples/";

import ConfirmEmailChangePage from "../apps/auth/views/confirm-email-change";
import ForgotPasswordPage from "../apps/auth/views/forgot-password";
import AuthLoginPage from "../apps/auth/views/login";
import AuthResetPasswordPage from "../apps/auth/views/reset-password";
import AccessDenied from "../apps/common/views/access-denied";
import App from "../apps/common/views/app";
import { ConfirmPage } from "../apps/common/views/confirm-page";
import PageNotFoundPage from "../apps/common/views/page-not-found";
import TooManyRequestsPage from "../apps/common/views/too-many-requests";
import { DashboardReferClientsPage } from "../apps/dashboard/views/refer-clients";
import DashboardReferClientsProgramPage from "../apps/dashboard/views/refer-clients-program";
import { DashboardReferTalentPage } from "../apps/dashboard/views/refer-talent";
import { EmployerProfilePage } from "../apps/employer/views/profile";
import { FreelancerDashboardBidsListingPage } from "../apps/freelancer/views/dashboard/applications/my-bids-listing";
import { FreelancerDashboardInvoicesPage } from "../apps/freelancer/views/dashboard/invoices";
import { DashboardMyJobsPage } from "../apps/freelancer/views/dashboard/jobs-history";
import { DashboardMyInvitesPage } from "../apps/freelancer/views/dashboard/my-invites";
import { FreelancerDashboardWelcomePage } from "../apps/freelancer/views/dashboard/welcome";
import { TalentListingPage } from "../apps/freelancer/views/talent-list";
import { AddInvoicePage } from "../apps/invoices/views/add-invoice";
import { FreelancerInvoicesListingPage } from "../apps/invoices/views/freelancer";
import { InviteTalentPage } from "../apps/jobs/views/invite-talent";
import JobBidsPage from "../apps/jobs/views/job-bids";
import JobDetailsPage from "../apps/jobs/views/job-details";
import { JobEditBidPage, JobNewBidPage } from "../apps/jobs/views/job-new-bid";
import { OnboarddingScheduleMeeting } from "../apps/onboarding/views/oragnization-schedule-meeting";
import SettingsAccountInfoPage from "../apps/settings/views/account-info";
import SettingsNotifications from "../apps/settings/views/notifications";
import SettingsPayments from "../apps/settings/views/payments";
import SettingsPrivacySecurity from "../apps/settings/views/privacy-security";
import { Discussion } from "../apps/spaces/views/discussion";
import { lazyWithRetry } from "../utils";

import AdminRoutes from "./admin-routes";
import { ACCESS_RULE } from "./constants";
import {
  Logout,
  ProtectedRoute,
  RedirectAuthenticatedUserFromLoginToNext,
} from "./protected-route";
import redirects from "./route-redirects";
import { UnknownRoute } from "./unknown-route";

const GrantTokenRewardPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/grant-btrust-reward"
    ),
);

const TALENT_MAIN_ACCESS_RULE = [
  ...ACCESS_RULE.ACCOUNT_ALL_SET,
  ACCESS_RULE.HAS_FREELANCER,
];

const EMPLOYER_MAIN_ACCESS_RULE = [
  ...ACCESS_RULE.ACCOUNT_ALL_SET,
  ACCESS_RULE.HAS_EMPLOYER,
];

export const routes = (
  additionalContext: (context: JSX.Element) => JSX.Element,
) => (
  <Route
    path="/"
    element={<ErrorBoundary>{additionalContext(<App />)}</ErrorBoundary>}
  >
    {redirects}
    {AdminRoutes}
    <Route
      path="grant_token_reward"
      element={
        <ProtectedRoute
          rules={[
            ACCESS_RULE.AUTHENTICATED,
            ACCESS_RULE.CAN_GRANT_TOKEN_REWARD,
          ]}
        >
          <GrantTokenRewardPage />
        </ProtectedRoute>
      }
    />
    <Route path="r/:referrer" element={<InvitationRedirect />} />
    <Route path="auth">
      <Route index element={<Navigate to="login" />} />
      <Route
        path="login"
        element={
          <RedirectAuthenticatedUserFromLoginToNext>
            <ProtectedRoute rules={[ACCESS_RULE.NOT_AUTHENTICATED]}>
              <AuthLoginPage />
            </ProtectedRoute>
          </RedirectAuthenticatedUserFromLoginToNext>
        }
      />
      <Route path="sign_up/*" element={<Outlet />}>
        {OnboardingRoutes}
      </Route>
      <Route
        path="forgot_password"
        element={
          <ProtectedRoute rules={[ACCESS_RULE.NOT_AUTHENTICATED]}>
            <ForgotPasswordPage />
          </ProtectedRoute>
        }
      />
      <Route path="logout" element={<Logout />} />
    </Route>
    <Route
      path="verify_email"
      element={
        <ProtectedRoute
          rules={[
            ACCESS_RULE.AUTHENTICATED,
            ACCESS_RULE.ACCOUNT_TYPE_SELECTED,
            ACCESS_RULE.EMAIL_NOT_VERIFIED,
          ]}
        >
          <SignUpFinishPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="email_confirm"
      element={
        <ProtectedRoute
          rules={[
            ACCESS_RULE.ACCOUNT_TYPE_SELECTED,
            ACCESS_RULE.EMAIL_NOT_VERIFIED,
          ]}
        >
          <SignUpEmailConfirmPage />
        </ProtectedRoute>
      }
    />
    <Route path="confirm-email-change" element={<ConfirmEmailChangePage />} />
    <Route
      path="reset_password/:uid/:token"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.NOT_AUTHENTICATED]}>
          <AuthResetPasswordPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="search-talent"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.AUTHENTICATED]}>
          <SearchTalentPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="talent"
      element={
        <ProtectedRoute rules={[...ACCESS_RULE.ACCOUNT_ALL_SET]}>
          <TalentListingPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="talent"
      element={
        <ProtectedRoute rules={[...TALENT_MAIN_ACCESS_RULE]}>
          <Outlet />
        </ProtectedRoute>
      }
    >
      <Route path="dashboard">
        <Route index element={<Navigate to="welcome" />} />
        <Route path="welcome" element={<FreelancerDashboardWelcomePage />} />
        <Route path="my_jobs">
          <Route index element={<MyWorkIndexRedirect />} />
          <Route path="history" element={<Outlet />}>
            <Route index element={<DashboardMyJobsPage />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>
          <Route path="invites" element={<DashboardMyInvitesPage />} />
          <Route path="my_proposals" element={<Outlet />}>
            <Route index element={<FreelancerDashboardBidsListingPage />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="help_offers" element={<MyHelpOffersListingPage />}>
            <Route index element={<Navigate to="getting_help" replace />} />
            <Route
              path="getting_help"
              element={<FreelancerDashboardGettingHelpOffersPageLazy />}
            />
            <Route
              path="giving_help"
              element={<FreelancerDashboardGivingHelpOffersPageLazy />}
            />
          </Route>
          <Route
            path="invoices"
            element={<FreelancerDashboardInvoicesPage />}
          />
          <Route path="offers" element={<Outlet />}>
            <Route index element={<MyOffersListingPage />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>
        </Route>
        <Route path="earn" element={<EarnPage />}>
          <Route index element={<EarnIndexRedirectPage />} />
          <Route path="refer_talent" element={<DashboardReferTalentPage />} />
          <Route path="refer_clients" element={<DashboardReferClientsPage />} />
          <Route
            path="refer_clients_program"
            element={<DashboardReferClientsProgramPage />}
          />
        </Route>
        <Route path="my_wallet" element={<MyWalletPage />} />
      </Route>
      <Route path="invoices">
        <Route index element={<FreelancerInvoicesListingPage />} />
        <Route path="add_new" element={<AddInvoicePage />} />
        <Route path="edit/:id" element={<EditInvoicePage />} />
      </Route>
    </Route>
    <Route path="talent/:id/*">{FreelancerProfilePageRoutes}</Route>

    <Route path="employer">
      <Route index element={<Navigate to="dashboard" />} />
      <Route
        path="dashboard"
        element={
          <ProtectedRoute rules={[...EMPLOYER_MAIN_ACCESS_RULE]}>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="welcome" />} />
        <Route
          index
          path="welcome"
          element={
            <ProtectedRoute rules={[ACCESS_RULE.EMPLOYER_HAS_ADDRESS_SET]}>
              <EmployerDashboardWelcomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="my_jobs"
          element={
            <ProtectedRoute rules={[ACCESS_RULE.EMPLOYER_HAS_ADDRESS_SET]}>
              <EmployerDashboardMyJobsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="my_talent"
          element={
            <ProtectedRoute rules={[ACCESS_RULE.EMPLOYER_HAS_ADDRESS_SET]}>
              <EmployerDashboardMyTalentPage />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="invoices"
        element={
          <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_EMPLOYER_INVOICES]}>
            <EmployerInvoicesPage />
          </ProtectedRoute>
        }
      >
        <Route index element={<EmployerInvoicesTableViewLazy />} />
        <Route
          path="invoice_batches"
          element={<EmployerInvoiceBatchesViewLazy />}
        />
      </Route>
      <Route
        path="edit"
        element={
          <ProtectedRoute
            rules={[
              ...EMPLOYER_MAIN_ACCESS_RULE,
              ACCESS_RULE.EMPLOYER_HAS_ADDRESS_SET,
            ]}
          >
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="personal_info" />} />
        <Route
          path="personal_info"
          element={<EmployerEditPersonalInfoPage />}
        />
        <Route
          path="organization_info"
          element={<EmployerEditOrganizationInfoPage />}
        />
        <Route path="team_members" element={<EmployerTeamMembersPage />} />
        <Route path="ats_integration" element={<ATSIntegrationPage />} />
      </Route>
    </Route>
    <Route path="employers/:id" element={<EmployerProfilePage />} />
    <Route
      path="feed"
      element={
        <ProtectedRoute
          rules={[ACCESS_RULE.AUTHENTICATED, ACCESS_RULE.HAS_FREELANCER]}
        >
          <GGHPageOutlet />
        </ProtectedRoute>
      }
    >
      <Route index element={<FeedPage />} />
      <Route path="careerchoice" element={<FeedPage />} />
      <Route path="my_posts" element={<MyPostsPage />} />
    </Route>
    <Route path="feed/:id" element={<Outlet />}>
      <Route index element={<SinglePostPageWrapper />} />
      <Route path=":slug" element={<SinglePostPageWrapper />} />
    </Route>
    <Route
      path="spaces"
      element={
        <ProtectedRoute
          rules={[ACCESS_RULE.AUTHENTICATED, ACCESS_RULE.HAS_FREELANCER]}
        >
          <ExploreSpacesPage />
        </ProtectedRoute>
      }
    />
    <Route path="spaces/:spaceId" element={<SpacePageWrapper />}>
      <Route index element={<Navigate to="discussion" replace />} />
      <Route path="discussion" element={<Discussion />} />
      <Route path="about" element={<AboutSpace />} />
      <Route path="people" element={<SpacesPeople />} />
    </Route>
    <Route path="spaces/:spaceId/post/:spacePostId" element={<Outlet />}>
      <Route index element={<SinglePostSpacePage />} />
      <Route path=":slug" element={<SinglePostSpacePage />} />
    </Route>
    <Route path="jobs" element={<Outlet />}>
      <Route index element={<JobsListing />} />
      <Route
        path="add_new/*"
        element={
          <ProtectedRoute rules={[...ACCESS_RULE.ACCOUNT_ALL_SET]}>
            <Outlet />
          </ProtectedRoute>
        }
      >
        {CreateJobRoutes}
      </Route>
      <Route
        path=":id"
        element={
          <ProtectedRoute rules={[]}>
            <JobDetailsPage />
          </ProtectedRoute>
        }
      >
        <Route
          path="invite_matched_talent"
          element={
            <ProtectedRoute rules={[...ACCESS_RULE.ACCOUNT_ALL_SET]}>
              <InviteTalentDrawer />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path=":id">
        <Route
          path="invite_talent"
          element={
            <ProtectedRoute rules={[ACCESS_RULE.AUTHENTICATED]}>
              <InviteTalentPage />
            </ProtectedRoute>
          }
        />
        <Route path="edit/*" element={<Outlet />}>
          {EditJobRoutes}
        </Route>
        <Route
          path="proposals"
          element={
            <ProtectedRoute rules={[ACCESS_RULE.AUTHENTICATED]}>
              <JobBidsPage />
            </ProtectedRoute>
          }
        >
          <Route path=":bidId" element={<BidDrawer />} />
        </Route>
        <Route path="interviews" element={<InterviewPage />} />
        <Route
          path="interview-questions"
          element={
            <ProtectedRoute rules={[ACCESS_RULE.AUTHENTICATED]}>
              <InterviewQuestionsPage />
            </ProtectedRoute>
          }
        />
        <Route path="proposals">
          <Route
            path="new"
            element={
              <ProtectedRoute rules={[ACCESS_RULE.HAS_FREELANCER]}>
                <JobNewBidPage />
              </ProtectedRoute>
            }
          />
          <Route
            path=":bidId/edit"
            element={
              <ProtectedRoute rules={[ACCESS_RULE.HAS_FREELANCER]}>
                <JobEditBidPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="proposals/:bidId/rejection_feedback"
          element={<RejectionFeedbackPage />}
        />
        <Route path="offers/*" element={<Outlet />}>
          {OffersRoutes}
        </Route>
      </Route>
    </Route>
    <Route path="jobs-list" element={<EmbeddedJobsListPage />} />
    <Route
      path="bookmarks"
      element={
        <ProtectedRoute
          rules={[ACCESS_RULE.AUTHENTICATED, ACCESS_RULE.HAS_FREELANCER]}
        >
          <BookmarksPage />
        </ProtectedRoute>
      }
    >
      <Route index element={<Navigate to="career_help" replace />} />
      <Route path="career_help" element={<CareerHelpBookmarksPage />} />
      <Route path="jobs" element={<JobsBookmarksPage />} />
    </Route>
    <Route
      path="role-jobs"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.HAS_FREELANCER]}>
          <RoleJobsPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="search-jobs"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.AUTHENTICATED]}>
          <SearchJobsPage />
        </ProtectedRoute>
      }
    />

    <Route
      path="search-feed"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.HAS_FREELANCER]}>
          <SearchPosts />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings"
      element={
        <ProtectedRoute rules={[...ACCESS_RULE.ACCOUNT_ALL_SET]}>
          <Outlet />
        </ProtectedRoute>
      }
    >
      <Route index element={<Navigate to="account_info" />} />
      <Route path="account_info" element={<SettingsAccountInfoPage />} />
      <Route path="notifications" element={<SettingsNotifications />} />
      <Route path="privacy_security" element={<SettingsPrivacySecurity />} />
      <Route path="payments" element={<SettingsPayments />} />
      <Route path="purchases" element={<PurchasePage />} />
    </Route>
    <Route
      path="messages"
      element={
        <ProtectedRoute rules={[...ACCESS_RULE.ACCOUNT_ALL_SET]}>
          <MessagesProvider>
            <MessagesPage />
          </MessagesProvider>
        </ProtectedRoute>
      }
    />
    <Route
      path="onboarding"
      element={
        <ProtectedRoute rules={[...EMPLOYER_MAIN_ACCESS_RULE]}>
          <Outlet />
        </ProtectedRoute>
      }
    >
      <Route index element={<Navigate to="organization" />} />
      <Route path="organization" element={<OnboardingOrganizationPage />} />
      <Route path="schedule-meeting" element={<OnboarddingScheduleMeeting />} />
    </Route>
    <Route
      path="token"
      element={<Navigate to="https://www.usebraintrust.com/btrust-token" />}
    />
    {RhfTestRoutes}
    {SETTINGS.NFT_MINTING_ENABLED ? NFTRoutes : null}
    <Route path="confirm" element={<ConfirmPage />} />
    <Route path="429" element={<TooManyRequestsPage />} />
    <Route path="page-not-found" element={<PageNotFoundPage />} />
    <Route path="access-denied" element={<AccessDenied />} />
    <Route path="*" element={<UnknownRoute to="/page-not-found" />} />
  </Route>
);

// additionalContext represents context part that have to be included INSIDE router
const router = (additionalContext: (context: JSX.Element) => JSX.Element) =>
  createBrowserRouter(createRoutesFromElements(routes(additionalContext)), {
    window,
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  });

export default router;
