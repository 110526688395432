import { useUser } from "@js/apps/common/hooks/use-user";
import { openOTPDisabledWarningModal } from "@js/apps/settings/components/otp-auth";
import { useAppSelector } from "@js/hooks/redux";

import { openChoosePayoutMethodModal } from "../components/choose-payout-method-modal";

export const useOpenChooseWithdrawalMethodModal = () => {
  const user = useUser();
  const creatingWithdrawalMethod = useAppSelector(
    (state) => state.withdrawal.creatingWithdrawalMethod,
  );
  const onChooseWithdrawalMethod = () => {
    if (!user?.is_otp_enabled) {
      return openOTPDisabledWarningModal();
    }

    openChoosePayoutMethodModal();
  };

  return { onChooseWithdrawalMethod, creatingWithdrawalMethod };
};
