import type { MouseEvent } from "react";
import cs from "classnames";

import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";

import { useAttachmentRenameInput } from "./use-attachement-rename-input";

import styles from "./styles.module.scss";

type AttachmentRenameInputProps = {
  loading: boolean;
  value: string;
  onRename?: (newName: string) => Promise<unknown>;
  onDelete?: () => void;
};

export const AttachmentRenameInput = ({
  loading,
  value,
  onRename,
  onDelete,
}: AttachmentRenameInputProps) => {
  const {
    isEditable,
    isSaving,
    _value,
    extension,
    inputRef,
    errorMessage,
    handleRename,
    onChange,
    onClick,
    onKeyDown,
    toggleEditable,
  } = useAttachmentRenameInput({
    value,
    onRename,
  });

  if (loading) {
    return (
      <Typography
        component="p"
        sx={{
          padding: "8px 16px",
          borderRadius: "8px",
          background: "var(--yellow)",
          width: "100%",
        }}
      >
        Uploading...
      </Typography>
    );
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <ClickAwayListener onClickAway={handleRename}>
        <TextField
          variant="outlined"
          value={isEditable ? _value : `${_value}${extension}`}
          onChange={onChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          disabled={!isEditable || isSaving}
          inputRef={inputRef}
          autoFocus
          fullWidth
          className={cs(styles.attachmentField, {
            [styles.attachmentFieldNoRename]: !onRename,
          })}
          InputProps={{
            endAdornment: (
              <Adornment
                isSaving={isSaving}
                isEditable={isEditable}
                extension={extension}
                onRename={onRename}
                onDelete={onDelete}
                toggleEditable={toggleEditable}
              />
            ),
          }}
        />
      </ClickAwayListener>
      {errorMessage && (
        <Typography
          component="span"
          variant="paragraph"
          color="negative"
          size="small"
          marginTop="6px"
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

type AdornmentProps = {
  isSaving: boolean;
  isEditable: boolean;
  extension: string;
  onRename?: (newName: string) => Promise<unknown>;
  onDelete?: () => void;
  toggleEditable: (_e: MouseEvent<HTMLButtonElement>) => void;
};

const Adornment = ({
  isSaving,
  isEditable,
  extension,
  onRename,
  onDelete,
  toggleEditable,
}: AdornmentProps) => {
  return (
    <InputAdornment position="end">
      {isSaving && (
        <Typography component="span" size="small">
          Saving...
        </Typography>
      )}
      {!!onRename && (
        <>
          {isEditable && <span className={styles.extension}>{extension}</span>}
          <button
            type="button"
            onClick={toggleEditable}
            onKeyDown={(e) => e.stopPropagation()}
            className="btn-reset pointer"
            disabled={isSaving}
            style={{
              textDecoration: "underline",
              marginLeft: "10px",
            }}
          >
            <Typography component="span" size="small">
              {isEditable ? "Cancel" : "Rename"}
            </Typography>
          </button>
        </>
      )}
      {!!onDelete && (
        <IconButton
          type="button"
          onClick={onDelete}
          onKeyDown={(e: { key: string }) => e.key === "Enter" && onDelete()}
          variant="transparent"
          aria-label="Delete file"
          sx={{
            mr: -1,
            ml: 2,
            "&.MuiButtonBase-root": {
              p: "6px",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </InputAdornment>
  );
};
