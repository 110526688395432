import { useEffect, useRef, useState } from "react";
import { throttle } from "underscore";

import { Box } from "@hexocean/braintrust-ui-components";
import { useAccountType } from "@js/apps/common/hooks";
import { useAirAiTopBar } from "@js/apps/dashboard/components/air-ai-bar/hooks/use-air-ai-top-bar";
import { useShowCompleteProfileTopBar } from "@js/apps/dashboard/hooks/use-show-complete-profile-top-bar";
import { NavigationProfileCompletionNudgeCard } from "@js/apps/freelancer/components/profile-completion-nudge";
import { Logo } from "@js/components/logo";

import {
  AI_INTERVIEWS_TOP_BAR_HEIGHT,
  COMMON_Z_INDEX,
  HEADER_HEIGHT,
  PROFILE_COMPLETION_TOP_BAR_HEIGHT,
  SIDE_MENU_MIN_WIDTH,
} from "../../constants";
import { getTopBarVisiblePartHeight } from "../../helpers";
import { useHandleLeftColumnWidth } from "../../hooks/use-handle-left-column-width";
import { HelpCenterLink } from "../help-center-link";

import styles from "./styles.module.scss";

type LeftColumnProps = {
  sidebar: JSX.Element;
  bgcolor?: string;
};

export const LeftColumn = ({ sidebar, bgcolor }: LeftColumnProps) => {
  const elRef = useRef<HTMLElement>(null);
  useHandleLeftColumnWidth(elRef);
  const { offsetTop } = useManageTopBarOffset();

  const isBgBlack = bgcolor === "var(--black)";

  return (
    <Box
      ref={elRef}
      zIndex={COMMON_Z_INDEX}
      display="flex"
      minWidth={SIDE_MENU_MIN_WIDTH}
      flexShrink={0}
      flexDirection="column"
      bgcolor={bgcolor}
    >
      <Box
        position="sticky"
        top={0}
        height={HEADER_HEIGHT}
        pl={3.5}
        display="flex"
        alignItems="center"
        flexShrink={0}
      >
        <Logo variant={isBgBlack ? "full-white" : "full-black"} />
      </Box>
      <Box
        className={styles.sidebar}
        position="sticky"
        top={HEADER_HEIGHT}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ height: `calc(100vh - ${HEADER_HEIGHT}px - ${offsetTop}px)` }}
      >
        {sidebar}
        <Box display="flex" flexDirection="column" gap={2}>
          <NavigationProfileCompletionNudgeCard />
          <HelpCenterLink />
        </Box>
      </Box>
    </Box>
  );
};

const useManageTopBarOffset = () => {
  const { accountType } = useAccountType();
  const { showCompleteProfileTopBar } = useShowCompleteProfileTopBar();
  const { showTopBar } = useAirAiTopBar();

  const getTopBarElement = () => {
    switch (accountType) {
      case ENUMS.AccountType.FREELANCER:
        return {
          isElementVisible: showCompleteProfileTopBar,
          elementHeight: PROFILE_COMPLETION_TOP_BAR_HEIGHT,
        };
      case ENUMS.AccountType.EMPLOYER:
        return {
          isElementVisible: showTopBar,
          elementHeight: AI_INTERVIEWS_TOP_BAR_HEIGHT,
        };
      default:
        return {
          isElementVisible: false,
          elementHeight: 0,
        };
    }
  };

  const topBarElement = getTopBarElement();

  return useTopBarOffset(topBarElement);
};

type UseTopBarOffsetParams = {
  isElementVisible: boolean;
  elementHeight: number;
};

const useTopBarOffset = ({
  isElementVisible,
  elementHeight,
}: UseTopBarOffsetParams) => {
  const [windowScrollTop, setWindowScrollTop] = useState(0);

  useEffect(() => {
    if (!isElementVisible) {
      return;
    }

    const handleScroll = throttle(
      () => {
        const position = window.scrollY;
        const trackedWindowScrollTop =
          position >= elementHeight ? elementHeight : position;

        setWindowScrollTop(trackedWindowScrollTop);
      },
      100,
      { trailing: true },
    );

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isElementVisible, elementHeight]);

  const offsetTop = getTopBarVisiblePartHeight({
    scrollOffset: windowScrollTop,
    isElementVisible,
    elementHeight,
  });

  return { offsetTop };
};
