import { useEmailConfirmationModal } from "@js/apps/dashboard/hooks/use-email-confirmation-modal";
import {
  useShowPurchaseUpgrade,
  useShowSuccessFiatPurchaseUpgrade,
} from "@js/hooks/purchase-upgrade";

import { usePollWalletStatus } from "../on-ramp/hooks/use-poll-wallet-status";
import { useShowPendingOnrampTransaction } from "../on-ramp/hooks/use-show-pending-onramp-transaction";
import WithdrawalMethodWarning from "../withdrawal/components/withdrawal-method-warning";

import { useProfileCompletionSuccess } from "./hooks/use-profile-completion-success";

const pathToShowConfirmationModal = /^\/talent\/dashboard\/welcome\/?$/;
export const FreelancerGlobalProvider = () => {
  useEmailConfirmationModal({
    pathToShowConfirmationModal,
  });
  useProfileCompletionSuccess();
  usePollWalletStatus();
  useShowPendingOnrampTransaction();
  useShowPurchaseUpgrade();
  useShowSuccessFiatPurchaseUpgrade();

  return (
    <>
      <WithdrawalMethodWarning />
    </>
  );
};
