import { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { Box } from "@hexocean/braintrust-ui-components";
import { CantManageMessage } from "@js/apps/jobs/components";
import { useIdParam } from "@js/hooks/use-id-param";
import { NavLayoutContainer } from "@js/layouts/app";

import { useEditJob } from "../../apps/create-job/hooks/edit-job";
import { JobInterviewLoader } from "../../components/job-interview-loader";
import { InterviewQuestionsHeader } from "../../components/job-interview-questions-header";
import { InterviewQuestionsForm } from "../../forms/interview-questions";
import { useAiInterviewQuestion } from "../../hooks/ai-interview-question";
import { useAIRInterview } from "../../hooks/use-air-interview";
import { JobInterviewLayout } from "../../layout/job-interviews";

import style from "./style.module.scss";

export const InterviewQuestionsPage = () => {
  const jobId = useIdParam();
  const { showAIRInterview } = useAIRInterview();

  if (!jobId || !showAIRInterview) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  return (
    <JobInterviewLayout>
      <InterviewQuestionsPageContent />
    </JobInterviewLayout>
  );
};

const InterviewQuestionsPageContent = () => {
  const { job, loading, ...formProps } = useEditJob();
  const { isGeneratingQuestions } = useAiInterviewQuestion(job);
  const { state } = useLocation();

  const isLoading = useMemo(
    () => loading || isGeneratingQuestions,
    [loading, isGeneratingQuestions],
  );

  const customContent = useMemo(() => {
    if (isLoading || !job) {
      return (
        <Box className={style.interviewLoading}>
          <JobInterviewLoader
            text="Creating your AI Interview"
            subtext="Generating your question suggestions..."
          />
        </Box>
      );
    }

    if (!job.user_can_manage) {
      return (
        <CantManageMessage
          title="You can't manage talent interviews in this job."
          to={`/jobs/${job?.id}`}
        />
      );
    }

    return null;
  }, [isLoading, job]);

  const header = useMemo(() => {
    const backLink = state?.prevPath ?? `/jobs/${job?.id}/interviews`;
    return (
      <NavLayoutContainer className={style.interviewHeader}>
        <InterviewQuestionsHeader
          backLink={backLink}
          isLoading={isLoading}
          userCanManage={!!job?.user_can_manage}
        />
      </NavLayoutContainer>
    );
  }, [isLoading, job, state]);

  return customContent ? (
    <InterviewQuestionsForm header={header} customContent={customContent} />
  ) : (
    <InterviewQuestionsForm header={header} {...formProps} />
  );
};
