import React from "react";
import { Outlet } from "react-router-dom";

import {
  Box,
  Loader,
  Pagination,
  PaginationWrapper,
} from "@hexocean/braintrust-ui-components";
import {
  FiltersClearAllButton,
  ResultsCount,
} from "@js/apps/common/components/filters/components";
import { SEARCH_FILTER_DATA_TYPES } from "@js/apps/common/components/filters/constants";
import { SearchFilter } from "@js/apps/common/components/filters/fields";
import { SearchFilterLocationContext } from "@js/apps/common/context/search-filter-location-context";
import {
  CantManageMessage,
  EditJobRequirementsCTA,
  FilteringActions,
  JobManagementHeader,
  JobManagementNav,
} from "@js/apps/jobs/components";
import { RefetchBidContextProvider } from "@js/apps/jobs/context/refetch-bids-context";
import { useCanManageJobsOnBehalfOfClient } from "@js/apps/jobs/hooks";
import { RouterLink } from "@js/components/link";

import {
  BidListActionBar,
  BulkSelectActions,
} from "../../apps/bids/components";
import { VirtualizedJobBidList } from "../../apps/bids/components/virtualized-job-bid-list";
import { useJobBids } from "../../apps/bids/hooks/job-bids";
import { BidsLoadingStateTalentsWithSmartMatching } from "../../components/loading-state-talents-with-smart-matching";
import { JobManagement } from "../../layout";

import style from "./style.module.scss";

export const JobBidsPageContent = React.memo(({ jobId }: { jobId: number }) => {
  const {
    loading,
    jobBidsLoading,
    jobBidsFetching,
    job,
    bidList,
    refetchBidList,
    areFiltersDefault,
    isShowingHiddenBids,
    totalBidsCount,
    isLastPage,
    resetFilters,
  } = useJobBids(jobId);
  const canManageJobsOnBehalfOfClient = useCanManageJobsOnBehalfOfClient();

  if (loading) return <Loader />;

  if (!job?.user_can_manage) {
    return (
      <div data-testid="managing-bids-not-allowed">
        <CantManageMessage
          title="You can't manage applications in this job."
          to={`/jobs/${job?.id}`}
        />
      </div>
    );
  }

  const showEditJobRequirementCTA =
    (!!bidList.length || (!bidList.length && !areFiltersDefault)) && isLastPage;

  return (
    <JobManagement.ContentLayout
      header={<JobManagementHeader job={job} />}
      main={
        <SearchFilterLocationContext.Provider value="bid_search_box">
          <RefetchBidContextProvider
            loadingBids={jobBidsLoading}
            fetchingBids={jobBidsFetching}
            refetchBidList={refetchBidList}
          >
            <JobManagement.ContentBox className={style.jobBidsPageMain}>
              <Box className={style.jobBidsListSectionHeader}>
                <JobManagementNav
                  job={job}
                  totalBidsCount={totalBidsCount}
                  totalInterviewingBidsCount={
                    job.bids_count?.interviewing_and_active_bids_count || 0
                  }
                />
                <Box sx={{ maxWidth: "200px", flexShrink: 0 }}>
                  <SearchFilter
                    className={style.jobBidsSearchField}
                    dataType="BID"
                    placeholder="Search by name"
                  />
                </Box>
              </Box>

              <BidListActionBar bids={bidList}>
                <FilteringActions
                  isMatcher={canManageJobsOnBehalfOfClient}
                  isAiInterviewEnabled={job.is_ai_interview_enabled}
                  jobType={job.job_type}
                  jobId={job.id}
                />
                <BulkSelectActions
                  job={job}
                  bidList={bidList}
                  isShowingHiddenBids={isShowingHiddenBids}
                />
              </BidListActionBar>

              {!areFiltersDefault && (
                <Box display="flex" alignItems="center">
                  <ResultsCount
                    dataType={SEARCH_FILTER_DATA_TYPES.talent}
                    count={totalBidsCount}
                    wholeTextBold
                  />
                  <FiltersClearAllButton onClick={resetFilters} />
                </Box>
              )}

              {jobBidsLoading ? (
                <BidsLoadingStateTalentsWithSmartMatching />
              ) : (
                <>
                  <VirtualizedJobBidList
                    job={job}
                    bidList={bidList}
                    areFiltersDefault={areFiltersDefault}
                  />
                  {showEditJobRequirementCTA && (
                    <EditJobRequirementsCTA job={job} />
                  )}
                  <PaginationWrapper mt={0}>
                    <Pagination
                      RouterLink={RouterLink}
                      count={totalBidsCount}
                      perPage={SETTINGS.EMPLOYER_BIDS_JOBS_PER_PAGE}
                    />
                  </PaginationWrapper>
                  <Outlet />
                </>
              )}
            </JobManagement.ContentBox>
          </RefetchBidContextProvider>
        </SearchFilterLocationContext.Provider>
      }
    />
  );
});
