import { type MouseEvent } from "react";
import cs from "classnames";

import {
  Avatar,
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { talentNamePostClicked } from "@js/apps/give-and-get-help/actions";
import {
  CommentContext,
  usePostContext,
} from "@js/apps/give-and-get-help/context";
import {
  CommentReplyFormWrapper,
  ReplyForm,
} from "@js/apps/give-and-get-help/forms/reply-form";
import { useHandlePostCommentActions } from "@js/apps/give-and-get-help/hooks";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";
import type { PostComment } from "@js/types/give-and-get-help";

import { ActionsMenu } from "../../actions-menu";
import { GghServicesNudge } from "../../ggh-services-nudge";
import { PostAttachments } from "../../post-attachments";
import { PostOrCommentDetails } from "../../post-comment-details";
import { PostCommentText } from "../../post-comment-text";
import { ProfileCard } from "../../profile-card";
import { ProfileCardPopover } from "../../profile-card-popover";
import { AddHelpServiceTip } from "../add-help-service-tip/add-help-service-tip";
import { CommentOfferBox } from "../comment-offer-box";
import type { CommentsList } from "../comments-list";

import { useComment } from "./use-comment/use-comment";
import { CommentContentWrapper } from "./comment-content-wrapper";
import { CommentInteractsSection } from "./comment-interacts-section";
import { useCommentReplies } from "./use-comment-replies";

import styles from "../styles.module.scss";

type CommentListType = typeof CommentsList;

export type CommentProps = {
  postId: number;
  comment: PostComment;
  isFirstLevel?: boolean;
  isLastInThread?: boolean;
  restrictTogglingReplies?: boolean;
  toggleReplyBox?: (postId: number) => void;
  CommentsList: CommentListType;
  isDisplayingAllPostComments?: boolean;
  onExpandSingleComment?: (comment: PostComment) => void;
};

export const Comment = ({
  postId,
  comment,
  isFirstLevel = false,
  isLastInThread = false,
  restrictTogglingReplies = false,
  toggleReplyBox,
  CommentsList,
}: CommentProps) => {
  const { isPublic, postData, onViewCommentClick } = usePostContext();
  const {
    shouldHideComments,
    replies,
    isLoadingReplies,
    expandReplies,
    hideReplies,
    repliesToDisplay,
  } = useCommentReplies({
    comment,
    postId: postData.id,
  });

  const {
    toggleReply,
    handleEditClick,
    handleEditReplyClose,
    shouldDisplayPostAttachments,
    isReplyOpen,
    isCommentEdit,
    commentContextValue,
    commentLinksMetadata,
    commentToReplyInThread,
    commentUrl,
    isPostSignupPromptDisplayed,
    ref,
    isOwnComment,
    editCommentFormId,
    commentFormId,
    scrollCommentReplyFormIntoView,
  } = useComment({
    postData,
    comment,
    replies,
  });

  const dispatch = useAppDispatch();
  const { handleCollapsePost, handleExpandPost, handleUserNameClicked } =
    useHandlePostCommentActions({
      entity: {
        ...comment,
        slot_position: postData.slot_position,
      },
      entityRef: ref,
    });
  const isSmall = useMediaQuery("sm");

  const shouldDisplayThreadBeginningVisualizer =
    isFirstLevel && !!repliesToDisplay.length;

  const shouldDisplayCommentsList = !!repliesToDisplay.length;

  const handleUserAvatarClicked = () => {
    dispatch(talentNamePostClicked({ talent_id: comment.freelancer.id }));
  };

  const handleToggleReply = (id: number) => {
    toggleReply(id);
    expandReplies();
  };

  const handleReplyClick = () => {
    onViewCommentClick?.(postData);
    if (!isFirstLevel && toggleReplyBox) {
      toggleReplyBox(comment.id);
    } else {
      handleToggleReply(comment.id);
    }
  };

  const shouldShowAddReplyForm =
    !isLoadingReplies && isReplyOpen && isFirstLevel;

  const repliesCount = comment.comments_count ?? comment?.comments?.length ?? 0;

  const shouldShowViewMoreRepliesButton =
    !isPublic && !!repliesCount && shouldHideComments;

  const handleViewRepliesClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    onViewCommentClick?.(postData);
    expandReplies();
  };

  return (
    <CommentContext.Provider value={commentContextValue}>
      <Box
        className={cs(styles.feedComment, {
          [styles.feedCommentPublicLast]:
            isPublic && isPostSignupPromptDisplayed,
        })}
        id={`${comment.id}`}
        data-testid={`comment-${comment.id}`}
        ref={ref}
      >
        <Box className={styles.feedCommentsSectionAvatarWrapper}>
          <ProfileCardPopover
            anchor={
              <Button
                className={styles.feedCommentAvatar}
                variant="transparent"
                RouterLink={RouterLink}
                href={`/talent/${comment.freelancer.id}/`}
                target="_blank"
                onClick={handleUserAvatarClicked}
              >
                <Avatar
                  src={comment.freelancer.user}
                  size={isSmall ? "xs" : "md"}
                />
              </Button>
            }
            renderContent={({ updatePosition }) => (
              <ProfileCard
                talentId={comment.freelancer.id}
                avatarData={comment.freelancer.user}
                firstName={comment.freelancer.user.first_name}
                onDataLoad={updatePosition}
              />
            )}
          />

          {shouldDisplayThreadBeginningVisualizer && (
            <Box
              className={styles.feedCommentsSectionThreadVisualizerTopComment}
            />
          )}

          {isLastInThread && !isFirstLevel && (
            <Box
              className={styles.feedCommentsSectionThreadVisualizerLastComment}
            />
          )}
        </Box>
        <Box sx={{ pl: { xs: 1.5, md: 2 }, minWidth: 0 }}>
          <CommentContentWrapper
            onHideReplies={hideReplies}
            isShowingReplies={shouldDisplayCommentsList}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <PostOrCommentDetails
                firstName={comment.freelancer.user.first_name}
                lastName={comment.freelancer.user.last_name}
                role={comment.freelancer.role}
                id={comment.freelancer.id}
                avatarData={comment.freelancer.user}
                introductionHeadline={
                  comment.freelancer.user.introduction_headline
                }
                created={comment.created}
                variant="comment"
                onUserNameClick={handleUserNameClicked}
                shouldDisplayAdminBadge={false}
              />
            </Box>
            <PostCommentText
              comment={comment}
              onExpand={handleExpandPost}
              onCollapse={handleCollapsePost}
            />
          </CommentContentWrapper>
          {!postData.is_celebratory_post && !!postData.category && (
            <AddHelpServiceTip
              postBudget={postData.budget}
              postCategory={postData.category}
              postFreelancer={postData.freelancer}
              comment={comment}
            />
          )}
          <Box>
            <CommentInteractsSection
              commentData={comment}
              slotPosition={postData.slot_position}
              restrictTogglingReplies={restrictTogglingReplies}
              onReply={handleReplyClick}
            />
          </Box>

          {shouldDisplayPostAttachments && (
            <Box mt={2} overflow="hidden">
              <PostAttachments
                attachments={[...commentLinksMetadata, ...comment.attachments]}
              />
            </Box>
          )}
          {comment.help_offer !== null && (
            <Box mt={1} maxWidth="fit-content">
              <CommentOfferBox {...comment} />
              {isOwnComment && !!postData.category && (
                <GghServicesNudge
                  postCategoryId={postData.category.id}
                  comment={comment}
                />
              )}
            </Box>
          )}
          {shouldShowViewMoreRepliesButton && (
            <Typography
              role="button"
              variant="label"
              size="small"
              component="p"
              color="blue"
              sx={{ cursor: "pointer", mt: 1, width: "fit-content" }}
              onClick={handleViewRepliesClick}
            >
              View {repliesCount} {repliesCount > 1 ? "replies" : "reply"}
            </Typography>
          )}
        </Box>

        <Box alignSelf="flex-start">
          <ActionsMenu
            entity={{ ...comment, slot_position: postData.slot_position }}
            urlToCopy={commentUrl}
            type="comment"
            handleEditClick={handleEditClick}
          />
        </Box>

        <Box className={styles.feedCommentInternalList}>
          {isCommentEdit && (
            <ReplyForm
              data-testid="edit-comment-form"
              type="edit-comment"
              id={comment.id}
              formId={editCommentFormId}
              expanded
              onCloseReply={handleEditReplyClose}
              commentToEdit={comment}
              isCelebratoryPost={postData.is_celebratory_post}
            />
          )}

          {shouldDisplayCommentsList && (
            <CommentsList
              postId={postId}
              comments={repliesToDisplay}
              toggleReplyBox={handleToggleReply}
            />
          )}

          {shouldShowAddReplyForm && (
            <CommentReplyFormWrapper
              className={styles.feedCommentsSectionReplyFormWrapper}
              commentToReply={commentToReplyInThread}
              scrollCommentReplyFormIntoView={scrollCommentReplyFormIntoView}
            >
              <ReplyForm
                type="reply-to-comment"
                formId={commentFormId}
                commentToReply={commentToReplyInThread}
                id={comment.id}
                expanded
                onCloseReply={handleToggleReply}
                isCelebratoryPost={postData.is_celebratory_post}
              />
            </CommentReplyFormWrapper>
          )}

          {isLoadingReplies && (
            <Box sx={{ position: "relative", height: "5rem" }}>
              <Loader centered />
            </Box>
          )}
        </Box>
      </Box>
    </CommentContext.Provider>
  );
};
