import PoweredByCoinbase from "@static/on-ramp/poweredByCoinbase.svg";

import {
  Box,
  Button,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowRightTopIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

import { useActionButtons } from "./hooks/use-action-buttons";

export const ActionButtons = () => {
  const {
    costInFiat,
    isLoadingCoinbase,
    isLoadingReserveUpgrade,
    isLoadingPurchaseUpgrade,
    isSufficientBtrst,
    isWalletReady,
    onOpenCoinbase,
    onPayBTRST,
    onPayFiat,
  } = useActionButtons();

  return (
    <Box pb={{ xs: 2.5, md: 4 }}>
      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        mb={isSufficientBtrst ? 3.5 : 2.75}
      >
        {isSufficientBtrst ? (
          <Button
            disabled={isLoadingPurchaseUpgrade}
            variant="primary"
            shape="squared"
            onClick={onPayBTRST}
          >
            Pay
          </Button>
        ) : (
          <Tooltip
            title="This action is currently unavailable. We are in the process of creating a wallet for you, which should be ready in a few minutes."
            placement="top"
            disabled={isWalletReady}
          >
            <div>
              <Button
                fullWidth
                disabled={
                  isLoadingReserveUpgrade || isLoadingCoinbase || !isWalletReady
                }
                variant="primary"
                shape="squared"
                onClick={onOpenCoinbase}
                endIcon={<ArrowRightTopIcon />}
              >
                Add BTRST to my wallet
              </Button>
            </div>
          </Tooltip>
        )}

        <Button variant="black-outlined" shape="squared" onClick={onPayFiat}>
          Or pay {costInFiat}
        </Button>
      </Box>
      {!isSufficientBtrst && (
        <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
          <PoweredByCoinbase />
        </Box>
      )}
      <Box
        sx={{
          "& a": {
            color: "var(--link)",
            textDecoration: "none",
          },
        }}
      >
        <Typography
          component="div"
          variant="paragraph"
          size="small"
          textAlign="center"
        >
          By confirming payment you accept our{" "}
          <Typography
            component="a"
            size="small"
            href={SETTINGS.PRIVACY_DOC_URL}
            target="_blank"
            className="policy-field-link"
            rel="noreferrer"
            RouterLink={RouterLink}
          >
            Privacy Policy
          </Typography>
          , and{" "}
          <Typography
            component="a"
            size="small"
            href={SETTINGS.TERMS_DOC_URL}
            target="_blank"
            className="policy-field-link"
            rel="noreferrer"
            RouterLink={RouterLink}
          >
            Payments Terms & Conditions
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
