import React from "react";
import cs from "classnames";
import { omit } from "underscore";

import { Typography } from "@hexocean/braintrust-ui-components";
import { useSubscribableNotifications } from "@js/apps/settings/hooks/use-subscribable-notifications";
import { Spacer } from "@js/components/spacer";
import type { NotificationSetting } from "@js/types/notifications";

import { NotificationSwitch } from "../notifications-section/notification-switch";
import { NotificationToggleButton } from "../notifications-section/notification-toggle-button";

import styles from "./styles.module.scss";

type NotificationsSectionProps = {
  title: string;
  notifications: NotificationSetting[];
};

export const NotificationsSection = ({
  title,
  notifications,
}: NotificationsSectionProps) => {
  const isSingleNotification = notifications.length === 1;

  return (
    <div
      className={cs(styles.notificationsSection, {
        [styles.notificationsSectionSingle]: isSingleNotification,
      })}
      data-testid={`${title}-notifications-section`}
    >
      <Typography
        component="span"
        variant="title"
        size="small"
        fontWeight={400}
        className={styles.notificationsSectionTitle}
      >
        {title}
      </Typography>
      <div className={styles.notificationsSectionList}>
        {notifications.map((notificationSetting) => {
          const isToggle = !!notificationSetting?.options;

          return (
            <React.Fragment key={notificationSetting.notification}>
              <div
                className={cs(styles.notificationsSectionRow, {
                  [styles.notificationsSectionRowToggle]: isToggle,
                })}
              >
                <Typography component="span" variant="label" size="medium">
                  {notificationSetting.label}
                </Typography>
                <div className={styles.notificationsSectionInputEnd}>
                  {isToggle ? (
                    <NotificationToggleButton
                      notification={notificationSetting}
                    />
                  ) : (
                    <NotificationSwitch notification={notificationSetting} />
                  )}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export const JobsNotificationsSection = ({
  title,
  notifications,
}: NotificationsSectionProps) => {
  const {
    notifications: _notifications,
    anySubscribable,
    subscribableNotifications,
  } = useSubscribableNotifications({ notifications });

  return (
    <div className={styles.notificationsSection}>
      <Typography
        component="span"
        variant="title"
        size="small"
        fontWeight={400}
        className={styles.notificationsSectionTitle}
        marginTop={6.5}
      >
        {title}
      </Typography>
      <div className={styles.notificationsSectionList}>
        {!!anySubscribable && (
          <div
            className={cs(styles.notificationsSectionColumnLabels, {
              [styles.withAdditionalToggle]: anySubscribable,
            })}
          >
            <Spacer width="33%" />
            <Typography
              component="span"
              variant="label"
              size="medium"
              className={styles.notificationsSectionColumnLabelsHeader}
            >
              Jobs I own
            </Typography>
            <Typography
              component="span"
              variant="label"
              size="medium"
              className={styles.notificationsSectionColumnLabelsHeader}
            >
              Jobs I subscribe to
            </Typography>
          </div>
        )}
        {_notifications.map((notificationSetting) => (
          <div
            key={notificationSetting.notification}
            className={cs(styles.notificationsSectionRow, {
              [styles.withAdditionalToggle]: anySubscribable,
            })}
          >
            <Typography component="span" variant="label" size="medium">
              {notificationSetting.label}
            </Typography>
            <div className={styles.notificationsSectionInputEnd}>
              <NotificationSwitch
                notification={omit(notificationSetting, "subscribableIdx")}
              />
            </div>
            {notificationSetting.subscribableIdx !== -1 ? (
              <div className={styles.notificationsSectionInputEnd}>
                <NotificationSwitch
                  notification={
                    subscribableNotifications[
                      notificationSetting.subscribableIdx
                    ]
                  }
                />
              </div>
            ) : (
              <Spacer className="test" width="78px" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
