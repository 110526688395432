import React from "react";

import { useEmailConfirmationModal } from "@js/apps/dashboard/hooks/use-email-confirmation-modal";
import { useSetWelcomeModalParam } from "@js/apps/employer/hooks/use-employer-welcome-modal";

import { AutoBlockUserWarningModalProvider } from "./components/auto-block-warning-modal";
import { CanViewInvoicesProvider } from "./components/can-view-invoices-provider";
import { CurrentOfferDepositProvider } from "./components/current-offer-deposit-provider";

const pathsToHideConfirmationModal = [
  /^\/onboarding\//,
  /\/auth\/logout/,
  /^\/verify_email/,
];

export const EmployerGlobalProvider = () => {
  const setSearchParam = useSetWelcomeModalParam();

  useEmailConfirmationModal({
    pathsToHideConfirmationModal,
    callback: setSearchParam,
  });

  return (
    <>
      <CanViewInvoicesProvider />
      <AutoBlockUserWarningModalProvider />
      <CurrentOfferDepositProvider />
    </>
  );
};
