import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { ErrorSVGIcon } from "@hexocean/braintrust-ui-components/Icons";
import { BasicAccordion } from "@js/apps/on-ramp/components/onramp-modal/accordion";

import { usePurchaseSummary } from "./hooks/use-purchase-summary";

export const PurchaseSummary = () => {
  const { formattedRequiredTotalBtrst, isSufficientBtrst, product } =
    usePurchaseSummary();

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      bgcolor="var(--soft-violet)"
      borderRadius={2}
      overflow="hidden"
      minHeight="max-content"
    >
      <Box px={4} pt={4} pb={3.5}>
        <Box>
          <Typography
            component="div"
            variant="label"
            size="medium"
            fontWeight={500}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            sx={{ wordBreak: "keep-all" }}
          >
            <Box flex={1}>{product.name}</Box>
            <Typography
              component="div"
              variant="label"
              size="small"
              display="flex"
              gap={0.5}
              alignItems="center"
            >
              <img
                width="auto"
                height="18px"
                src={`${SETTINGS.STATIC_URL}logo/logo-symbol.svg`}
                alt="logo"
              />
              {product.price} BTRST
            </Typography>
          </Typography>
          <Typography component="p" variant="paragraph" size="small">
            {product.packageInfo}
          </Typography>
        </Box>
        <Box
          bgcolor="var(--grey-4)"
          height="1px"
          borderRadius={1}
          mt={3.5}
          mb={2.25}
        />

        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ wordBreak: "keep-all" }}
        >
          <Typography
            flex={1}
            component="p"
            variant="label"
            size="medium"
            fontWeight={500}
          >
            Total due
          </Typography>
          <Typography
            component="div"
            variant="label"
            size="large"
            fontWeight={500}
            display="flex"
            gap={0.5}
            alignItems="center"
          >
            <img
              width="auto"
              height="20px"
              src={`${SETTINGS.STATIC_URL}logo/logo-symbol.svg`}
              alt="logo"
            />
            {product.price} BTRST
          </Typography>
        </Box>
        {!isSufficientBtrst && (
          <Box
            display="flex"
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={{ xs: "flex-start", md: "center" }}
            gap={0.75}
            bgcolor="var(--medium-red)"
            padding="8px 5px"
            borderRadius={1}
            mt={2.25}
          >
            <ErrorSVGIcon sx={{ color: "var(--negative-2)" }} />
            <Typography
              component="div"
              variant="paragraph"
              size="small"
              alignItems={{ xs: "flex-start", md: "center" }}
              flex={{ xs: "1", md: "unset" }}
            >
              You need an additional{" "}
              <Box fontWeight={500} display="contents">
                <Box display="inline" mr={0.5} sx={{ verticalAlign: "sub" }}>
                  <img
                    width="auto"
                    height="18px"
                    src={`${SETTINGS.STATIC_URL}logo/logo-symbol.svg`}
                    alt="logo"
                  />
                </Box>
                {formattedRequiredTotalBtrst} BTRST
              </Box>{" "}
              to complete this purchase.
            </Typography>
          </Box>
        )}
      </Box>

      {!isSufficientBtrst && (
        <Box marginTop="auto" bgcolor="#F1E7FF" py={1.5} px={2.5}>
          <Typography
            component="p"
            variant="label"
            size="medium"
            fontWeight={500}
            color="dark-violet"
            mb={1}
            ml={1.25}
          >
            Help
          </Typography>
          <Box
            sx={{
              "& .MuiAccordion-rounded.Mui-expanded": {
                backgroundColor: "#E2D5F3",
                borderRadius: "5px",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: 0,
              },
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              "& .MuiAccordionSummary-root": {
                minHeight: "fit-content",
                borderRadius: "5px",
                padding: "4px 10px",
                marginTop: "0px",
              },
              "& .MuiAccordionSummary-root:hover": {
                backgroundColor: "#E2D5F3",
              },
              "& .MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "fit-content",
                marginBottom: 0,
              },
              "& .MuiCollapse-root": {
                padding: "0 10px 4px",
              },
            }}
          >
            <BasicAccordion
              options={[
                {
                  title: "Why do we need to use Coinbase?",
                  body: "Coinbase is the largest online exchange that allows people to buy, sell, and trade cryptocurrencies, including BTRST, on the Blockchain. We chose Coinbase for it’s trusted reputation, secure platform, and easy-to-use interface that makes it ideal for beginner to advanced cryptocurrency traders.",
                },
                {
                  title: "Is it safe to use Coinbase?",
                  body: (
                    <>
                      <Typography component="p" size="small">
                        Coinbase is the most trusted crypto exchange:{" "}
                        <Typography component="a" size="small">
                          <a
                            href="https://www.coinbase.com/security"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://www.coinbase.com/security
                          </a>
                        </Typography>
                      </Typography>
                    </>
                  ),
                },
                {
                  title: "How will I know when my BTRST is available?",
                  body: (
                    <>
                      <Typography component="p" size="small">
                        We'll notify you when your BTRST is available. This
                        could take up to 3 hours. You can view your token
                        balance anytime in your Braintrust&nbsp;
                        <Typography component="a" size="small">
                          <a
                            href="https://app.usebraintrust.com/auth/login/?next=%2Ftalent%2Fdashboard%2Fmy_wallet%2F"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "underline" }}
                          >
                            Wallet.
                          </a>
                        </Typography>
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
