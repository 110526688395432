import { useEffect } from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { ErrorIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Modal } from "@js/components/modal";

import { openEnableOTPAuthModal } from "../enable-otp-modal";

import styles from "./styles.module.scss";

const OTP_DISABLED_WARNING_MODAL_ID = "otp-disabled-warning";

export const OTPDisabledWarningModalInstance = Modal(
  OTP_DISABLED_WARNING_MODAL_ID,
  {
    className: styles.modal,
    closeButton: false,
  },
);

type OTPDisabledWarningProps = {
  onSuccessAction?: (codes: string[]) => void;
};

const OTPDisabledWarning = ({ onSuccessAction }: OTPDisabledWarningProps) => {
  useEffect(() => {
    return () => OTPDisabledWarningModalInstance.close();
  }, []);

  return (
    <>
      <Typography
        component="h3"
        variant="title"
        fontWeight={400}
        size="small"
        className="mt0 mb+"
      >
        <ErrorIcon className="general-warning-icon" /> 2-Step Authentication
        Required
      </Typography>
      <Typography component="p">
        For security purposes, you must enable 2-Step Authentication before
        taking this action.
      </Typography>
      <Box
        display="flex"
        gap={2}
        className="mt+"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexDirection={{ xs: "column-reverse", sm: "row" }}
      >
        <Button
          variant="secondary"
          onClick={OTPDisabledWarningModalInstance.close}
        >
          Cancel
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            OTPDisabledWarningModalInstance.close();
            openEnableOTPAuthModal({ onSuccessAction });
          }}
        >
          Setup 2-Step Authentication
        </Button>
      </Box>
    </>
  );
};

export const openOTPDisabledWarningModal = (
  config?: OTPDisabledWarningProps,
) => {
  OTPDisabledWarningModalInstance.open({
    children: <OTPDisabledWarning {...config} />,
  });
};
