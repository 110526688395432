import { PROFILE_COMPLETION_STEPS } from "@js/apps/freelancer/components/profile-completion-sequence/const";
import type { ProfileCompletionAreasMap } from "@js/types/freelancer";

import styles from "./views/styles.module.scss";

export const MODULE_EDIT_MODAL_PROPERTIES = {
  closeButton: true,
  closeButtonProps: {
    variant: "tertiary",
    size: "x-small",
  },
  keepOnBackdropClick: true,
  className: styles.modal,
} as const;

export const TALENT_LOCATION = {
  talent_listing: "talent_listing",
  talent_search: "talent_search",
  legacy_talent_search: "legacy_talent_search",
} as const;

export const ADD_ANOTHER_ITEM = "ADD_ANOTHER_ITEM";

export const MAX_YEARS_OF_EXPERIENCE = 99;
export const MIN_YEARS_OF_EXPERIENCE = 0;

export const COMPLETION_AREA_TO_COMPONENT_TYPE_MAP: ProfileCompletionAreasMap<{
  type: EnumType<typeof PROFILE_COMPLETION_STEPS>;
}> = {
  [ENUMS.ProfileCompletionArea.FreelancerRoleCompletionDetail]: {
    type: PROFILE_COMPLETION_STEPS.ROLE,
  },
  [ENUMS.ProfileCompletionArea.FreelancerSkillCompletionDetail]: {
    type: PROFILE_COMPLETION_STEPS.SKILLS,
  },
  [ENUMS.ProfileCompletionArea.FreelancerSuperPowersCompletionDetail]: {
    type: PROFILE_COMPLETION_STEPS.SKILLS,
  },
  [ENUMS.ProfileCompletionArea.FreelancerWorkExperienceCompletionDetail]: {
    type: PROFILE_COMPLETION_STEPS.WORK_EXPERIENCE,
  },
  [ENUMS.ProfileCompletionArea.FreelancerFullNameCompletionDetail]: {
    type: PROFILE_COMPLETION_STEPS.ABOUT,
  },
  [ENUMS.ProfileCompletionArea.FreelancerLocationCompletionDetail]: {
    type: PROFILE_COMPLETION_STEPS.ABOUT,
  },
};
