import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  type PurchaseUpgradeParams,
  useConfirmStripeCheckoutUpgradeSessionMutation,
} from "@js/apps/auth/api";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { useUserWalletBalance } from "@js/apps/common/hooks/use-user-wallet-balance";
import { openInsightPurchaseModal } from "@js/apps/jobs/components/application-insight-purchase-modal";
import { usePreCheckBalanceUpgrade } from "@js/apps/jobs/components/application-onramp-modal/hooks";
import { openApplicationOnrampModal } from "@js/apps/jobs/components/application-onramp-modal/utils";
import type { OpenBidCreateSuccessModalArg } from "@js/apps/jobs/components/bid-create-success-modal/types";
import { openBoostModal } from "@js/apps/jobs/components/job-list-boost-modal/job-list-boost-modal";
import { openBoostSuccessModal } from "@js/apps/jobs/components/product-checkout-modal/btrst-checkout-form/boost-success-modal/boost-success-modal";
import { openSuccessPurchaseModal } from "@js/apps/jobs/components/product-checkout-modal/btrst-checkout-form/success-purchase-modal";
import { openProductDescriptionModal } from "@js/apps/jobs/components/product-description-modal";
import { useStripeFeatureFlag } from "@js/apps/jobs/hooks/stripe-feature-flag";
import { Snackbar } from "@js/components/snackbar";

import { useAppDispatch } from "../redux";

export type OnClickPurchaseUpgradeArg = {
  onSuccessPurchaseBtrst?: () => void;
  onSuccessPurchaseBoost?: () => void;
  postAppBoostProps?: OpenBidCreateSuccessModalArg;
};

const usePurchaseUpgrade = (
  upgradeType: PurchaseUpgradeParams["upgrade_type"],
) => {
  const { isSufficientBtrst } = usePreCheckBalanceUpgrade(upgradeType);
  const { showStripe } = useStripeFeatureFlag();

  const onClickPurchaseUpgrade = useCallback(
    (args?: OnClickPurchaseUpgradeArg) => {
      if (showStripe) {
        openProductDescriptionModal({ upgradeType });
      } else {
        if (isSufficientBtrst) {
          if (upgradeType === "boost") {
            openBoostModal({
              onSuccessPurchaseBoost: args?.onSuccessPurchaseBoost,
            });
          } else if (upgradeType === "insights") {
            openInsightPurchaseModal();
          } else {
            Snackbar.error("Couldn't open any upgrade modal");
          }
        } else {
          openApplicationOnrampModal({
            onSuccessPurchaseBtrst: args?.onSuccessPurchaseBtrst,
            upgradeType,
          });
        }
      }
    },
    [isSufficientBtrst, showStripe, upgradeType],
  );

  return {
    onClickPurchaseUpgrade,
  };
};

type UpgradeType = PurchaseUpgradeParams["upgrade_type"] | null;

const useShowPurchaseUpgrade = () => {
  const user = useUser();
  const { showStripe } = useStripeFeatureFlag();
  const { isFreelancer } = useAccountType();
  const { isLoading: loadingWalletBalance } = useUserWalletBalance();

  const { onClickPurchaseUpgrade: showInsightPurchase } =
    usePurchaseUpgrade("insights");

  const { onClickPurchaseUpgrade: showBoostPurchase } =
    usePurchaseUpgrade("boost");

  const [searchParams] = useSearchParams();

  const determineUpgradeType = useCallback(
    (params: URLSearchParams): UpgradeType => {
      if (params.get("purchase_insights") === "true") {
        return "insights";
      }
      if (params.get("ispurchasemodal") === "true") {
        return "boost";
      }
      return null;
    },
    [],
  );

  const handleStripeUpgrade = useCallback((upgradeType: UpgradeType) => {
    if (upgradeType) {
      openProductDescriptionModal({ upgradeType });
    }
  }, []);

  const handleNonStripeUpgrade = useCallback(
    (upgradeType: UpgradeType) => {
      switch (upgradeType) {
        case "insights":
          showInsightPurchase();
          break;
        case "boost":
          showBoostPurchase();
          break;
        default:
          // No action needed for unknown upgrade types
          break;
      }
    },
    [showInsightPurchase, showBoostPurchase],
  );

  useEffect(() => {
    const canShowUpgrade = !loadingWalletBalance && isFreelancer;
    if (!canShowUpgrade) {
      return;
    }

    const upgradeType = determineUpgradeType(searchParams);
    if (!upgradeType) {
      return;
    }

    const handleUpgrade = showStripe
      ? handleStripeUpgrade
      : handleNonStripeUpgrade;

    handleUpgrade(upgradeType);
  }, [
    isFreelancer,
    loadingWalletBalance,
    searchParams,
    showStripe,
    determineUpgradeType,
    handleStripeUpgrade,
    handleNonStripeUpgrade,
    user?.can_view_insights,
  ]);
};

const useShowSuccessFiatPurchaseUpgrade = () => {
  const dispatch = useAppDispatch();

  const { isFreelancer } = useAccountType();
  const [searchParams] = useSearchParams();
  const [confirmStripeCheckoutUpgradeSession] =
    useConfirmStripeCheckoutUpgradeSessionMutation();

  useEffect(() => {
    const openSucessFiatPurchaseUpgrade = async () => {
      const upgradeType = searchParams.get(
        "success_fiat_payment",
      ) as UpgradeType;
      const sessionId = searchParams.get("session_id");

      try {
        if (upgradeType && sessionId && isFreelancer) {
          await confirmStripeCheckoutUpgradeSession({
            upgrade_type: upgradeType,
            session_id: sessionId,
          });

          if (upgradeType === "boost") {
            openBoostSuccessModal(dispatch);
          } else if (upgradeType === "insights") {
            openSuccessPurchaseModal(dispatch);
          }
        }
      } catch {
        Snackbar.error("Couldn't confirm Fiat payment");
      }
    };

    openSucessFiatPurchaseUpgrade();
  }, [
    confirmStripeCheckoutUpgradeSession,
    dispatch,
    isFreelancer,
    searchParams,
  ]);
};

export {
  usePurchaseUpgrade,
  useShowPurchaseUpgrade,
  useShowSuccessFiatPurchaseUpgrade,
};
