import { Navigate, Route } from "react-router-dom";

import {
  FreelancerProfilePageAboutLazy,
  FreelancerProfilePageResumeLazy,
} from "../../views/lazy/lazy";
import { FreelancerProfilePage } from "../../views/profile";

export const FreelancerProfilePageRoutes = [
  <Route
    path=""
    key="freelancer_profile_page"
    element={<FreelancerProfilePage />}
  >
    <Route index element={<FreelancerProfilePageAboutLazy />} />
    <Route path="resume" element={<FreelancerProfilePageResumeLazy />} />
    <Route path="*" element={<Navigate to="/page-not-found" replace />} />
  </Route>,
];
