import type { ReactNode } from "react";
import cs from "classnames";

import type {
  BoxProps,
  TypographyProps,
} from "@hexocean/braintrust-ui-components";
import {
  Box,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Emoji } from "@js/components/emoji";
import type {
  IPost as PostProps,
  PostCategory,
} from "@js/types/give-and-get-help";
import { matchFontColorToBackgroundColor } from "@js/utils/match-color";

import type { FormatHelpOfferBudgetConfig } from "../../utils";
import { formatHelpOfferBudget } from "../../utils";
import { CategoryLabel } from "../category-and-budget-utils/category-label";

import styles from "./styles.module.scss";

type PostCategoryBadgeProps = {
  category: Omit<PostCategory, "budget_lower" | "budget_higher"> | null;
};

export const PostCategoryBadge = ({ category }: PostCategoryBadgeProps) => {
  if (!category) {
    return null;
  }

  const categoryFontColor = matchFontColorToBackgroundColor(category.color);
  return (
    <Box
      padding={"8px 16px"}
      borderRadius={2}
      bgcolor={`var(${category.color})`}
      color={`var(${categoryFontColor})`}
    >
      <Typography component="p" size="small">
        <CategoryLabel category={category} />
      </Typography>
    </Box>
  );
};

type PostBudgetBadgeProps = {
  budget: PostProps["budget"];
};

export const PostBudgetBadge = ({ budget }: PostBudgetBadgeProps) => {
  return (
    <Box display="flex" alignItems="center" whiteSpace="nowrap">
      <Emoji emoji="🔥" />
      <Typography
        component="span"
        color="inherit"
        fontWeight={400}
        size="small"
        pl={1}
      >
        Asking for 1:1 help
      </Typography>
      {Number(budget) !== 0 && (
        <BudgetLabel budget={budget} size="small" sx={{ pl: 0.5 }} />
      )}
    </Box>
  );
};

type CategoryBudgetBadgeContainerProps = {
  categoryColor: EnumType<typeof ENUMS.PostCategoryColor>;
  children: ReactNode;
} & BoxProps;

export const CategoryBudgetBadgeContainer = ({
  categoryColor,
  className,
  children,
  ...boxProps
}: CategoryBudgetBadgeContainerProps) => {
  const fontColor = matchFontColorToBackgroundColor(categoryColor);
  return (
    <Box
      sx={{
        borderRadius: "8px",
        p: "8px 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.25rem",
        flexWrap: "wrap",
      }}
      className={cs("category-and-budget", className)}
      bgcolor={`var(${categoryColor})`}
      color={`var(${fontColor})`}
      data-testid="category-budget-badge"
      {...boxProps}
    >
      {children}
    </Box>
  );
};

type CategoryNameProps = {
  category: PostCategory;
  nameProps?: Partial<TypographyProps>;
};

export const CategoryName = ({ category, nameProps }: CategoryNameProps) => {
  const isMobile = useMediaQuery(750);

  return (
    <Box className={styles.postCategoryName}>
      <CategoryLabel
        category={category}
        nameProps={{
          size: isMobile ? "small" : "medium",
          ...nameProps,
        }}
      />
    </Box>
  );
};

type BudgetLabelProps = FormatHelpOfferBudgetConfig & Partial<TypographyProps>;

export const BudgetLabel = ({
  budget,
  truncateBudget,
  hideFreeLabel,
  ...rest
}: BudgetLabelProps) => {
  const isFree = Number(budget) === 0;
  const budgetLabel = formatHelpOfferBudget({
    budget,
    truncateBudget,
    hideFreeLabel,
  });

  return (
    <Typography component="span" color="inherit" fontWeight={500} {...rest}>
      {isFree ? (
        <>{budgetLabel}</>
      ) : (
        <>
          <BraintrustIcon
            sx={{
              fontSize: "inherit",
              verticalAlign: "text-bottom",
            }}
          />{" "}
          {budgetLabel} BTRST
        </>
      )}
    </Typography>
  );
};
