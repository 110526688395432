import { useEffect, useState } from "react";

import {
  Box,
  IconButton,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

import styles from "./styles.module.scss";

type AirAiDissmissableBarProps = {
  jobId: number;
  onClose?: VoidFunction;
};

export const AirAiDissmissableBar = ({
  jobId,
  onClose = () => {},
}: AirAiDissmissableBarProps) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const isDismissed = localStorage.getItem(`airAiBarDismissed-${jobId}`);
    if (isDismissed) {
      setIsVisible(false);
    }
  }, [jobId]);

  const handleDismiss = () => {
    setIsVisible(false);
    localStorage.setItem(`airAiBarDismissed-${jobId}`, "true");
    onClose();
  };

  if (!isVisible) {
    return null;
  }
  return (
    <Box className={styles.airAiBar}>
      <Stack direction="column" useFlexGap spacing={2} p={5}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems={{ xs: "center", sm: "flex" }}
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <img
              src={`${SETTINGS.STATIC_URL}jobs/ai-icon.svg`}
              alt="air-ai"
              height={25}
              width={27}
            />
            <Typography component="h5" variant="label" size="medium">
              What to expect from AIR Interviews
            </Typography>
          </Box>
          <IconButton
            size="x-small"
            variant="medium-grey"
            aria-label="Dismiss air ai bar"
            onClick={handleDismiss}
            sx={{ alignSelf: { xs: "center", sm: "flex-start" } }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box display="contents" alignItems="left" gap={1}>
          <Typography component="p" variant="paragraph" size="small">
            AIR will evaluate candidates based on their skills, experience, and
            fit for the role, then present the top options. You can choose the
            candidate who best aligns with your needs and request a subsequent
            interview to discuss the role further. If you need any further help,
            please contact{" "}
            <RouterLink
              target="_blank"
              to={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
              rel="noreferrer"
              className={styles.airAiBarCustomLink}
            >
              {SETTINGS.SUPPORT_EMAIL}
            </RouterLink>
            .
          </Typography>
          <Typography
            component="p"
            variant="link"
            size="small"
            className={styles.airAiBarCustomLink}
            onClick={handleDismiss}
          >
            Do not show again
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default AirAiDissmissableBar;
