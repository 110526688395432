import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import { Box, Checkbox, Typography } from "@hexocean/braintrust-ui-components";
import type { AvailabilityFormValues } from "@js/apps/available-for-work/hooks/use-availability-form";
import { clickCheckAvailabilityOption } from "@js/apps/freelancer/actions";
import { useAppDispatch } from "@js/hooks";
import { enumToOptions } from "@js/utils";

type AvailabilityOption = EnumType<typeof ENUMS.FreelancerAvailabilityForWork>;

type AvailabilityOptionsFieldProps = {
  control: Control<AvailabilityFormValues>;
};

export const AvailabilityOptionsField = ({
  control,
}: AvailabilityOptionsFieldProps) => {
  const dispatch = useAppDispatch();

  const { field: availabilityForWorkOptionsField } = useController({
    name: "availability_for_work_options",
    control,
  });

  const { field: availabilityForWorkField } = useController({
    name: "availability_for_work",
    control,
  });

  const handleChange = (optionValue: AvailabilityOption, checked: boolean) => {
    const currentValues = availabilityForWorkOptionsField.value ?? [];
    const newValues = checked
      ? [...currentValues, optionValue]
      : currentValues.filter(
          (value: AvailabilityOption) => value !== optionValue,
        );

    availabilityForWorkOptionsField.onChange(newValues);
  };

  const handleAvailabilityClick = (availabilityOption: AvailabilityOption) =>
    dispatch(clickCheckAvailabilityOption(availabilityOption));

  const options = enumToOptions(ENUMS.FreelancerAvailabilityForWorkLabels);

  return (
    <>
      <Box display="flex" flexDirection="column">
        {options.map((option) => {
          const isChecked = (
            availabilityForWorkOptionsField.value ?? []
          ).includes(option.value);

          return (
            <Checkbox
              key={option.value}
              checked={isChecked}
              label={option.label}
              disabled={availabilityForWorkField.value === false}
              onClick={() =>
                !isChecked && handleAvailabilityClick(option.value)
              }
              {...availabilityForWorkOptionsField}
              ref={undefined}
              value={option.value}
              onChange={(_, checked) => handleChange(option.value, checked)}
            />
          );
        })}
      </Box>
    </>
  );
};

export const AvailabilityOptionsFieldError = ({
  control,
}: {
  control: Control<AvailabilityFormValues>;
}) => {
  const {
    fieldState: { error, isTouched },
  } = useController({
    name: "availability_for_work_options",
    control,
  });

  if (isTouched && error) {
    return (
      <Typography component="p" size="small" error>
        {error.message}
      </Typography>
    );
  }

  return null;
};
