import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Checkbox } from "@hexocean/braintrust-ui-components";
import { LOCATIONS_TAGS_CHARACTERS_LIMIT } from "@js/apps/jobs/apps/create-job/constants";
import {
  prepareArrayWithTagsCharactersLimit,
  prepareAutocompleteValue,
} from "@js/apps/jobs/apps/create-job/utils/prepare-data";
import type { LocationValue } from "@js/components/autocomplete-new/google-places/types";
import type { GoogleComboboxMultipleFieldProps } from "@js/forms/fields";

import { useLocationAndTimezoneNotification } from "../../use-location-and-timezone-notification";

import {
  filterDuplicatedOptions,
  filterSelectedAndCustomOptions,
  getLocationName,
  handleOptionDisabled,
  isLocationOptionOnTheList,
  isOnlyLocation,
  sortSelectedAndCustomOptions,
} from "./helpers";

export type UseJobLocationFieldArg = {
  locations: TypedWrappedFieldProps<LocationValue[]>;
};

export const useJobLocationField = ({ locations }: UseJobLocationFieldArg) => {
  const { showLimitNotification } = useLocationAndTimezoneNotification();

  const handleChange = (data: Array<LocationValue>) => {
    const { isOnlyLocation: isOnlyLocationChosen, location: onlyLocation } =
      isOnlyLocation(data);

    if (isOnlyLocationChosen && onlyLocation) {
      locations.input.onChange([onlyLocation]);
      return;
    }

    if (data.length > SETTINGS.JOB_LOCATIONS_LIMIT) {
      showLimitNotification("LOCATIONS", SETTINGS.JOB_LOCATIONS_LIMIT);
      return;
    }

    locations.input.onChange(data);
  };

  const filterOptionsHandler = (
    options: LocationValue[],
    { inputValue }: { inputValue: string },
  ) => {
    return options
      .filter((option) => filterSelectedAndCustomOptions(option, inputValue))
      .sort(sortSelectedAndCustomOptions)
      .filter(filterDuplicatedOptions);
  };

  const handleDisableOptionFromList = (option: LocationValue) => {
    return handleOptionDisabled(option, locations.input.value || []);
  };

  const renderOptionsHandler: GoogleComboboxMultipleFieldProps<false>["renderOption"] =
    ({ key, ...props }, option: LocationValue, { selected }) => {
      if (!option) {
        return null;
      }

      const isOptionDisabled = handleOptionDisabled(
        option,
        locations.input.value || [],
      );
      const isLocationHasBeenSelected = isLocationOptionOnTheList(
        locations.input.value || [],
        option,
      );

      const isSelected = selected || isLocationHasBeenSelected;

      return (
        <div
          key={option.location}
          style={{
            cursor: isOptionDisabled ? "not-allowed" : "initial",
          }}
        >
          <Box component="li" {...props}>
            <Checkbox
              sx={{
                backgroundColor: isSelected ? "var(--yellow)" : "",
              }}
              checked={isSelected}
            />
            {option.location}
          </Box>
        </div>
      );
    };

  const renderTagsHandler: GoogleComboboxMultipleFieldProps<false>["renderTags"] =
    (locationsCollection: LocationValue[], _, { focused }) => {
      const locationTagsPreparation = prepareArrayWithTagsCharactersLimit(
        locationsCollection,
        LOCATIONS_TAGS_CHARACTERS_LIMIT,
        getLocationName,
        focused,
      );

      return locationTagsPreparation.map((loc, index) => {
        return prepareAutocompleteValue({
          value: getLocationName(loc),
          isLast: index === locationTagsPreparation.length - 1,
          isInputFocused: focused,
          isLimitReached:
            locationTagsPreparation.length < locationsCollection.length,
        });
      });
    };

  return {
    handleDisableOptionFromList,
    handleChange,
    renderOptionsHandler,
    renderTagsHandler,
    filterOptionsHandler,
  };
};
