import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Typography } from "@hexocean/braintrust-ui-components";
import { OfferNotFound } from "@js/apps/jobs/apps/offers/views/talent-preview-offer/not-found";
import { RouterLink } from "@js/components/link";
import { useQueryParams } from "@js/hooks";
import { AppLayout } from "@js/layouts/app";

import EmployerNotFound from "../../employer/views/employer-not-found";
import FreelancerNotFound from "../../freelancer/views/freelancer-not-found";
import { JobNotFound } from "../../jobs/views/job-details/job-not-found";
import { NOT_FOUND_PAGE_ID } from "../constants";

import styles from "./styles.module.scss";

const PageNotFound = () => {
  const { message, origin, reloaded } = useQueryParams();
  const { search, state }: any = useLocation();

  const notFoundPage = useCallback(() => {
    let notFoundPageToDisplay: null | JSX.Element = null;

    // When adding cases depending on route, please also include them in ./configure.js
    switch (message) {
      case ENUMS.JobNotFoundErrorCode.JOB_NOT_FOUND:
        notFoundPageToDisplay = <JobNotFound />;
        break;
      case NOT_FOUND_PAGE_ID.FREELANCER_PROFILE:
        notFoundPageToDisplay = <FreelancerNotFound />;
        break;
      case NOT_FOUND_PAGE_ID.EMPLOYER_PROFILE:
        notFoundPageToDisplay = <EmployerNotFound />;
        break;
      case NOT_FOUND_PAGE_ID.TALENT_OFFER_PREVIEW:
        notFoundPageToDisplay = <OfferNotFound reason={state?.detail} />;
        break;
      default:
        break;
    }

    return { notFoundPage: notFoundPageToDisplay };
  }, [message, state?.detail]);

  useEffect(() => {
    if (!reloaded) {
      window.location.replace(
        `/page-not-found/${search ? search + "&" : "?"}reloaded=true`,
      );
    }
  }, [message, notFoundPage, origin, reloaded, search, state?.prevRoute]);

  const page = notFoundPage();

  return (
    <AppLayout
      pageTitle="Page Not Found"
      flexColumn
      className={styles.notFoundPage}
    >
      {!!page.notFoundPage ? page.notFoundPage : <DefaultPageNotFoundContent />}
    </AppLayout>
  );
};

const DefaultPageNotFoundContent = () => {
  return (
    <>
      <Typography component="h1" variant="display">
        404
      </Typography>
      <Typography
        component="p"
        variant="title"
        fontWeight={400}
        className={styles.notFoundPageMessage}
      >
        Page Not Found
      </Typography>
      <Typography
        component="p"
        variant="title"
        fontWeight={400}
        className={styles.notFoundPageInfo}
      >
        Return to the <RouterLink to="/">homepage</RouterLink> or contact us
        about the problem
      </Typography>
    </>
  );
};

export default PageNotFound;
