import { Fragment } from "react";
import cs from "classnames";

import type {
  MUITooltipProps,
  TypographyProps,
} from "@hexocean/braintrust-ui-components";
import { Box, Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import {
  ErrorSVGIcon,
  InfoIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import style from "./style.module.scss";

type FieldSectionPanelBaseProps = {
  id?: string;
  title: string;
  titleProps?: Partial<TypographyProps>;
  isError?: boolean;
  children: React.ReactNode;
  rightSocket: JSX.Element;
  className?: string;
  image?: string;
};

type FieldSectionPanelProps = Omit<
  FieldSectionPanelBaseProps,
  "rightSocket"
> & {
  actions?: JSX.Element[];
  description?: string;
};

export const FieldSectionPanel = ({
  actions,
  description,
  ...props
}: FieldSectionPanelProps) => {
  return (
    <FieldSectionPanelBase
      {...props}
      rightSocket={
        <>
          {!!actions?.length && (
            <Box className={style.sectionPanelActions}>
              {actions.map((action) => (
                <Fragment key={action.key}>{action}</Fragment>
              ))}
            </Box>
          )}
          {!!description && (
            <Typography
              component="p"
              size="small"
              className={style.sectionPanelDescription}
            >
              {description}
            </Typography>
          )}
        </>
      }
    />
  );
};

type FieldSectionPanelWithImportanceProps = FieldSectionPanelProps & {
  fieldImportance?: "required" | "optional" | "recommended";
  tooltipMessage?: MUITooltipProps["title"];
  className?: string;
};

export const FieldSectionPanelWithImportance = ({
  actions,
  description,
  fieldImportance,
  tooltipMessage,
  className,
  ...props
}: FieldSectionPanelWithImportanceProps) => {
  return (
    <FieldSectionPanelBase
      {...props}
      className={cs(style.panelWithImportance, className)}
      rightSocket={
        <>
          {!!fieldImportance && (
            <Box className={style.sectionPanelImportance}>
              <FieldImportanceLabel
                fieldImportance={fieldImportance}
                tooltipMessage={tooltipMessage}
              />
            </Box>
          )}
          {!!actions?.length && (
            <Box className={style.sectionPanelActions}>
              {actions.map((action) => (
                <Fragment key={action.key}>{action}</Fragment>
              ))}
            </Box>
          )}
          {!!description && (
            <Typography
              component="p"
              size="small"
              className={style.sectionPanelDescription}
            >
              {description}
            </Typography>
          )}
        </>
      }
    />
  );
};

export const FieldSectionPanelBase = ({
  id,
  title,
  titleProps,
  rightSocket,
  isError,
  children,
  className,
  image,
}: FieldSectionPanelBaseProps) => {
  const defaultId = title?.toLowerCase().replace(" ", "-");

  return (
    <Box
      id={id || defaultId}
      className={cs(style.sectionPanel, className, {
        [style.sectionPanelError]: isError,
      })}
    >
      {!!image && (
        <Box className={style.sectionPanelImage} flexShrink={0}>
          <img src={image} alt="" width="30" height="30" />
        </Box>
      )}

      <Box className={style.sectionPanelHead}>
        <Typography
          component="h4"
          variant="label"
          {...titleProps}
          className={style.sectionPanelTitle}
        >
          {isError && (
            <ErrorSVGIcon
              style={{
                color: "var(--negative-2)",
                fontSize: 16,
                marginRight: 8,
              }}
            />
          )}
          {title}
        </Typography>
        {rightSocket}
      </Box>
      {children}
    </Box>
  );
};

type FieldImportanceLabelProps = {
  fieldImportance: "required" | "optional" | "recommended";
  tooltipMessage?: MUITooltipProps["title"];
};

type FieldImportanceLabelType = {
  readonly required: string;
  readonly optional: string;
  readonly recommended: string;
};

const FIELD_IMPORTANCE_LABEL: FieldImportanceLabelType = {
  required: "Required",
  optional: "Optional",
  recommended: "Highly recommended",
};

const FieldImportanceLabel = ({
  tooltipMessage,
  fieldImportance,
}: FieldImportanceLabelProps) => {
  if (!!tooltipMessage) {
    return (
      <>
        <Typography
          sx={{ display: "flex" }}
          color="tertiary"
          component="p"
          size="small"
        >
          {FIELD_IMPORTANCE_LABEL[fieldImportance]}
          <Tooltip title={tooltipMessage}>
            <InfoIcon
              style={{
                width: 20,
                height: 20,
                marginLeft: 8,
                marginTop: 2,
              }}
            />
          </Tooltip>
        </Typography>
      </>
    );
  }
  return (
    <Typography color="tertiary" component="p" size="small">
      {FIELD_IMPORTANCE_LABEL[fieldImportance]}
    </Typography>
  );
};
