import { Typography } from "@hexocean/braintrust-ui-components";

import style from "./style.module.scss";

type ClientTestimonialSlideProps = {
  header: string;
  title: string;
  text: string;
};

export const ClientTestimonialSlide = ({
  header,
  title,
  text,
}: ClientTestimonialSlideProps) => {
  return (
    <div className={style.clientSlide}>
      <Typography component="p" variant="label" size="large">
        {header}
      </Typography>
      <Typography component="p" variant="title" size="medium" fontWeight={400}>
        {title}
      </Typography>
      <Typography component="p" variant="title" size="small" fontWeight={400}>
        {text}
      </Typography>
    </div>
  );
};
