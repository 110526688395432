import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Box, Menu } from "@hexocean/braintrust-ui-components";
import { Button } from "@hexocean/braintrust-ui-components";
import {
  ArrowRightTopIcon,
  HelpOutlineIcon,
  KeyboardArrowDownIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import { handleBookingTalentsCalendarClick } from "@js/apps/jobs/utils";
import type { EmployerBidListBid, Job } from "@js/types/jobs";
import { capitalizeEachFirstLetter } from "@js/utils";

import {
  useBidInterviewIntendedMutation,
  useUpdateEmployerBidInterviewSectionMutation,
} from "../../api";
import { useSubmitSchedulingLink } from "../../hooks/use-submit-scheduling-link";
import { openRequestLiveInterviewMessage } from "../request-live-interview-message";

type NextRoundButtonProps = {
  bid: EmployerBidListBid;
  job: Job;
  onOptionClick?: () => void;
  closeDrawer?: () => void;
} & Partial<ButtonProps>;

export const NextRoundButton = ({
  job,
  bid,
  onOptionClick,
  closeDrawer,
  ...buttonProps
}: NextRoundButtonProps) => {
  const [updateEmployerBidInterviewSection] =
    useUpdateEmployerBidInterviewSectionMutation();
  const [bidInterviewIntended] = useBidInterviewIntendedMutation();
  const { refetchBidList } = useBidsContext();
  const { submitConfirmedLink } = useSubmitSchedulingLink({
    bid,
    job,
  });

  const handleSendCalendarClick = () => {
    onOptionClick?.();
    submitConfirmedLink();
  };
  return (
    <Menu
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchor={
        <Button
          variant="primary"
          shape="squared"
          endIcon={<KeyboardArrowDownIcon />}
          disabled={!!job.completed_at || job.openings_left === 0}
          {...buttonProps}
          sx={{ flexShrink: 0 }}
        >
          Advance to next round
        </Button>
      }
    >
      {bid.calendar_url ? (
        <Menu.Item
          onClick={async () => {
            onOptionClick?.();
            await handleBookingTalentsCalendarClick(bid, bidInterviewIntended);
          }}
        >
          <Box display="flex" alignItems="center">
            View {capitalizeEachFirstLetter(bid.freelancer.user.first_name)}’s
            calendar
            <ArrowRightTopIcon sx={{ ml: 0.5, fontSize: "120%" }} />
          </Box>
        </Menu.Item>
      ) : (
        <Menu.Item
          onClick={() => {
            onOptionClick?.();
            openRequestLiveInterviewMessage({ bid, job });
          }}
        >
          Send interview request
        </Menu.Item>
      )}
      <Menu.Item onClick={handleSendCalendarClick}>Send my calendar</Menu.Item>
      <Menu.Item
        tooltipText="Moves Talent to the “Round 2” stage. Note this will not send any message to the Talent."
        displayTooltipOnlyIfDisabled={false}
        onClick={async () => {
          onOptionClick?.();
          await updateEmployerBidInterviewSection({
            bidId: bid.id,
            status: ENUMS.JobInterviewStatus.ROUND_2,
          });
          refetchBidList?.();
          closeDrawer?.();
        }}
      >
        <Box display="flex" alignItems="center">
          Mark “Round 2”
          <HelpOutlineIcon sx={{ ml: 0.5, fontSize: "120%" }} />
        </Box>
      </Menu.Item>
    </Menu>
  );
};
