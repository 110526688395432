import React, { useCallback, useEffect, useRef } from "react";

import {
  IconButton,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  CloseIcon,
  SettingsIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { useForceRender } from "@js/hooks/use-force-render";
import type { SiteNotification as SiteNotificationType } from "@js/types/notifications";

import { siteNotificationsMarkAllAsRead } from "../../actions";
import { SiteNotificationItem } from "../site-notification";

import style from "./style.module.scss";

type SiteNotificationsProps = {
  close: () => void;
  anchorEl: HTMLElement | null;
};

type ActionIconButtonProps = {
  to?: string;
  onClick?: () => void;
  children: React.ReactNode;
  ariaLabel: string;
};

const ActionIconButton = ({
  to,
  children,
  onClick,
  ariaLabel,
}: ActionIconButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      aria-label={ariaLabel}
      variant="transparent-with-hover"
      RouterLink={to ? RouterLink : undefined}
      to={to}
    >
      {children}
    </IconButton>
  );
};
type NotificationsContentProps = {
  siteNotifications: SiteNotificationType[] | null;
  close: () => void;
};

const NotificationsContent = ({
  siteNotifications,
  close,
}: NotificationsContentProps) => {
  if (!siteNotifications?.length) {
    return (
      <Typography component="p" variant="paragraph" textAlign="center" m={4}>
        No notifications.
      </Typography>
    );
  }

  return (
    <div className={style.siteNotificationsContent}>
      {siteNotifications.map((siteNotification) => {
        return (
          <SiteNotificationItem
            closeNotificationsPopup={close}
            key={siteNotification.id}
            notification={siteNotification}
          />
        );
      })}
    </div>
  );
};

export const SiteNotifications = ({
  close,
  anchorEl,
}: SiteNotificationsProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const { getArrowXPosition } = useCalculateArrowPosition({
    anchorEl,
    containerEl: ref?.current,
  });

  useEffect(() => {
    dispatch(siteNotificationsMarkAllAsRead());
  }, [dispatch]);

  const { loadingSiteNotifications, siteNotifications } = useAppSelector(
    (state) => state.notifications,
  );

  return (
    <div
      className={style.siteNotifications}
      style={{
        "--arrowX": getArrowXPosition(),
      }}
      ref={ref}
    >
      <div className={style.siteNotificationsHeader}>
        <Typography
          p={2}
          fontWeight={500}
          component="h4"
          variant="paragraph"
          size="medium"
        >
          Notifications
        </Typography>
        <span className={style.siteNotificationsIcons}>
          <ActionIconButton
            to="/settings/notifications"
            ariaLabel="Open settings"
          >
            <SettingsIcon className={style.siteNotificationsIcon} />
          </ActionIconButton>

          <ActionIconButton onClick={close} ariaLabel="Close">
            <CloseIcon className={style.siteNotificationsBigger} />
          </ActionIconButton>
        </span>
      </div>
      {loadingSiteNotifications ? (
        <Loader centered />
      ) : (
        <NotificationsContent
          siteNotifications={siteNotifications}
          close={close}
        />
      )}
    </div>
  );
};

type UseCalculateArrowPositionProps = {
  anchorEl: HTMLElement | null;
  containerEl: HTMLElement | null;
};

const useCalculateArrowPosition = ({
  anchorEl,
  containerEl,
}: UseCalculateArrowPositionProps) => {
  /*
   * We need to force re-render the component to get the correct position of the arrow.
   * That is because notifications Popover appears after animation and first getBoundingClientRect()
   * gets smaller width then the actual width after animation.
   * */
  const forceUpdate = useForceRender();
  useEffect(() => {
    const timer = setTimeout(() => {
      forceUpdate();
    }, 200);
    return () => clearTimeout(timer);
  }, [forceUpdate]);

  const getArrowXPosition = useCallback(() => {
    if (!anchorEl) return "";
    const anchorRect = anchorEl.getBoundingClientRect();
    const anchorMiddle = anchorRect.left + anchorRect.width / 2;
    const notificationWrapperRightEdge =
      containerEl?.getBoundingClientRect()?.right;
    const arrowWidth = 18;

    if (!notificationWrapperRightEdge) return "";

    const arrowX = Math.abs(
      notificationWrapperRightEdge - anchorMiddle - arrowWidth,
    );

    return `${arrowX.toFixed(0)}px`;
  }, [anchorEl, containerEl]);

  return { getArrowXPosition };
};
