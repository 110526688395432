import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";

type OpenWhatToExpectModal = {
  onClose: VoidFunction;
};

const shownJobs = new Set<number>();

const ContentSection = ({
  heading,
  text,
}: {
  heading: string;
  text: string;
}) => (
  <Box className="custom-stack">
    <Typography component="h2" variant="label" size="medium">
      {heading}
    </Typography>
    <Typography component="p" variant="paragraph" size="small">
      {text}
    </Typography>
  </Box>
);

export const AirUiExpectModalContent = ({ onClose }: OpenWhatToExpectModal) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Stack direction="column" className={styles.airAiPopupContent}>
      <Stack direction="column" useFlexGap spacing={2} p={5}>
        <Box
          display="flex"
          justifyContent={isMobile ? "start" : "left"}
          alignItems="left"
          gap={1}
        >
          <img
            src={`${SETTINGS.STATIC_URL}jobs/ai-icon.svg`}
            alt="air-ai"
            height={31}
            width={31}
          />
        </Box>

        <Box
          display="contents"
          justifyContent={isMobile ? "start" : "left"}
          alignItems="left"
          gap={1}
        >
          <Typography
            component="p"
            size="small"
            variant="title"
            className={styles.airAiPopupNotbold}
          >
            Thank you for scheduling your first AIR Interview! 🎉
          </Typography>
          <Typography component="h1" variant="label" size="large">
            What to expect from AIR Interviews:
          </Typography>

          <ContentSection
            heading="AIR Interviews"
            text="After talent conducts their interview, AIR will notify you with the results."
          />

          <ContentSection
            heading="Summary + Grading"
            text="You'll receive a synopsis of the interview in a clear and concise summary. Talent video responses will be recorded and assessed based on predefined criteria set by the hiring manager."
          />

          <ContentSection
            heading="Selection"
            text="AIR will evaluate candidates based on their skills, experience, and fit for the role, then present the top options. You can choose the candidate who best aligns with your needs and request a subsequent interview to discuss the role further."
          />

          <Button
            shape="squared"
            variant="primary"
            size={isMobile ? "x-small" : "small"}
            sx={{
              whiteSpace: "nowrap",
              flexShrink: 0,
              gap: 1,
              alignSelf: "start",
              backgroundColor: "var(--black)",
            }}
            onClick={onClose}
          >
            Close
          </Button>

          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            className={styles.airAiPopupInfo}
          >
            <img
              src={`${SETTINGS.STATIC_URL}get-career-help/info.svg`}
              alt="info"
              width="20"
              height="21"
            />
            <Typography component="p" size="small">
              If you need any further help, please contact{" "}
              <RouterLink
                target="_blank"
                to={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
                rel="noreferrer"
                className={styles.link}
                style={{ textDecoration: "underline" }}
              >
                {SETTINGS.SUPPORT_EMAIL}
              </RouterLink>
              .
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export const openAirAIWhatToExpectModal = (props: OpenWhatToExpectModal) => {
  ModalInstance.open({
    className: "text-content-mw",
    padding: false,
    children: <AirUiExpectModalContent {...props} />,
  });
};

export const expectModal = {
  openAnnouncement: (jobId: number) => {
    if (!shownJobs.has(jobId)) {
      shownJobs.add(jobId);
      openAirAIWhatToExpectModal({
        onClose: () => {
          ModalInstance.close();
        },
      });
    }
  },
};
