import { Loader } from "@hexocean/braintrust-ui-components";

import { useWithdrawalMethods } from "../../hooks";
import { AddRecipientsModal } from "../add-recipients";
import { AddWithdrawalMethodModal } from "../add-withdrawal-method";
import { WithdrawalMethodsList } from "../withdrawal-methods-list";

export const WithdrawalMethodsSection = () => {
  const {
    fetchingWithdrawalMethods,
    withdrawalMethods,
    setAsDefaultWithdrawalMethod,
    onAddRecipientsClick,
    onWithdrawalMethodDelete,
  } = useWithdrawalMethods();

  return (
    <>
      <div className="mb++">
        {fetchingWithdrawalMethods && <Loader />}
        {!fetchingWithdrawalMethods && (
          <WithdrawalMethodsList
            withdrawalMethods={withdrawalMethods}
            onDelete={(withdrawalMethod) =>
              onWithdrawalMethodDelete(withdrawalMethod)
            }
            onSetAsDefault={setAsDefaultWithdrawalMethod}
            onAddRecipientsClick={onAddRecipientsClick}
          />
        )}
      </div>
      <AddRecipientsModal />
      <AddWithdrawalMethodModal />
    </>
  );
};
