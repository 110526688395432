import React from "react";

import {
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useSignUpFinish } from "@js/apps/onboarding/hooks";
import { OnboardingProfNetLayout } from "@js/apps/onboarding/views/layout";

import { useSignUpEmailConfirmPage } from "../../hooks/sign-up-confirm";

import style from "./style.module.scss";

export const SignUpFinishPage: React.FC = () => {
  const { loading, unverifiedUser, signUpError } = useSignUpFinish();
  const { handleEmailResend } = useSignUpEmailConfirmPage();

  if (loading) {
    return <Loader centered />;
  }

  return (
    <OnboardingProfNetLayout>
      <Box display="flex" justifyContent="center" alignItems="center">
        {signUpError ? (
          <Typography component="p">
            {signUpError}
            {signUpError.includes("Invalid key") && (
              <>
                {" Please "}
                <Button
                  variant="transparent"
                  className={style.resendButton}
                  onClick={handleEmailResend}
                >
                  resend the email
                </Button>
              </>
            )}
          </Typography>
        ) : (
          unverifiedUser?.user_is_already_registered && (
            <Typography component="p">
              Account is already registered.
            </Typography>
          )
        )}
      </Box>
    </OnboardingProfNetLayout>
  );
};
