import { change, reset, submit } from "redux-form";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import {
  closeAddWithdrawalMethodOTPModal,
  openAddWithdrawalMethodOTPModal,
} from "@js/apps/withdrawal/components/add-withdrawal-method/withdrawal-method-otp-modal-instance";
import { Modal } from "@js/components/modal";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type {
  TransferWiseWithdrawalMethod,
  WithdrawalFormRequirements,
} from "@js/types/withdrawals";

import {
  createStripeWithdrawalMethod,
  createTransferWiseWithdrawalMethod,
  fetchCurrencyRequirementsClear,
  fetchWithdrawalMethods,
  resendConfirmAddWithdrawalMethod,
} from "../../actions";
import {
  ADD_STRIPE_WITHDRAWAL_METHOD_FORM_ID,
  AddStripWithdrawalMethodForm,
} from "../../forms/add-stripe-withdrawal-method";
import AddTransferWiseWithdrawalForm, {
  ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID,
} from "../../forms/add-transferwise-withdrawal";

export const AddWithdrawalMethodModal = Modal("add-withdrawal-method", {
  keepOnBackdropClick: true,
  closeButton: false,
});

type AddWithdrawalMethodProps = {
  choosenWithdrawalMethod: EnumType<typeof ENUMS.WithdrawalContentType>;
};

export const openAddWithdrawalMethodModal = (arg: AddWithdrawalMethodProps) => {
  AddWithdrawalMethodModal.open({
    children: <AddWithdrawalMethodModalContent {...arg} />,
  });
};

export const AddWithdrawalMethodModalContent = ({
  choosenWithdrawalMethod,
}: AddWithdrawalMethodProps) => {
  const dispatch = useAppDispatch();
  const creatingWithdrawalMethod = useAppSelector(
    (state) => state.withdrawal.creatingWithdrawalMethod,
  );
  const formRequirements = useAppSelector(
    (state) => state.withdrawal.formRequirements,
  );

  const close = () => {
    dispatch(fetchCurrencyRequirementsClear());
    dispatch(fetchCurrentUser());
    AddWithdrawalMethodModal.close();
    closeAddWithdrawalMethodOTPModal();
  };

  return (
    <>
      {choosenWithdrawalMethod ===
      ENUMS.WithdrawalContentType.TransferWiseWithdrawalMethod ? (
        <TransferWiseMethodContent
          creatingWithdrawalMethod={creatingWithdrawalMethod}
          formRequirements={formRequirements}
          close={close}
        />
      ) : (
        <StripeMethodContent
          creatingWithdrawalMethod={creatingWithdrawalMethod}
          close={close}
        />
      )}
    </>
  );
};

type TransferWiseMethodContentProps = {
  close: () => void;
  formRequirements: WithdrawalFormRequirements;
  creatingWithdrawalMethod: boolean;
};

const TransferWiseMethodContent = ({
  formRequirements,
  close,
  creatingWithdrawalMethod,
}: TransferWiseMethodContentProps) => {
  const dispatch = useAppDispatch();

  const createTransferWiseWithdrawal = async (
    withdrawalMethod: TransferWiseWithdrawalMethod,
  ) => {
    return dispatch(createTransferWiseWithdrawalMethod(withdrawalMethod)).then(
      () => dispatch(fetchWithdrawalMethods()),
    );
  };

  const onSubmit = () => {
    return dispatch(submit(ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID));
  };

  const handleSubmit = (data: TransferWiseWithdrawalMethod) => {
    return createTransferWiseWithdrawal(data)
      .then((res) => {
        dispatch(reset(ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID));
        return res;
      })
      .then(({ key }) =>
        dispatch(change(ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID, "key", key)),
      )
      .then(close)
      .catch((exception) => {
        if (exception.errors && exception.errors.code_is_required) {
          openAddWithdrawalMethodOTPModal();
        } else {
          closeAddWithdrawalMethodOTPModal();
        }

        throw exception;
      });
  };

  return (
    <div className="add-withdrawal-method-modal-content">
      <Typography component="h3" variant="title" fontWeight={400}>
        Add Banking Information
      </Typography>
      <div className="add-withdrawal-method-modal-content-card-element">
        <AddTransferWiseWithdrawalForm
          formRequirements={formRequirements}
          onSubmit={handleSubmit}
        />
      </div>
      <div className="buttons right">
        <Button variant="transparent" color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="transparent"
          color="secondary"
          disabled={creatingWithdrawalMethod}
          onClick={onSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

type StripeMethodContentProps = {
  close: () => void;
  creatingWithdrawalMethod: boolean;
};

const StripeMethodContent = ({
  close,
  creatingWithdrawalMethod,
}: StripeMethodContentProps) => {
  const dispatch = useAppDispatch();
  const showResendEmail = useAppSelector(
    (state) => state.withdrawal.resendConfirmationEmail,
  );
  const forms = useAppSelector((state) => state.form);
  const isForm = !!forms["create-stripe-withdrawal-method"];

  const createStripeWithdrawal = async () => {
    return dispatch(createStripeWithdrawalMethod()).then(() => {
      dispatch(fetchWithdrawalMethods());
    });
  };

  const onSubmit = () => {
    return dispatch(submit(ADD_STRIPE_WITHDRAWAL_METHOD_FORM_ID));
  };

  const handleSubmit = () => {
    return createStripeWithdrawal().then(close);
  };

  return (
    <div className="add-withdrawal-method-modal-content">
      <Typography component="h3" variant="title" fontWeight={400}>
        Add Stripe Account
      </Typography>
      <div className="add-withdrawal-method-modal-content-card-element">
        <AddStripWithdrawalMethodForm onSubmit={handleSubmit} />
        {isForm && showResendEmail && (
          <Button
            variant="secondary"
            size="x-small"
            onClick={resendConfirmAddWithdrawalMethod}
          >
            Resend confirmation e-mail
          </Button>
        )}
      </div>
      <div className="buttons right mt+">
        <Button variant="transparent" color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="transparent"
          color="primary"
          disabled={creatingWithdrawalMethod}
          onClick={onSubmit}
        >
          Add Stripe account
        </Button>
      </div>
    </div>
  );
};
