import React from "react";
import _ from "underscore";

import {
  Button,
  Divider,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { openEnableOTPAuthModal } from "@js/apps/settings/components/otp-auth";
import {
  AddW8Modal,
  AddW9Modal,
} from "@js/apps/settings/components/tax-documents";
import { AddWithdrawalMethodModal } from "@js/apps/withdrawal/components";
import {
  useFetchWithdrawalMethods,
  useOpenChooseWithdrawalMethodModal,
} from "@js/apps/withdrawal/hooks";
import { RouterLink } from "@js/components/link";
import { useAppSelector, useNavigate } from "@js/hooks";
import type { FreelancerOffer } from "@js/types/jobs";
import { assertUnreachable, isNotNullable } from "@js/utils";

import { TalentOfferChecklistRow } from "./components";

import styles from "./style.module.scss";

type TalentOfferChecklistProps = {
  offer: FreelancerOffer;
};

export const TalentOfferChecklist = ({ offer }: TalentOfferChecklistProps) => {
  switch (offer.job.job_type) {
    case ENUMS.JobType.FREELANCE:
    case ENUMS.JobType.GRANT: {
      return <TalentOfferChecklistFreelanceOrGrantJob offer={offer} />;
    }
    case ENUMS.JobType.DIRECT_HIRE: {
      return null;
    }
    default: {
      assertUnreachable(offer.job.job_type);
      return null;
    }
  }
};

const TalentOfferChecklistFreelanceOrGrantJob = ({
  offer,
}: TalentOfferChecklistProps) => {
  const defaultWithdrawalMethod = useAppSelector((state) =>
    _.findWhere(state.withdrawal.withdrawalMethods, { is_default: true }),
  );

  const navigate = useNavigate();

  const user = useUser();
  const isOTPEnabled = user?.is_otp_enabled;
  const isW8FormRequired = user?.is_w8_form_required;
  const isW9FormRequired = user?.is_w9_form_required;
  const address = user?.address;
  const isTaxFormSubmitted =
    !!user?.address &&
    !(user?.is_w8_form_required || user?.is_w9_form_required);

  const { onChooseWithdrawalMethod } = useOpenChooseWithdrawalMethodModal();

  useFetchWithdrawalMethods();

  const ACTIONS = [
    {
      header: "Set up 2FA before submitting your first invoice",
      isCompleted: isOTPEnabled,
      action: isOTPEnabled ? false : openEnableOTPAuthModal,
    },
    {
      header: "Set up banking information",
      isCompleted: !!defaultWithdrawalMethod,
      action: defaultWithdrawalMethod
        ? false
        : isOTPEnabled
          ? onChooseWithdrawalMethod
          : openEnableOTPAuthModal,
    },
    {
      header: "Add tax documentation to your account",
      isCompleted: isTaxFormSubmitted,
      action: address
        ? isW8FormRequired
          ? AddW8Modal.open
          : isW9FormRequired
            ? AddW9Modal.open
            : undefined
        : () => navigate("/settings/payments/"),
    },
    {
      header: "Review payment terms",
      subheader:
        "Review terms and conditions, payment terms and frequency for this job",
      description: (
        <>
          <p>
            You are entering into a contract with Braintrust and Brainturst pays
            your invoices as soon as the client pays.
          </p>
          <p>
            When your invoice is paid, it has to go through our partner, Wise in
            order to get into your account. Wise takes 7-10 days, but sometimes
            this happens sooner.
          </p>
          <p>
            When you joined Braintrust, you signed up for our Terms and
            Conditions, which are applicable to this contract. Please review{" "}
            <a
              href={SETTINGS.TERMS_DOC_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
            .
          </p>
        </>
      ),
    },
    offer.job.job_type !== ENUMS.JobType.GRANT
      ? {
          header: " Review how invoicing works",
          subheader:
            "Make sure you have all the information you need to submit your first invoice",
          description: (
            <>
              <p>
                You will submit invoices on the Braintrust platform through{" "}
                <a
                  href="https://app.usebraintrust.com/talent/invoices"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  this
                </a>{" "}
                portal.
              </p>
              <p>
                Our{" "}
                <a
                  href={SETTINGS.SUPPORT_URLS.TALENT_FAQ_ARTICLE_URL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  FAQ
                </a>{" "}
                is the best place to start with questions on how invoicing works
                on Braintrust.
              </p>
              <p>
                If you have any questions about payment terms or invoices -
                please reach out to us first!
              </p>
            </>
          ),
        }
      : undefined,
  ];

  return (
    <>
      <AddWithdrawalMethodModal />
      <RoundedBox sx={{ border: "1px solid #E9E7E4" }} mb={6}>
        <Typography component="span" variant="label" size="large">
          ✏️ Braintrust checklist
        </Typography>

        <div className={styles.checklist}>
          {ACTIONS.filter(isNotNullable).map((item) => {
            return (
              <React.Fragment key={item.header}>
                <TalentOfferChecklistRow
                  header={item.header}
                  subheader={item.subheader}
                  isCompleted={item.isCompleted}
                  description={item.description}
                  action={item.action}
                />
                <Divider color="beige" />
              </React.Fragment>
            );
          })}
        </div>

        <AddW8Modal>
          <p className="mb+">
            All residents outside of U.S must provide their W-8 tax information.
            Need a W-8 form?{" "}
            <Button
              style={{
                fontSize: 16,
                margin: "-8px 0",
              }}
              variant="transparent"
              color="secondary"
              target="_blank"
              rel="noopener noreferrer"
              download="fw8ben"
              href={`${SETTINGS.STATIC_URL}pdf/fw8ben.pdf`}
              RouterLink={RouterLink}
            >
              Download a copy of the form
            </Button>
          </p>
        </AddW8Modal>
        <AddW9Modal />
      </RoundedBox>
    </>
  );
};
