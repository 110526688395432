import { SubmissionError } from "redux-form";

import { Snackbar } from "@js/components/snackbar";
import { formatErrorForSnackbar } from "@js/utils/string";

const handleFormError = (data: Record<string, string>) => {
  if (!data) return;

  if (data?.code || data?.code_is_required || data?.company_node) {
    throw new SubmissionError(data);
  }
};

export const handleResponseError = (
  error: Record<string, any>,
  errorMsg = "Sorry! An unexpected error occurred",
) => {
  if (!error) return;
  const { data } = error.response;
  handleFormError(data);
  Snackbar.error(data?._error || formatErrorForSnackbar(data) || errorMsg, {
    autoHideDuration: 10000,
  });
};
