import { fetchCurrentUser } from "@js/apps/auth/actions";
import { jobsApi } from "@js/apps/jobs/api";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { useSendInterviewScheduleLinkMutation } from "../../api";
import { openSendSchedulingLinkModal } from "../../components/bid-scheduling-link-modal";
import { openSendScheduleLinkConfirmationModal } from "../../forms/bid-send-scheduling-link/send-calendar-link-confirmation-modal";

export const useSubmitSchedulingLink = ({
  bid,
  job,
}: {
  bid: EmployerBidListBid;
  job: Job;
}) => {
  const [sendInterviewScheduleLink] = useSendInterviewScheduleLinkMutation();
  const dispatch = useAppDispatch();
  const submitConfirmedLink = () => {
    const isSentToAll = !!job.extension?.employer_calendar_link;

    if (!isSentToAll) {
      openSendSchedulingLinkModal({ bid, job });
      return;
    }

    sendInterviewScheduleLink({
      bidId: bid.id,
      calendar_link: job.extension?.employer_calendar_link || "",
      save_as_default: true,
      same_link_for_all: true,
    })
      .unwrap()
      .then(() => {
        dispatch(fetchCurrentUser());
        dispatch(jobsApi.util.invalidateTags([{ type: "Jobs", id: job.id }]));
        openSendScheduleLinkConfirmationModal();
      })
      .catch(() => {
        Snackbar.error("Something went wrong...");
      });
  };

  return {
    submitConfirmedLink,
  };
};
