import React from "react";

import {
  Box,
  RoundedBox,
  Stack,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CheckCircleFilledIcon } from "@hexocean/braintrust-ui-components/Icons";
import { JobActionMenu } from "@js/apps/jobs/components/job-action-menu";
import { RouterLink } from "@js/components/link";
import { UserProfile } from "@js/components/user-profile";
import type { EmployerOwnJob } from "@js/types/jobs";
import { pluralize } from "@js/utils";

import { ImportedFromAtsLabel } from "../imported-from-ats-label";

import styles from "./style.module.scss";

export const EmployerMyJobListItem = ({ job }: { job: EmployerOwnJob }) => {
  const activeHires = job.freelancers_hired_count;
  const isJobSubscribed = !!job.subscribed_job_id;
  const isAtsImported = job.ats_imported;

  return (
    <RoundedBox>
      <div className={styles.item}>
        <div className={styles.itemContent}>
          <div className={styles.itemInfo}>
            <div className={styles.itemHead}>
              {isAtsImported && <ImportedFromAtsLabel />}
              <Typography
                className={`${styles.itemTitle} capitalize-first-letter`}
                component="link"
                to={`/jobs/${job.id}/proposals/`}
                RouterLink={RouterLink}
                size="large"
                noTextDecoration
              >
                {job.title}
              </Typography>
            </div>
            {activeHires > 1 && (
              <Typography
                component="p"
                size="small"
                color="grey-2"
                className={styles.itemActiveHires}
              >
                {activeHires} Active Hires
              </Typography>
            )}
            <CountSection
              className={styles.itemCount}
              url={`/jobs/${job.id}/proposals/`}
              count={job?.total_bids_count}
            />
            <OffersStatusUpdates updates={job.offers_status_update} />
          </div>
          <Stack className={styles.itemProfiles}>
            <UserProfile profile={job.creator} title="Job owner" />
            {job.freelancer_hired && (
              <UserProfile
                key={job.freelancer_hired.id}
                profile={job.freelancer_hired}
                title="Talent Hired"
              />
            )}
            {job.freelancers_with_offers.map((freelancer) => (
              <UserProfile
                key={freelancer.id}
                profile={freelancer}
                title="Pending Offer"
              />
            ))}
          </Stack>
        </div>
        <div className={styles.itemMenuWrapper}>
          {isJobSubscribed && <SubscribedToJobTooltip />}
          <JobActionMenu job={job} />
        </div>
      </div>
    </RoundedBox>
  );
};

type OffersStatusUpdateProps = {
  updates: EmployerOwnJob["offers_status_update"];
};

const OffersStatusUpdates = ({ updates }: OffersStatusUpdateProps) => {
  if (updates.length <= 0) {
    return null;
  }

  return (
    <Box my={1}>
      {updates.map(({ message }) => (
        <Typography component="p" key={message}>
          Update: {message}.
        </Typography>
      ))}
    </Box>
  );
};

type CountSectionProps = {
  url: string;
  count: number;
  className: string;
};

const CountSection = ({ url, count, className }: CountSectionProps) => {
  if (count <= 0) {
    return null;
  }

  return (
    <section className={className}>
      <Typography
        component="link"
        RouterLink={RouterLink}
        to={url}
        className="db"
      >
        {count} Application{pluralize(count)}
      </Typography>
    </section>
  );
};

const SubscribedToJobTooltip = () => {
  return (
    <Tooltip title={<TooltipTitle />} color="var(--black)" placement="top">
      <CheckCircleFilledIcon
        style={{
          color: "var(--positive-2)",
        }}
      />
    </Tooltip>
  );
};

const TooltipTitle = () => (
  <>
    You are subscribed to this job and will receive email notifications. You can{" "}
    <Typography
      component="a"
      RouterLink={RouterLink}
      to="/settings/notifications/"
      className={styles.myJobListTooltip}
    >
      adjust your settings
    </Typography>
    .
  </>
);
