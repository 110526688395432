import { useEffect } from "react";

import {
  Alert,
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  createOTPModalInstance,
  submitActionAfterOTPConfirmation,
} from "@js/apps/common/forms/fields/otp/otp-auth-modal";
import {
  addStripeMethodRecipients,
  deleteRecipient,
  fetchFreelancerCompanyNodes,
  fetchWithdrawalMethods,
} from "@js/apps/withdrawal/actions";
import type { AddRecipientsFormData } from "@js/apps/withdrawal/forms/add-recipients";
import { AddRecipientsForm } from "@js/apps/withdrawal/forms/add-recipients";
import { handleResponseError } from "@js/apps/withdrawal/utils";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { WithdrawalMethodType } from "@js/types/withdrawals";

import { RecipientsListing } from "../recipients-listing";

export type AddRecipientsModalContentProps = {
  handledMethod: WithdrawalMethodType | null;
  closeModal: () => void;
};

export const AddRecipientsModalContent = ({
  handledMethod,
  closeModal,
}: AddRecipientsModalContentProps) => {
  const fetchingCompanyNodes = useAppSelector(
    (state) => state.withdrawal?.fetchingCompanyNodes,
  );
  const freelancerCompanyNodes = useAppSelector(
    (state) => state.withdrawal?.companyNodes,
  );
  const withdrawalMethods = useAppSelector(
    (state) => state.withdrawal?.withdrawalMethods,
  );
  const currentWithdrawalMethod = withdrawalMethods.find(
    (method) => method.id === handledMethod?.id,
  );
  const addingRecipients = useAppSelector(
    (state) => state.withdrawal?.addingRecipients,
  );
  const dispatch = useAppDispatch();

  const addStripeRecipients = async (recipients: AddRecipientsFormData) => {
    return dispatch(addStripeMethodRecipients(recipients))
      .then(() => {
        dispatch(fetchWithdrawalMethods());
      })
      .catch((error) => {
        handleResponseError(error);
      });
  };

  const deleteStripeRecipient = async (recipient: number) => {
    return submitActionAfterOTPConfirmation({
      onSubmit: ({ code }) =>
        deleteRecipient(recipient, code)
          .then(() => {
            dispatch(fetchWithdrawalMethods());
          })
          .catch((error) => {
            handleResponseError(error);
          }),
    });
  };

  const handleSubmit = (values: AddRecipientsFormData) => {
    if (values?.code && handledMethod?.method.id) {
      closeAddRecipientsOTPModal();
      return addStripeRecipients({
        ...values,
        stripe_withdrawal_method: handledMethod.method.id,
      });
    }

    openAddRecipientsOTPModal();
  };

  const handleSubmitFail = (error: Record<string, string>) => {
    if (error?.company_node) {
      closeAddRecipientsOTPModal();
    }
  };

  useEffect(() => {
    dispatch(fetchFreelancerCompanyNodes());
  }, [dispatch]);

  return (
    <Box>
      {fetchingCompanyNodes && !freelancerCompanyNodes.length ? (
        <Loader centered />
      ) : (
        <>
          <Typography
            mb={2}
            fontWeight={500}
            component="h1"
            variant="paragraph"
            size="large"
          >
            Choose Company Node to connect with your Stripe account
          </Typography>
          <AddRecipientsForm
            recipients={freelancerCompanyNodes}
            onSubmit={handleSubmit}
            onSubmitFail={handleSubmitFail}
          >
            <Box mt={2}>
              <Alert type="info" className="mt+ mb+">
                <Typography
                  component="p"
                  size="small"
                  color="blue"
                  fontWeight={500}
                >
                  Stripe payments are tied to the Braintrust Company Node
                  handling money transfer from the Client. If in the future you
                  work on a job serviced by a different Company Node, you will
                  need to add a new account connection.
                </Typography>
              </Alert>

              {addingRecipients ? (
                <Loader />
              ) : (
                <RecipientsListing
                  currentWithdrawalMethod={currentWithdrawalMethod}
                  deleteRecipient={deleteStripeRecipient}
                  closeModal={closeModal}
                />
              )}

              <div className="buttons right mt+">
                <Button variant="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Add company node
                </Button>
              </div>
            </Box>
            <AddRecipientsOTPModalInstance />
          </AddRecipientsForm>
        </>
      )}
    </Box>
  );
};

const {
  AddRecipientsOTPModalInstance,
  openAddRecipientsOTPModal,
  closeAddRecipientsOTPModal,
} = createOTPModalInstance("AddRecipientsOTPModal");
