import { lazyWithRetry } from "@js/utils/lazy-with-retry";

export const FreelancerDashboardWelcomeLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-home" */ "@js/apps/freelancer/components/freelancer-dashboard-welcome"
  );
  return { default: module.FreelancerDashboardWelcome };
});

export const ReferAndEarnLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-refer-and-earn" */ "@js/apps/dashboard/components/refer-and-earn"
  );
  return { default: module.ReferAndEarn };
});

export const MyWalletLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-wallet" */ "@js/apps/freelancer/components/my-wallet"
  );
  return { default: module.MyWallet };
});

export const FreelancerDashboardBidsLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-my-work" */ "@js/apps/freelancer/components/freelancer-dashboard-bids"
  );
  return { default: module.FreelancerDashboardBids };
});

export const FreelancerDashboardOffersLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-my-work" */ "@js/apps/freelancer/components/freelancer-dashboard-offers"
  );
  return { default: module.FreelancerDashboardOffers };
});

export const FreelancerDashboardJobsLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-my-work" */ "@js/apps/freelancer/components/freelancer-dashboard-jobs"
  );
  return { default: module.FreelancerDashboardJobs };
});

export const FreelancerDashboardInvitesLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-my-work" */ "@js/apps/freelancer/components/freelancer-dashboard-invites"
  );
  return { default: module.FreelancerDashboardInvites };
});

export const FreelancerDashboardGettingHelpOffersPageLazy = lazyWithRetry(
  async () => {
    const module = await import(
      /* webpackChunkName: "freelancer-views-my-work" */ "@js/apps/freelancer/views/dashboard/help-offers"
    );
    return { default: module.FreelancerDashboardGettingHelpOffersPage };
  },
);

export const FreelancerDashboardGivingHelpOffersPageLazy = lazyWithRetry(
  async () => {
    const module = await import(
      /* webpackChunkName: "freelancer-views-my-work" */ "@js/apps/freelancer/views/dashboard/help-offers"
    );
    return { default: module.FreelancerDashboardGivingHelpOffersPage };
  },
);

export const FreelancerInvoicesLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-invoices" */ "@js/apps/freelancer/components/freelancer-invoices"
  );
  return { default: module.FreelancerInvoices };
});

export const FreelancerProfileLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-profile" */ "@js/apps/freelancer/components/freelancer-profile"
  );
  return { default: module.FreelancerProfile };
});

export const FreelancerProfilePageAboutLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-profile" */ "@js/apps/freelancer/views/profile-about"
  );
  return { default: module.FreelancerProfilePageAbout };
});

export const FreelancerProfilePageResumeLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "freelancer-views-profile" */ "@js/apps/freelancer/components/profile-resume"
  );
  return { default: module.FreelancerProfilePageResume };
});
