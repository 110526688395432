import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useAccountType } from "@js/apps/common/hooks";
import { useTokenFeatureManagement } from "@js/apps/jobs/hooks/paid-management-feature";
import { RouterLink } from "@js/components/link";
import { useShowSnackbarFromQueryParam } from "@js/hooks";
import { AppLayout } from "@js/layouts/app";

type OwnProps = {
  children: React.ReactNode;
  pageTitle?: string;
  onSave?: () => void;
  onCancel?: () => void;
  isSaveDisabled?: boolean;
  onCancelDisabled?: boolean;
};

type MenuItem = {
  path: string;
  label: string;
};

export const SettingsLayout = ({
  children,
  pageTitle,
  onSave,
  onCancel,
  isSaveDisabled = false,
  onCancelDisabled = false,
}: OwnProps): JSX.Element => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery("sm");
  const { isFreelancer } = useAccountType();
  const { showTokenFeatureManagement } = useTokenFeatureManagement();

  useShowSnackbarFromQueryParam();

  const showSaveAndCancelButtons =
    isFreelancer || pathname.includes("account_info");

  const items: MenuItem[] = useMemo(() => {
    const menuItems = [
      {
        path: "/settings/account_info/",
        label: "Account",
      },
      {
        path: "/settings/privacy_security/",
        label: "Privacy & Security",
      },
      {
        path: "/settings/payments/",
        label: "Payments",
      },
      {
        path: "/settings/notifications/",
        label: "Notifications",
      },
      ...(isFreelancer && showTokenFeatureManagement
        ? [
            {
              path: "/settings/purchases/",
              label: "Purchases",
            },
          ]
        : []),
    ];

    return menuItems;
  }, [showTokenFeatureManagement, isFreelancer]);

  return (
    <AppLayout pageTitle={pageTitle} spacing={isMobile ? 0 : 8} showFooter>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
        mb={isMobile ? 3 : 0}
        gap={1}
      >
        <Typography
          variant="display"
          component="h1"
          size={isMobile ? "small" : "medium"}
          fontWeight={400}
          className="dib underline"
        >
          Settings
        </Typography>
        {showSaveAndCancelButtons && (
          <Box>
            {onCancel && (
              <Button
                variant="secondary"
                onClick={onCancel}
                size="medium"
                disabled={onCancelDisabled}
              >
                Cancel
              </Button>
            )}
            {onSave && (
              <Button
                variant="primary"
                onClick={onSave}
                disabled={isSaveDisabled}
                size="medium"
                style={{ marginLeft: 8 }}
              >
                Save
              </Button>
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Tabs
            value={pathname}
            variant="scrollable"
            sx={{
              "& .MuiTabs-flexContainer": {
                gap: 2,
                p: "0px 1px",
                // For feature Flag UnderlinedTabs Button remove
              },
            }}
          >
            {items.map(({ path, label }) => (
              <Tab
                key={label}
                value={path}
                component={RouterLink}
                sx={{
                  py: "6px",
                }}
                to={path}
                label={
                  <Typography component="span" variant="label" size="medium">
                    {label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </AppLayout>
  );
};
