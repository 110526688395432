import type { FC } from "react";
import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";

import { ActionButtons } from "./action-buttons";
import { PurchaseSummary } from "./purchase-summary";

export const BTRSTCheckoutForm: FC = () => {
  return (
    <Box
      width={{ xs: "100%", md: "690px" }}
      display="flex"
      flex={1}
      px={{ xs: 2.5, md: 4 }}
      flexDirection="column"
      gap={2.5}
    >
      <PurchaseSummary />
      <ActionButtons />
    </Box>
  );
};
