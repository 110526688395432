import type { ComponentType } from "react";
import { useCallback } from "react";
import type { NavigateFunction, NavigateOptions, To } from "react-router-dom";
import {
  useLocation,
  // eslint-disable-next-line no-restricted-imports
  useNavigate as useRouterNavigate,
  useParams,
} from "react-router-dom";

import { useCurrentPath } from "@js/hooks/use-current-path";

export const useNavigate = (): NavigateFunction => {
  const nativeNavigate = useRouterNavigate();
  const currentPath = useCurrentPath();

  return useCallback(
    (to: To | number, options?: NavigateOptions) => {
      if (typeof to === "number") {
        return nativeNavigate(to);
      }

      nativeNavigate(to, {
        ...options,
        state: {
          prevPath: currentPath,
          ...(options?.state || {}),
        },
      });
    },
    [currentPath, nativeNavigate],
  );
};

/** @deprecated Use `React Router hooks` instead */
export type WithRouterProps = {
  location: ReturnType<typeof useLocation>;
  params: Record<string, string>;
  navigate: ReturnType<typeof useRouterNavigate>;
};

/** @deprecated Use `React Router hooks` instead */
export const withRouter = <Props extends WithRouterProps>(
  Component: ComponentType<Props>,
) => {
  return (props: Omit<Props, keyof WithRouterProps>) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useRouterNavigate();

    return (
      <Component
        {...(props as Props)}
        location={location}
        params={params}
        navigate={navigate}
      />
    );
  };
};
